
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Doctor } from '../models/doctor.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';

@Injectable()
export class DoctorService {
    private _doctors: ReplaySubject<Doctor[]>;
    private dataStore: {
        doctors: Doctor[]
    };

    constructor(private http: HttpClient, private notifications: NotificationService) {
        this.dataStore = { doctors: []};
        this._doctors = (new ReplaySubject(1) as ReplaySubject<Doctor[]>);
    }

    get(specialityId: number, departmentId: number, languageId: number): Observable<Doctor[]> {
        return this.http
            .get<Doctor[]>(`api/Doctor/Get?specialityId=${specialityId}&departmentId=${departmentId}&languageId=${languageId}`).pipe(
            tap((data: Doctor[]) => {

                this.dataStore.doctors = [];
                data.forEach(t => this.dataStore.doctors.push(new Doctor(t.id, t.name)));
                this._doctors.next(Object.assign({}, this.dataStore).doctors);
                },
            error => this.notifications.handleHttpError(error)));
    }

    reset(){
        this.dataStore.doctors = [];
        this._doctors.next(Object.assign({}, this.dataStore).doctors);
    }

    get doctors() {
        return this._doctors.asObservable();
    }
}