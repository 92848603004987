
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Department } from '../models/department.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';

@Injectable()
export class DepartmentService {
    
    private _departments: ReplaySubject<Department[]>;
    private dataStore: {
        departments: Department[]
    };

    constructor(private http: HttpClient, private notifications: NotificationService) {
        this.dataStore = { departments: []};
        this._departments = (new ReplaySubject(1) as ReplaySubject<Department[]>);
    }

    get(specialityId: number): Observable<Department[]> {
        return this.http
            .get<Department[]>(`api/Department/Get?specialityId=${specialityId}`).pipe(
            tap((data: Department[]) => {

                this.dataStore.departments = [];
                data.forEach(t => this.dataStore.departments.push(new Department(t.id, t.name)));
                this._departments.next(Object.assign({}, this.dataStore).departments);
                },
            error => this.notifications.handleHttpError(error)));
    }

    reset() {
        this.dataStore.departments = [];
        this._departments.next(Object.assign({}, this.dataStore).departments);
    }

    get departments() {
        return this._departments.asObservable();
    }
}