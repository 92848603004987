
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Speciality } from '../models/speciality.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';
import { VisitType } from '../models/visit-type.model';

@Injectable()
export class VisitTypeService {


    constructor(private http: HttpClient, private notifications: NotificationService) {
    }

    get(): Observable<Speciality[]> {
        return this.http
            .get<VisitType[]>(`api/VisitType/Get`)
                .pipe(tap(()=>{},
                error => this.notifications.handleHttpError(error)));
            
    }
}