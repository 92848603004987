﻿import { BaseResponse } from './base-response.model';

export class GetPatientResponse extends BaseResponse {
    public name: string;
    public surname: string;
    public pesel: string;
    public birthDate: Date;
    public phoneNumber: string;
    public email: string;
    public acceptedToS: Array<number>;
}