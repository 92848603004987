import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

import { Observable, Subject } from "rxjs";

import { VisitService } from "../shared/services/visit.service";
import { VisitCancelResponse } from "../shared/models/visit-cancel-response.model";
import { GetResponse } from "../shared/models/get-response.model";

import { ErrorProcessor } from "./../../shared/error-processor";
import { ClientService } from "@app/shared/services/client.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "visit-cancel",
  templateUrl: "./visit-cancel.component.html",
  styleUrls: ["./visit-cancel.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0", height: "0" }),
        group([
          animate(".7s ease-out", style({ opacity: "1" })),
          animate(".7s ease-out", style({ height: "*" })),
        ]),
      ]),
      transition(":leave", [
        style({ opacity: "1", height: "*" }),
        animate(".6s ease-in", style({ opacity: "0" })),
        animate(".5s ease-in", style({ height: "0" })),
      ]),
    ]),
  ],
})
export class VisitCancelComponent implements OnInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  public telemedicineActive: boolean = false;

  isError = false;
  error = "";
  isMsg = false;
  message = "";
  guid: string;
  finished = false;
  loadingMessage: string;
  @Input() visit: GetResponse;
  @Output() canceled: EventEmitter<GetResponse> =
    new EventEmitter<GetResponse>();

  loadingVisitData: boolean;
  constructor(
    private visitService: VisitService,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService
  ) {
    this.clientService.configChange
      .pipe(takeUntil(this.$destroy))
      .subscribe((model) => {
        this.telemedicineActive = model.telemedicineActive;
      });
  }

  ngOnInit() {
    this.finished = false;
    this.guid = this.route.snapshot.params.guid;
    if (typeof this.guid != "undefined") {
      this.loadingVisitData = true;
      this.loadingMessage = "Trwa pobieranie danych wizyty";
      this.visitService.getByGuid(this.guid).subscribe(
        (v: GetResponse) => {
          if (v == null) {
            this.isError = true;
            this.error = "Nie znaleziono wizyty";
          }
          this.visit = v;
          this.loadingVisitData = false;
        },
        (error: any) => {
          this.isError = true;
          this.error = ErrorProcessor.process(error);
          this.loadingVisitData = false;
        }
      );
    }
  }

  cancelVisit() {
    if (!this.visit.canCancel) {
      this.isError = true;
      this.error = this.visit.cantCancelMessage;
      this.finished = true;
      return;
    }

    this.loadingMessage = "Trwa odwoływanie wizyty";
    this.loadingVisitData = true;
    this.visitService.cancel(this.visit.visitId).subscribe(
      (result: VisitCancelResponse) => {
        this.finished = true;
        this.loadingVisitData = false;
        if (result.success) {
          this.isMsg = true;
          this.isError = false;
          this.message = result.message;
          this.visitService.removeVisitFromStore(this.visit.visitId);
        } else {
          this.isMsg = false;
          this.isError = true;
          this.error = result.message;
        }
      },
      (error: any) => {
        this.finished = true;
        this.loadingVisitData = false;
        this.isMsg = false;
        this.isError = true;
        this.error = ErrorProcessor.process(error);
      }
    );
  }

  cancel() {
    if (this.guid == null) this.canceled.emit(this.visit);
    else {
      this.router.navigate(["/"]);
    }
  }
}
