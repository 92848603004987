﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CaptchaService {
  
    constructor(private http: HttpClient) {
    }

    verify(response: string) {
       // return this.http.post<any>('api/Captcha/Verify', { captchaResponse: response });
    }
}