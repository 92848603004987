﻿export class Notification {
    constructor(public type: NotificationType, public message: string) {
        switch (this.type) {
        case NotificationType.Success:
            this.class = 'alert-success';
            break;
        case NotificationType.Info:
            this.class = 'alert-info';
            break;
        case NotificationType.Warning:
            this.class = 'alert-warning';
            break;
        case NotificationType.Error:
            this.class = 'alert-danger';
            break;
        }
    }

    class: string;
}

export enum NotificationType {
    Success,
    Info,
    Warning,
    Error
}