﻿import { NamedValueResponse } from "../../../shared/models/named-value-response.model";

export class SearchResponseStatus {
  constructor(public message: string, public terms: SearchResponse[]) {}
}

export class SearchResponse {
  constructor(
    public scheduleId: number,
    public hourStartId: number,
    public hourEndId: number,
    public date: Date,
    public dateShow: string,
    public hour: string,
    public doctor: string,
    public department: string,
    public price: number,
    public changeVsitDate: boolean,
    public visitTypes: VisitType[]
  ) {}

  public isPaying: boolean;

  public typeSelected: number;
  public typeNameSelected: string;
  public token: string | null;
}

export class VisitType extends NamedValueResponse {}
