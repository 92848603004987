import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';

import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { ClientService } from './../shared/services/client.service';
import { PersonalDetail } from '../shared/models/personal-detail.model';
import { MedsoftValidators } from './../shared/validators/validators';
import { ConfigModel } from './../shared/models/config.model';
import { Term } from '../shared/models/term-model';
import { Captcha } from 'primeng/captcha'
import { DateHelper } from '../shared/date.helper';
import { InputMaskModule } from 'primeng/inputmask';

@Component({
    selector: 'personal-detail',
    templateUrl: './personal-detail.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ],
    styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit {

    hasRules: boolean;
    rulesUrl: string = '/api/Client/Rules';
    hasPrivacyPolicy: boolean;
    terms: Array<Term>;
    privacyPolicyUrl: string = '/api/Client/PrivacyPolicy';
    validate = false;
    registerAditionalInfo = '';
    allowMultiConsents: boolean;
    allowRegistrationForForeigners = false;
    registrationWithBirthDate = false;
    form: FormGroup;


    @Input()
    btnSubmitLabel: string;

    @Input()
    btnSubmitIcon: string;

    @Input()
    noAgreementMessage: string;

    @Input()
    forCreatingAccount: boolean;

    @Input()
    useCaptcha: boolean;

    _isError: boolean;
    _submitingForm: boolean;
    _error: string;

    @Input()
    get isError() {
        return this._isError;
    }

    @Input()
    get error() {
        return this._error;
    }

    @Input()
    get submitingForm() {
        return this._submitingForm;
    }

    @Output()
    isErrorChange = new EventEmitter<boolean>();

    @Output()
    errorChange = new EventEmitter<string>();

    @Output()
    submitingFormChange = new EventEmitter<boolean>()

    set isError(value: boolean) {
        this._isError = value;
        this.isErrorChange.emit(this._isError);

        if (this._isError && typeof this.captcha !== 'undefined') {
            this.captcha.reset();
        }
    };

    set error(value: string) {
        this._error = value;
        this.errorChange.emit(this._error);
    };

    set submitingForm(value: boolean) {
        this._submitingForm = value;
        this.submitingFormChange.emit(this._submitingForm);
    }


    @Output()
    formSubmit = new EventEmitter<PersonalDetail>(false);

    calendar_pl = {
        firstDayOfWeek: 1,
        dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Priątek", "Sobota"],
        dayNamesShort: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"],
        dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
        monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
        today: 'Dzisiaj',
        clear: 'Wyczyść'
    };

    dateRange: string;
    captchaResponse: string | null;

    constructor(private fb: FormBuilder, private clientService: ClientService) {

    }

    @ViewChild(Captcha)
    captcha: Captcha;

    ngOnInit() {
        this.init(this.clientService.config);
        let now = new Date();
        this.dateRange = `${now.getFullYear() - 120}:${now.getFullYear()}`;
        this.captchaResponse = null;
    }

    init(model: ConfigModel) {
        this.hasRules = model.hasRules;
        this.hasPrivacyPolicy = model.hasPrivacyPolicy;
        this.registerAditionalInfo = model.registerAditionalInfo;
        this.allowRegistrationForForeigners = model.allowRegistrationForForeigners;
        this.registrationWithBirthDate = model.registrationWithBirthDate;
        this.allowMultiConsents = model.termsOfServices != null;

        this.createForm();

        if (this.allowMultiConsents) {
            this.terms = model.termsOfServices;
            this.addTerms();
        }
    }

    createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            surname: ['', Validators.required],
            pesel: ['', this.registrationWithBirthDate ? [] : Validators.required],
            birthDate: ['', this.registrationWithBirthDate ? Validators.required : []],
            phone: ['', [Validators.required]],
            dontHavePesel: [false],
            rules: [false, Validators.requiredTrue],
            tos: new FormArray([]),
            passwordsGroup: this.fb.group(
                {
                    password: ['', this.forCreatingAccount ? Validators.required : []],
                    confirmPassword: ['', this.forCreatingAccount ? Validators.required : []]
                },
                { validator: [MedsoftValidators.equalValidator] }
            )
        });

        this.form.controls["dontHavePesel"].valueChanges.subscribe((val: boolean) => {
            if (val) {
                this.form.controls['pesel'].setValidators([]);
            } else {
                this.form.controls['pesel'].setValidators([Validators.required]);
            }
            this.form.controls['pesel'].updateValueAndValidity();
        });
    }

    addTerms() {
        if (this.form == null) return;
        for (let i = 0; i < this.terms.length; i++) {
            (<FormArray>this.form.get('tos')).push(new FormControl(false, []));
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            this.validate = true;
            this._isError = true;
            this._error = 'Proszę poprawić wprowadzone dane';
            return;
        }

        if (this.useCaptcha && this.captchaResponse == null) {
            this.validate = true;
            this._isError = true;
            this._error = 'Potwierdź, że nie jesteś robotem';
            return;
        }

        this.isError = false;
        this.error = '';

        let model = new PersonalDetail();
        model.name = this.form.value.name;
        model.email = this.form.value.email;
        model.surname = this.form.value.surname;
        model.pesel = this.form.value.pesel;
        model.phone = this.form.value.phone;
        model.password = this.form.value.passwordsGroup.password;
        model.passwordConfirmation = this.form.value.passwordsGroup.confirmPassword;
        model.dontHavePesel = this.allowRegistrationForForeigners && this.form.value.dontHavePesel;
        model.birthDate = DateHelper.getDate(this.form.value.birthDate);
        model.captchaResponse = this.captchaResponse;

        let terms = <FormArray>this.form.get('tos');

        if (this.allowMultiConsents) {
            for (let i = 0; i < this.terms.length; i++) {
                if (terms.at(i).value === true) {
                    model.acceptedTos.push(this.terms[i].termID);
                }
            }
        }

        this.formSubmit.emit(model);
        this.captchaResponse = null;
    }

    captchaOnResponse(event: any) {
        this.captchaResponse = event.response;
    }

    captchaOnExpire() {
        this.captchaResponse = null;
    }

    get tos() {
        return this.form.get('tos') as FormArray;
    }
    get showRegisterAditionalInfo(): boolean {
        return this.registerAditionalInfo !== '';
    }

    get okName(): boolean {
        return !this.form.hasError('required', ['name']) || (this.form.get('name')!.pristine && !this.validate);
    }

    get okSurname(): boolean {
        return !this.form.hasError('required', ['surname']) || (this.form.get('surname')!.pristine && !this.validate);
    }

    get okPesel(): boolean {
        return !this.form.hasError('required', ['pesel']) || (this.form.get('pesel')!.pristine && !this.validate);
    }

    get okPhone(): boolean {
        return !this.form.hasError('required', ['phone']) || (this.form.get('phone')!.pristine && !this.validate);
    }

    get okPassword(): boolean {
        return !this.form.hasError('required', ['passwordsGroup', 'password']) ||
            (this.form.get(['passwordsGroup', 'password'])!.pristine && !this.validate);
    }


    get okPasswordsMatch(): boolean {
        return !this.form.hasError('equal', ['passwordsGroup']) ||
            (this.form.get(['passwordsGroup', 'confirmPassword'])!.pristine && !this.validate);
    }

    get okEmail(): boolean {
        return !this.form.hasError('email', ['email']) || (this.form.get('email')!.pristine && !this.validate);
    }

    get okEmailReq(): boolean {
        return !this.form.hasError('required', ['email']) || (this.form.get('email')!.pristine && !this.validate);
    }

    get okBirthDate(): boolean {
        return !this.form.hasError('required', ['birthDate']) || (this.form.get('birthDate')!.pristine && !this.validate);
    }

    get okRules(): boolean {
        return !this.form.hasError('required', ['rules']) || (this.form.get('rules')!.pristine && !this.validate);
    }

    okTos(index: number): boolean {
        let therm = (<FormArray>this.form.get('tos')).at(index);
        return !therm.hasError('required') || (therm.pristine && !this.validate);
    }
}
