﻿import { Component } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router } from '@angular/router';

import { UserService } from '../shared/services/user.service';
import { ClientService } from '../shared/services/client.service';
import { NotificationService } from '../shared/services/notification.service';
import { Notification } from '../shared/models/notification.model';
import { ConfigModel } from '../shared/models/config.model';


@Component({
    selector: 'menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0'}),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class MenuComponent {
    public logo: string = '/api/Client/Logo';
    public isLogged: boolean;
    notifications: Notification[];

    public isCookieConfirmed: boolean = false;
    hideRegistration: boolean | null;
    fullAccess: boolean;

    constructor(private router: Router, private userService: UserService, private notificationService: NotificationService, private clientService: ClientService) {
        this.notifications = [];

        this.isLogged = this.userService.isLoggedIn;

        this.clientService.configChange.subscribe(r => {
            this.hideRegistration = r.hideRegistration;
            this.fullAccess = r.fullAccess || false;
        });

        this.hideRegistration = this.clientService.config.hideRegistration;
        this.fullAccess = this.clientService.config.fullAccess || false;

        userService.loggedInStatus.subscribe(status => {
            this.isLogged = status;
            if (!status && this.userService.doRedirect) {
                this.router.navigate(['/login', this.userService.getRedirect()]);
            }
        });

        this.isCookieConfirmed = userService.cookieConfirmed;

        notificationService.notifications.subscribe(notification => {
            if (this.notifications.length === 0 || this.notifications[this.notifications.length - 1].message != notification.message)
                this.notifications.push(notification);

            setTimeout(() => {
                    this.notifications.shift();
                },
                5000);
        });
    }

    logout(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.userService.logout();
    }

    menu(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.userService.logout();
    }

    get isVisit() {
        return this.router.url === '/' || this.router.url === '/search-visit';
    }

    get isLogin() {
        return this.router.url === '/login';
    }

    get isRegister() {
        return this.router.url === '/register';
    }

    get isPatient() {
        return this.router.url === '/patient';
    }

    get isVisits() {
        return this.router.url === '/my-visits';
    }

    hideCookieBanner(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.userService.cookieConfirmed = true;
        this.isCookieConfirmed = true;
    }
}

