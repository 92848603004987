
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Service } from '../models/service.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';

@Injectable()
export class ServiceService {
    private _services: ReplaySubject<Service[]>;
    private dataStore: {
        services: Service[]
    };

    constructor(private http: HttpClient, private notifications: NotificationService) {
        this.dataStore = { services: []};
        this._services = (new ReplaySubject(1) as ReplaySubject<Service[]>);
    }

    get(specialityId: number, departmentId: number, doctorId: number): Observable<Service[]> {
        return this.http
            .get<Service[]>(`api/Service/Get?specialityId=${specialityId}&departmentId=${departmentId}&doctorId=${doctorId}`).pipe(
            tap((data: Service[]) => {

                this.dataStore.services = [];
                data.forEach(t => this.dataStore.services.push(new Service(t.id, t.name, t.warnings)));
                this._services.next(Object.assign({}, this.dataStore).services);
                },
            error => this.notifications.handleHttpError(error)));
    }

    getServiceWarning(serviceId: number): string {
        const ind = this.dataStore.services.findIndex(x => x.id === serviceId);
        if (ind >= 0)
            return this.dataStore.services[ind].warnings;

        return '';
    }

    reset(){
        this.dataStore.services = [];
        this._services.next(Object.assign({}, this.dataStore).services);
    }

    get services() {
        return this._services.asObservable();
    }
}