﻿export class ErrorProcessor {

    static process(error: any): string {
        let ret: string;
        let err = error.error;
        if (error.status == 422) {
            ret = `<strong>${err.message}</strong><br>`;
            err.errors.forEach((e: any) => {
                ret += e.message + '<br>';
            });
            return ret;
        }
        if (error.status == 401) {
            return 'Nastąpiło wylogowanie';
        }

        if (err == null) {
            ret = "Wystąpił nieoczekiwany błąd";
        } else {
            ret = err.message;
        }
        return ret;
    }
}