﻿import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

import { UserService } from "./../shared/services/user.service";
import { ClientService } from "./../shared/services/client.service";
import { ErrorProcessor } from "./../shared/error-processor";
import { ConfirmResponse } from "./../shared/models/confirm-response.model";
import { VisitService } from "@app/visit/shared/services/visit.service";

@Component({
  templateUrl: "./confirm.component.html",
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        group([animate(".7s ease-out", style({ opacity: "1" }))]),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate(".8s ease-in", style({ opacity: "0" })),
      ]),
    ]),
  ],
})
export class ConfirmComponent implements OnInit {
  isError = false;
  error = "";
  isMsg = false;
  message = "";
  success = false;
  confirming: boolean = false;
  confirmed: boolean = false;
  confirmationMsg: string = "";

  isSms: boolean = false;

  confirmForm: FormGroup;

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private visitService: VisitService
  ) {
    this.titleService.setTitle("Medsoft - Potwierdź");
    this.createForm();
  }

  ngOnInit() {
    this.isSms = this.clientService.config.ifSmsOnRegister;
    let code = this.route.snapshot.params.id;
    this.confirming = typeof code !== "undefined" && code != null;
    if (this.confirming) this.confirmPatient(code.toString().substring(0, 5));
  }

  createForm() {
    this.confirmForm = this.fb.group({
      code: ["", Validators.required],
    });
  }

  onSubmit() {
    if (!this.confirmForm.valid) return;
    this.confirmPatient(this.confirmForm.value.code);
  }

  confirmPatient(code: any) {
    this.userService.confirm(code).subscribe(
      (result: any) => {
        this.isMsg = false;
        this.confirmed = true;
        if (result.success) {
          this.success = true;

          if (result.visitBookToken) {
            this.visitService
              .getBookedVisitByToken(result.visitBookToken)
              .subscribe(
                () => {
                  this.router.navigate(["/login/booking-status"], {
                    queryParams: {
                      token: result.visitBookToken,
                    },
                  });
                },
                (err) => {
                  this.router.navigate(["/login"]);
                }
              );
          } else {
            this.router.navigate(["/login"]);
          }
        } else {
          this.isError = true;
          this.error = "Wystąpił nieoczekiwany błąd";
        }
      },
      (error: any) => {
        this.confirmed = true;
        this.isError = true;
        this.isMsg = false;
        this.error = ErrorProcessor.process(error);
      }
    );
  }

  resend(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.userService.resendConfirmation().subscribe(
      (result: ConfirmResponse) => {
        if (result.success) {
          this.isMsg = true;
          this.message =
            result.message === "" ? "Kod został wysłany" : result.message;
        } else {
          this.isError = true;
          this.error =
            result.message === ""
              ? "Wystąpił nieoczekiwany błąd"
              : result.message;
        }
      },
      (error: any) => {
        this.isError = true;
        this.error = ErrorProcessor.process(error);
      }
    );
  }

  get okCode(): boolean {
    return (
      !this.confirmForm.hasError("required", ["code"]) ||
      this.confirmForm.get("code")!.pristine
    );
  }
}
