<nav [@fadeInOut] class="navbar navbar-default navbar-fixed-top">
    <div class="container">
        <div class="navbar-header">
            <button *ngIf="fullAccess" type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand logo" href="/">
                <img [src]="logo" />
            </a>
        </div>
        <div *ngIf="fullAccess" class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav navbar-right menu">
                <li>
                    <a [routerLink]="['search-visit']" [class.active]="isVisit">Rezerwuj wizytę</a>
                </li>
                <ng-container *ngIf="hideRegistration != null && !hideRegistration">
                    <ng-container *ngIf="isLogged">
                        <li>
                            <a [routerLink]="['my-visits']" [class.active]="isVisits">Moje wizyty</a>
                        </li>
                        <li>
                            <a [routerLink]="['patient']" [class.active]="isPatient">Moje dane</a>
                        </li>
                        <li>
                            <a (click)="logout($event)" [routerLink]="['']">Wyloguj</a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!isLogged">
                        <li>
                            <a [routerLink]="['login']" [class.active]="isLogin">Zaloguj</a>
                        </li>
                        <li>
                            <a [routerLink]="['register']" [class.active]="isRegister">Rejestracja</a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
</nav>

<div class="notifications">
    <div [@fadeInOut] *ngFor="let notification of notifications" class="alert {{notification.class}}">
        {{notification.message}}
    </div>
</div>

<div *ngIf="!isCookieConfirmed" class="cookie-law">
    <span>
        Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może działać lepiej. <a tabindex="0" href="http://wszystkoociasteczkach.pl" target="_blank">Dowiedz się więcej</a>
    </span>
    <button (click)="hideCookieBanner($event)" class="btn btn-sm btn-agree">Rozumiem</button>
</div>
