import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser';

import { VisitService } from '../shared/services/visit.service';
import { GetResponse } from '../shared/models/get-response.model';
import { Subscription } from 'rxjs';
import { ErrorProcessor } from '../../shared/error-processor';

import * as signalR from '@aspnet/signalr';
import { UserService } from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';
import { ClientService } from '@app/shared/services/client.service';

@Component({
    templateUrl: './e-visit-room.component.html',
    styleUrls: ['./e-visit-room.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class EVisitRoomComponent implements OnInit, OnDestroy, AfterViewInit {

    visit: GetResponse;
    urlId: string;
    error = '';
    message = '';
    finished = false;
    isProcessing = false;

    noVideo = false;
    isDoctorConnected = false;
    waitingForDoctorMessage: string;

    volLvl = 0;

    filesInOneLine = false;

    validDrag: boolean;
    maxSize: number = 10485760;
    lastInvalids: any;

    hasOtherConnection = false;

    file: File[] | null;

    @ViewChild('patientVideo') patientVideo: ElementRef;
    @ViewChild('docVideo') docVideo: ElementRef;


    private stream: MediaStream;
    private streamClone: MediaStream;
    private javascriptNode: ScriptProcessorNode;

    private isNegotiating = false;

    private $user: Subscription;
    private $fileUpload: Subscription;
    private alertHideTimeout: any;

    private iceConfig: RTCConfiguration;

    private peerConnection: RTCPeerConnection;
    private hub : signalR.HubConnection;

    constructor(
        private titleService: Title, 
        private router: Router, 
        private visitService: VisitService, 
        private cdRef: ChangeDetectorRef,
        private userService: UserService,
        private clientService: ClientService) {

        this.iceConfig = environment.rtcConfiguration;

    }

    ngOnInit(): void {
        this.hasOtherConnection = false;
        this.isNegotiating = false;
        this.noVideo = false;
        this.titleService.setTitle('Medsoft - Realizacja wizyty');
        
        if(!history.state.data || !history.state.data.visit){
            this.router.navigate(['/my-visits']);
            return;
        }
        
        this.visit = <GetResponse>history.state.data.visit;
        this.urlId = history.state.data.urlId;

        this.waitingForDoctorMessage = this.getWaitingForDoctorMessage();
    }

    getWaitingForDoctorMessage(): string {
        let message = this.clientService.config.evisitWaitingForDoctorMessage;

        message = message.replace("<<lekarz>>", this.visit.doctor);
        message = message.replace("<<data>>", this.visit.date);
        message = message.replace("<<godzina>>", this.visit.hour);
        message = message.replace("<<oddzial>>", this.visit.department);

        return message;
    }

    ngAfterViewInit(){
        if(!history.state.data || !history.state.data.visit){
            return;
        }

        this.startConnection();
    }

    ngOnDestroy(){
        this.closeStreams();

        if(this.$user){
            this.$user.unsubscribe();
        }
        if(this.$fileUpload){
            this.$fileUpload.unsubscribe;
        }
        if(this.alertHideTimeout){
            window.clearTimeout(this.alertHideTimeout);
        }

        if(this.peerConnection){
            this.peerConnection.close();
        }

        if(this.hub){
            this.hub.send('ChatDisconnect', true, this.urlId ? this.urlId : this.userService.currentPatientOnlineId, this.token).finally(
                () => {
                    this.hub.stop();
                }
            );
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event: any) {
        if(this.hub){
            this.hub.send('ChatDisconnect', true, this.urlId ? this.urlId : this.userService.currentPatientOnlineId, this.token).finally(
                () => {
                    this.hub.stop();
                }
            );
        }
    }

    startConnection(){
        this.initializeHub();
    }

    afterTurnCredentials(){
        this.peerConnection = new RTCPeerConnection(this.iceConfig);

        this.peerConnection.onicecandidate = ({ candidate }) => {
            if(candidate)
                this.hub.send('Candidate', true, this.urlId ? this.urlId : this.userService.currentPatientOnlineId.toString(), this.token, JSON.stringify(candidate.toJSON()));
        }

        this.peerConnection.onnegotiationneeded = async () => {
            if (this.isNegotiating) {
                console.log("SKIP nested negotiations");
                return;
              }
              this.isNegotiating = true;
            try {
              await this.peerConnection.setLocalDescription(await this.peerConnection.createOffer());
              // send the offer to the other peer
              if(this.peerConnection.localDescription)
                this.hub.send('Description', true, this.urlId ? this.urlId : this.userService.currentPatientOnlineId, this.token, JSON.stringify(this.peerConnection.localDescription.toJSON()));
            } catch (err) {
              console.error(err);
            }
        };

        this.peerConnection.ontrack = (event) => {
            this.docVideo.nativeElement.srcObject = event.streams[0];
        };

        this.checkMicAndCamera();
    }

    initializeHub(){
        this.hub = new signalR.HubConnectionBuilder()
        .withUrl('/webRtcHub', {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
          })
        .build();

        this.hub.on('candidate',  async (candidate) => {
            this.isDoctorConnected = true;
            await this.peerConnection.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)));
        });
    
        this.hub.on('description', async (desc) => {
            desc = new RTCSessionDescription(JSON.parse(desc));
            if (desc.type === 'offer') {
                await this.peerConnection.setRemoteDescription(desc);

                // this.stream.getTracks().forEach((track) =>
                //     this.peerConnection.addTrack(track, this.stream));
                await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());
    
                // this.patientVideo.nativeElement.srcObject = this.streamClone;
    
                if(this.peerConnection.localDescription)
                    this.hub.send('Description', false, 1, this.visit.teleToken, JSON.stringify(this.peerConnection.localDescription.toJSON()));
    
            } else if (desc.type === 'answer') {
                await this.peerConnection.setRemoteDescription(new RTCSessionDescription(desc));
            } else {
                console.log('Unsupported SDP type.');
            }
        });

        this.hub.on('turnCredentials',  async (c) => {
            this.iceConfig.iceServers[0].username = c.user;
            this.iceConfig.iceServers[0].credential = c.pwd;
            this.afterTurnCredentials();
        });

        this.hub.on('hasOtherConnection',  async () => {
            this.hasOtherConnection = true;
        });

        this.hub.start().catch(err => console.log(err)).then(()=>{
            this.hub.send('GetTurnKey', true, this.urlId ? this.urlId : this.userService.currentPatientOnlineId, this.token);
        });
    }

    closeStreams(){
        if(this.javascriptNode)
            this.javascriptNode.disconnect();
        if(this.stream){
            this.stream.getTracks().forEach((track:any) => {
                track.stop();
            });
        }
        if(this.streamClone){
            this.streamClone.getTracks().forEach(function(track:any) {
                track.stop();
            });
        }
    }

    checkMicAndCamera(){
        let nav = <any>navigator;
        nav.getUserMedia = nav.getUserMedia || nav.webkitGetUserMedia || nav.mozGetUserMedia;
        if (nav.getUserMedia) {
            nav.getUserMedia({
                audio: true,
                video: true
                },
                (stream: MediaStream) => {
                    
                    this.stream = stream;

                    this.streamClone = stream.clone()
                    //usuwam audio, żeby nie było słychać dźwięku u pacjenta
                    this.streamClone.getAudioTracks().forEach((t) => this.streamClone.removeTrack(t));
                    this.patientVideo.nativeElement.srcObject = this.streamClone;
                    this.cdRef.detectChanges();

                    stream.getTracks().forEach((track) =>
                        this.peerConnection.addTrack(track, stream));

                },
                () => {
                    this.noVideo = true;
                    this.error = 'Nie udało nam się uzyskać dostępu do kamery i/lub mikrofonu. Są one wymagane do odbycia wizyty';
                    this.cdRef.detectChanges();
                    this.closeStreams();
                }
            );
        } else {
            this.noVideo = true;
            this.error = 'Twoja przeglądarka nie posiada obsługi kamery lub mikrofonu, który jest wymagany do przeprowadzenia wizyty. Prosimy o skorzystanie z innej przeglądarki internetowej.';
            this.cdRef.detectChanges();
            this.closeStreams();
        }
    }

    removeFile(event: any, i: number) {
        event.stopPropagation();
        event.preventDefault();
    
        if(this.file != null){
            this.file.splice(i, 1);
            
            if (this.file.length == 0)
                this.file = null;
        }
      }

      addFiles(){
        if(this.file == null){
          return;
        }

        this.isProcessing = true;
        this.clearMsg();
        this.clearError();

        this.$fileUpload = this.visitService.addFileToEvisit(this.visit.visitId, this.file, this.urlId)
        .subscribe(r => {
            this.file = null;
            this.isProcessing = false;
            if(r.success){
                this.message = r.message;
                this.alertHideTimeout = window.setTimeout(()=>{
                    this.clearMsg();
                }, 15000);
            }
        },
        e =>{
            this.file = null;
            this.isProcessing = false;
            this.error = ErrorProcessor.process(e);
            this.alertHideTimeout = window.setTimeout(()=>{
                this.clearError();
            }, 30000);
        });
      }

      private clearError(){
        this.error = '';
      }

      private clearMsg(){
        this.message = '';
      }

      get isMsg(): boolean{
          return !!this.message;
      }

      get isError(): boolean{
        return !!this.error;
    }
    
      get text(): string[] {
        return !!this.file
          ? this.file.map((f) => `${f.name} (${(f.size / 1024).toFixed(2)}KB)`)
          : ["Dodaj załączniki (max 10MB)"];
      }

      get token(): string{
          return this.visit.teleToken ? this.visit.teleToken : '';
      }

}
