import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title }     from '@angular/platform-browser';

import { VisitService } from '../shared/services/visit.service';
import { GetResponse } from '../shared/models/get-response.model';
import * as signalR from '@aspnet/signalr';

import { environment } from '../../../environments/environment';

@Component({
    templateUrl: './e-visit-doc-room.component.html',
    styleUrls: ['./e-visit-doc-room.component.scss'],
})
export class EVisitDocRoomComponent implements OnInit, OnDestroy, AfterViewInit {

    visit: GetResponse
    error = '';
    message = '';
    finished = false;
    isProcessing = false;

    docId: any | undefined;
    roomId: any | undefined;
    roomDocToken: any | undefined;

    noVideo = false;

    hasOtherConnection = false;

    @ViewChild('patientVideo') patientVideo: ElementRef;
    @ViewChild('docVideo') docVideo: ElementRef;


    private stream: MediaStream;
    private streamClone: MediaStream;
    private javascriptNode: ScriptProcessorNode;

    private isNegotiating = false;

    private iceConfig: RTCConfiguration;

    private peerConnection: RTCPeerConnection;
    private hub : signalR.HubConnection;

    constructor(private titleService: Title, private activatedRoute: ActivatedRoute, private visitService: VisitService, private cdRef: ChangeDetectorRef) {
      this.iceConfig = environment.rtcConfiguration;
    }

    ngOnInit(): void {
        this.hasOtherConnection = false;
        this.isNegotiating = false;
        this.noVideo = false;
        this.titleService.setTitle('Medsoft - Realizacja wizyty - Lekarz');
        
        this.docId = this.activatedRoute.snapshot.paramMap.get('docId');
        this.roomDocToken = this.activatedRoute.snapshot.paramMap.get('roomDocToken');
    }

    ngAfterViewInit(){
        this.startConnection();
    }

    ngOnDestroy(){
        this.closeStreams();

        if(this.peerConnection){
            this.peerConnection.close();
        }
        if(this.hub){
            this.hub.send('ChatDisconnect', false, this.docId, this.roomDocToken).finally(
                () => {
                    this.hub.stop();
                }
            );
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event: any) {
        if(this.hub){
            this.hub.send('ChatDisconnect', false, this.docId, this.roomDocToken).finally(
                () => {
                    this.hub.stop();
                }
            );
        }
    }

    startConnection(){
        this.initializeHub();
    }

    afterTurnCredentials(){
        this.peerConnection = new RTCPeerConnection(this.iceConfig);

        this.peerConnection.onicecandidate = ({ candidate }) => {
            if(candidate)
                this.hub.send('Candidate', false, this.docId, this.roomDocToken, JSON.stringify(candidate.toJSON()));
        }

        this.peerConnection.onnegotiationneeded = async () => {
            if (this.isNegotiating) {
                console.log("SKIP nested negotiations");
                return;
              }
              this.isNegotiating = true;
            try {
              await this.peerConnection.setLocalDescription(await this.peerConnection.createOffer());
              // send the offer to the other peer
              if(this.peerConnection.localDescription)
                this.hub.send('Description', false, this.docId, this.roomDocToken, JSON.stringify(this.peerConnection.localDescription.toJSON()));
            } catch (err) {
              console.error(err);
            }
        };

        this.peerConnection.ontrack = (event) => {
            this.patientVideo.nativeElement.srcObject = event.streams[0];
        };

        this.checkMicAndCamera();
    }

    initializeHub(){
        this.hub = new signalR.HubConnectionBuilder()
        .withUrl('/webRtcHub', {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
          })
        .build();

        this.hub.on('candidate',  async (candidate) => {
            await this.peerConnection.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)));
        });

        this.hub.on('chatDisconnect',  async (candidate) => {
            this.patientVideo.nativeElement.srcObject = null;
        });
    
        this.hub.on('description', async (desc) => {
            desc = new RTCSessionDescription(JSON.parse(desc));
            if (desc.type === 'offer') {
                await this.peerConnection.setRemoteDescription(desc);

                // this.stream.getTracks().forEach((track) =>
                //     this.peerConnection.addTrack(track, this.stream));
                await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());
    
                // this.docVideo.nativeElement.srcObject = this.streamClone;
    
                if(this.peerConnection.localDescription)
                    this.hub.send('Description', false, this.docId, this.roomDocToken, JSON.stringify(this.peerConnection.localDescription.toJSON()));
    
            } else if (desc.type === 'answer') {
                await this.peerConnection.setRemoteDescription(desc);
            } else {
                console.log('Unsupported SDP type.');
            }
        });

        this.hub.on('turnCredentials',  async (c) => {
            this.iceConfig.iceServers[0].username = c.user;
            this.iceConfig.iceServers[0].credential = c.pwd;
            this.afterTurnCredentials();
        });

        this.hub.on('hasOtherConnection',  async () => {
            this.hasOtherConnection = true;
        });

        this.hub.start().catch(err => console.log(err)).then(()=>{
            this.hub.send('GetTurnKey', false, this.docId, this.roomDocToken);
        });
    }

    closeStreams(){
        if(this.javascriptNode)
            this.javascriptNode.disconnect();
        if(this.stream){
            this.stream.getTracks().forEach(function(track:any) {
                track.stop();
            });
        }
        if(this.streamClone){
            this.streamClone.getTracks().forEach(function(track:any) {
                track.stop();
            });
        }
    }

    checkMicAndCamera(){
        let nav = <any>navigator;
        nav.getUserMedia = nav.getUserMedia || nav.webkitGetUserMedia || nav.mozGetUserMedia;
        if (nav.getUserMedia) {
            nav.getUserMedia({
                audio: true,
                video: true
                },
                (stream: MediaStream) => {
                    
                    this.stream = stream;

                    this.streamClone = stream.clone()
                    //usuwam audio, żeby nie było słychać dźwięku u pacjenta
                    this.streamClone.getAudioTracks().forEach((t) => this.streamClone.removeTrack(t));
                    this.docVideo.nativeElement.srcObject = this.streamClone;
                    this.cdRef.detectChanges();

                    stream.getTracks().forEach((track) =>
                        this.peerConnection.addTrack(track, stream));
                },
                () => {
                    this.noVideo = true;
                    this.error = 'Nie udało nam się uzyskać dostępu do kamery i/lub mikrofonu. Są one wymagane do odbycia wizyty';
                    this.cdRef.detectChanges();
                    this.closeStreams();
                }
            );
        } else {
            this.noVideo = true;
            this.error = 'Twoja przeglądarka nie posiada obsługi kamery lub mikrofonu, który jest wymagany do przeprowadzenia wizyty. Prosimy o skorzystanie z innej przeglądarki internetowej.';
            this.cdRef.detectChanges();
            this.closeStreams();
        }
    }
      private clearError(){
        this.error = '';
      }

      private clearMsg(){
        this.message = '';
      }

      get isMsg(): boolean{
          return !!this.message;
      }

      get isError(): boolean{
        return !!this.error;
    }

}
