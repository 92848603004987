﻿import { FormGroup } from '@angular/forms';

export class MedsoftValidators
{
    public static equalValidator({ value }: FormGroup): { [ket: string]: any
                                                        } | null {
        const [first, ...rest] = Object.keys(value || {});
        const valid = rest.every(v => value[v] === value[first]);

        return valid? null : { equal: true };
    }
}