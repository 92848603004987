import { Component, OnInit, OnDestroy, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';

import { Observable, Subscription } from 'rxjs';

import { VisitService } from '../shared/services/visit.service';
import { SearchResponse, VisitType} from '../shared/models/search-response.model';

import { ClientService } from './../../shared/services/client.service';
import { ConfigModel } from './../../shared/models/config.model';
import { GetResponse } from '../shared/models/get-response.model';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'visit-slot-list',
    templateUrl: './visit-slot-list.component.html',
    styleUrls: ['./visit-slot-list.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0', height: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                            animate('.4s ease-out', style({ height: '*' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1', height: '*' }),
                        animate('.6s ease-in', style({ opacity: '0', height: '0' })),
                        animate('.5s ease-in', style({ height: '0' })),
                    ]),
            ]),
    ]
})
export class VisitSlotListComponent implements OnInit, OnDestroy {
    float: boolean = false;
    @Output() goToSlots: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @ViewChild("empty", { read: ElementRef }) empty: ElementRef;
    @ViewChild("list", { read: ElementRef }) list: ElementRef;
    @ViewChild("table", {read: ElementRef}) table: ElementRef;
    isError = false;
    error = '';
    showList = false;
    hasResults = false;
    showBookSlot = false;
    showRebookSlot = false;
    visits: Observable<SearchResponse[]>;
    canChangeVisitDate = false;
    selectedSlot: SearchResponse | null;
    showPrices: boolean = false;
    showPayment: boolean = false;
    blockWithoutPayment: boolean = false;
    patientVisits: GetResponse[];
    loadTerms: Observable<boolean>;

    public telemedicineActive: boolean = false;

    private $loadTerms: Subscription;

    constructor(private visitService: VisitService, private clientService: ClientService, private userService: UserService) {
        this.showPrices = this.clientService.config.showPrices;
        this.showPayment = this.clientService.config.paymentsActive;
        this.blockWithoutPayment = this.clientService.config.blockWithoutPayment;
        clientService.configChange.subscribe((model: ConfigModel) => {
            this.showPrices = model.showPrices;
            this.showPayment = model.paymentsActive;
            this.blockWithoutPayment = model.blockWithoutPayment;
            this.telemedicineActive = model.telemedicineActive;
        });

        this.onScroll = this.onScroll.bind(this);
    }

    ngOnInit(): void {
        this.showList = false;
        this.bindData();
        window.addEventListener('scroll', this.onScroll);
        this.visitService.patientVisits.subscribe(data => {
            this.patientVisits = data;
            this.canChangeVisitDate = data.length == 0 || !data.every(v => v.hasPayment);
            if (this.userService.isLoggedIn) {
                this.visitService.checkLimit().subscribe(limitReached => {
                    if (!this.canChangeVisitDate && limitReached && this.hasResults)
                        this.showRebooking();
                    else this.canChangeVisitDate = true;
                });
            }
            else this.canChangeVisitDate = true;
        });
    }

    ngOnDestroy(): void {
      window.removeEventListener('scroll', this.onScroll);
      if (this.$loadTerms)
        this.$loadTerms.unsubscribe();
    }

    onScroll(e: any) {
        if (this.showList && this.table)
          this.float = this.table.nativeElement.getBoundingClientRect().top < 53;
    }

    bindData() {
        this.visits = this.visitService.visitSlots;
        this.visits.subscribe((result: SearchResponse[]) => {
            this.visitService.getBySpeciality();
            this.showBookSlot = false;
            this.showRebookSlot = false;
            this.showList = true;
            this.hasResults = result.length > 0;

            if(!this.telemedicineActive){
                const visitType = [new VisitType(1, "Wizyta stacjonarna")];
            
                result.forEach(r=>r.visitTypes = visitType)
            }
            setTimeout(() => {
              if (this.hasResults) {
                this.goToSlots.emit(this.list);
              }
                else {
                    let resultError = this.visitService.visitSlotsError;

                    resultError.subscribe(message=>{
                        this.error = message;
                        this.isError = true;
                        this.goToSlots.emit(this.empty);
                    });
                }
            })
        });
      this.loadTerms = this.visitService.loadTerms;
      this.$loadTerms = this.loadTerms.subscribe();
    }

    showBooking(slot: SearchResponse) {
        //this.showList = false;
        
        if(slot.visitTypes.length === 1){
            slot.typeSelected = slot.visitTypes[0].id;
            slot.typeNameSelected = slot.visitTypes[0].name;
        } else{
            slot.typeSelected = 0;
        }
        
        this.showBookSlot = true;
        this.selectedSlot = slot;
    }

    hideBooking() {
        this.showList = true;
        this.showBookSlot = false;
    }

    showRebooking(slot: SearchResponse | null = null) {
        this.showRebookSlot = true;
        this.selectedSlot = slot;
    }

    hideRebooking() {
        this.showList = true;
        this.showRebookSlot = false;
    }
}
