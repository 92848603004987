
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Language } from '../models/language.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';

@Injectable()
export class LanguageService {
    private _languages: ReplaySubject<Language[]>;
    private dataStore: {
        languages: Language[]
    };

    constructor(private http: HttpClient, private notifications: NotificationService) {
        this.dataStore = { languages: []};
        this._languages = (new ReplaySubject(1) as ReplaySubject<Language[]>);
    }

    get(): Observable<Language[]> {
        return this.http
            .get<Language[]>(`api/Language/Get`).pipe(
            tap((data: Language[]) => {

                this.dataStore.languages = [];
                data.forEach(t => this.dataStore.languages.push(new Language(t.id, t.name)));
                this._languages.next(Object.assign({}, this.dataStore).languages);
                },
            error => this.notifications.handleHttpError(error)));
    }

    get languages() {
        return this._languages.asObservable();
    }
}