import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './services/user.service';
import { ClientService } from './services/client.service';
import { map } from 'rxjs/operators';
import { ConfigModel } from './models/config.model';
import { Observable } from 'rxjs';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private user: UserService, private clientService: ClientService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.clientService.config.hideRegistration == null) {
            return this.clientService.configChange.pipe(map((config: ConfigModel) => {
                if (!config.hideRegistration) {
                    if (this.user.isLoggedIn) {
                        return true;
                    } else {
                        this.router.navigate(['/login']);
                        return false;
                    }
                }
                return true;
            }));
        }
        else {
            if (!this.clientService.config.hideRegistration) {
                if (this.user.isLoggedIn || !!sessionStorage.getItem('isTempUser')) {
                    return true;
                } else {
                    this.router.navigate(['/login']);
                    return false;
                }
            }
            return true;
        }
    }
}