import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { Observable, ReplaySubject, BehaviorSubject } from "rxjs";

import { Hour } from "../models/hour.model";
import { DatesWithFreeSlotsResponse } from "../models/dates-with-free-slots-response.model";
import { BookSlotResponse } from "../models/book-slot-response.model";
import { GetPaymentStatusResponse } from "../../../shared/models/get-payment-status-response.model";
import {
  SearchResponse,
  SearchResponseStatus,
} from "../models/search-response.model";
import { GetResponse } from "../models/get-response.model";
import { VisitCancelResponse } from "../models/visit-cancel-response.model";
import { NotificationService } from "../../../shared/services/notification.service";
import { UserService } from "../../../shared/services/user.service";
import { ClientService } from "../../../shared/services/client.service";
import {
  ConfirmPresence,
  ConfirmPresenceResponse,
} from "../models/confirm-presence.model";
import { ChangeType, ChangeTypeResponse } from "../models/change-type.model";
import { BaseResponse } from "../../../shared/models/base-response.model";
import { VisitBookInfoResponse } from "../models/visit-book-info-response.model";

@Injectable()
export class VisitService {
  private _hours: ReplaySubject<Hour[]>;
  private _datesWithFreeSlots: ReplaySubject<DatesWithFreeSlotsResponse[]>;
  private _visitSlots: ReplaySubject<SearchResponse[]>;
  private _visitSlotsError: ReplaySubject<string>;
  private _patientVisits: ReplaySubject<GetResponse[]>;
  private _loadCalendar: ReplaySubject<boolean>;
  private _loadTerms: ReplaySubject<boolean>;
  private _reloadCalendar: ReplaySubject<void>;
  private dataStore: {
    hours: Hour[];
    datesWithFreeSlots: DatesWithFreeSlotsResponse[];
    visitSlots: SearchResponse[];
    visitSlotsError: string;
    patientVisits: GetResponse[];
    visitToBook: SearchResponse | null;
    visitToRemove: number | null;
    filters: any;
    lastBookedVisitId: number;
  };

  constructor(
    private http: HttpClient,
    private notifications: NotificationService,
    private router: Router,
    private userService: UserService
  ) {
    this.clear();
  }

  clear() {
    this.dataStore = {
      hours: [],
      datesWithFreeSlots: [],
      visitSlots: [],
      visitSlotsError: null,
      patientVisits: [],
      filters: {},
      visitToBook: null,
      visitToRemove: null,
      lastBookedVisitId: -1,
    };
    this._hours = new ReplaySubject(1) as ReplaySubject<Hour[]>;
    this._datesWithFreeSlots = new ReplaySubject(1) as ReplaySubject<
      DatesWithFreeSlotsResponse[]
    >;
    this._visitSlots = new ReplaySubject(1) as ReplaySubject<SearchResponse[]>;
    this._visitSlotsError = new ReplaySubject(1) as ReplaySubject<string>;
    this._patientVisits = new ReplaySubject(1) as ReplaySubject<GetResponse[]>;
    this._loadCalendar = new ReplaySubject(1) as ReplaySubject<boolean>;
    this._loadTerms = new ReplaySubject(1) as ReplaySubject<boolean>;
    this._reloadCalendar = new ReplaySubject(1) as ReplaySubject<void>;
  }

  getHours() {
    let sub = this.http.get<Hour[]>(`api/Visit/GetHours`).subscribe(
      (data: Hour[]) => {
        this.dataStore.hours = [];
        data.forEach((t) => this.dataStore.hours.push(new Hour(t.id, t.name)));
        this._hours.next(Object.assign({}, this.dataStore).hours);
        sub.unsubscribe();
      },
      (error) => {
        this.notifications.handleHttpError(error);
        sub.unsubscribe();
      }
    );
  }

  getDatesWithFreeSlots(
    specialityId: number,
    doctorId: number,
    timeOfDay: number,
    weekDays: number[],
    languageId: number,
    serviceId: number,
    departmentId: number,
    visitTypeId: number[],
    date: Date,
    forceReload: boolean
  ) {
    let weekDaysQuery: string = "";
    weekDays.forEach((d) => (weekDaysQuery += "&weekDays=" + d.toString()));

    let visitTypesQuery: string = "";
    visitTypeId.forEach(
      (vt) => (visitTypesQuery += "&visitType=" + vt.toString())
    );

    this.dataStore.filters = {
      specialityId,
      doctorId,
      timeOfDay,
      languageId,
      serviceId,
      departmentId,
      visitTypesQuery,
      date,
    };

    let dateQuery = date != null ? this.getDate(date) : "null";

    this.http
      .get<DatesWithFreeSlotsResponse[]>(
        `api/Visit/DatesWithFreeSlots?specialityId=${specialityId}&doctorId=${doctorId}&timeOfDay=${timeOfDay}&languageId=${languageId}&serviceId=${serviceId}&departmentId=${departmentId}${weekDaysQuery}${visitTypesQuery}&date=${dateQuery}`
      )
      .subscribe(
        (data: DatesWithFreeSlotsResponse[]) => {
          this.dataStore.datesWithFreeSlots = [];
          this.dataStore.datesWithFreeSlots = data;
          this._datesWithFreeSlots.next(
            Object.assign({}, this.dataStore).datesWithFreeSlots
          );
          this.changeCalendarState(false);
          if (forceReload) {
            this._reloadCalendar.next();
          }
        },
        (error) => this.notifications.handleHttpError(error)
      );
  }

  search(date: Date | null) {
    this.changeTermResultState(true);
    if (date == null) {
      this.dataStore.visitSlots = [];
      this._visitSlots.next(Object.assign({}, this.dataStore).visitSlots);
      this.changeCalendarState(false);
      this.changeTermResultState(false);
      return;
    }
    let f = this.dataStore.filters;
    let dateQuery = this.getDate(date);
    this.http
      .get<SearchResponseStatus>(
        `api/Visit/Search?specialityId=${f.specialityId}&doctorId=${f.doctorId}&timeOfDay=${f.timeOfDay}&languageId=${f.languageId}&serviceId=${f.serviceId}&departmentId=${f.departmentId}&date=${dateQuery}${f.visitTypesQuery}`
      )
      .subscribe(
        (data: SearchResponseStatus) => {
          this.dataStore.visitSlots = [];
          this.dataStore.visitSlots = data.terms;
          this.dataStore.visitSlotsError = null;
          this.dataStore.visitSlotsError = data.message;
          this._visitSlots.next(Object.assign({}, this.dataStore).visitSlots);
          this._visitSlotsError.next(
            Object.assign({}, this.dataStore).visitSlotsError
          );
          this.changeTermResultState(false);
        },
        (error) => this.notifications.handleHttpError(error)
      );
  }

  get(specialityId: number = -1) {
    if (this.userService.isLoggedIn) {
      this.http
        .get<GetResponse[]>(`api/Visit/Get?specialityId=${specialityId}`)
        .subscribe(
          (data: GetResponse[]) => {
            this.dataStore.patientVisits = [];
            this.dataStore.patientVisits = data;
            this._patientVisits.next(
              Object.assign({}, this.dataStore).patientVisits
            );
          },
          (error) => this.notifications.handleHttpError(error)
        );
    } else {
      this._patientVisits.next([]);
    }
  }

  getByGuid(guid: string) {
    return this.http.get<GetResponse>(`api/Visit/GetByGuid?Guid=${guid}`);
  }

  getByUrlId(urlId: string) {
    return this.http.get<GetResponse>(`api/Visit/GetByUrlId?urlId=${urlId}`);
  }

  getBySpeciality() {
    let f = this.dataStore.filters;
    this.get(f.specialityId || -1);
  }

  changeVisitType(
    visitId: number,
    visitTypeId: number,
    urlId: string
  ): Observable<ChangeTypeResponse> {
    return this.http
      .put<ChangeTypeResponse>(
        "api/Visit/ChangeType",
        new ChangeType(visitId, visitTypeId, urlId)
      )
      .pipe(
        tap(
          () => {},
          (error) => this.notifications.handleHttpError(error, "", true)
        )
      );
  }

  confirmPresence(
    visitId: number,
    urlId: string
  ): Observable<ConfirmPresenceResponse> {
    return this.http
      .put<ConfirmPresenceResponse>(
        "api/Visit/ConfirmPresence",
        new ConfirmPresence(visitId, urlId)
      )
      .pipe(
        tap(
          () => {},
          (error) => this.notifications.handleHttpError(error, "", true)
        )
      );
  }

  addFileToEvisit(
    visitId: any,
    files: File[],
    urlId: string
  ): Observable<BaseResponse> {
    let formData = new FormData();
    formData.append("visitId", visitId);

    if (urlId) {
      formData.append("urlId", urlId);
    }

    if (files)
      for (let f of files) {
        formData.append("file", f);
      }

    return this.http.put<BaseResponse>("api/Visit/EvisitFile", formData).pipe(
      tap(
        () => {},
        (error) => this.notifications.handleHttpError(error, "", true)
      )
    );
  }

  cancel(visitId: number) {
    return this.http
      .delete<VisitCancelResponse>(`api/Visit/Delete/${visitId}`)
      .pipe(
        tap(
          (data: VisitCancelResponse) => {},
          (error) => this.notifications.handleHttpError(error, "", true)
        )
      );
  }

  cancelByGuid(guid: string) {
    return this.http
      .delete<VisitCancelResponse>(`api/Visit/DeleteByGuid/${guid}`)
      .pipe(
        tap(
          (data: VisitCancelResponse) => {},
          (error) => this.notifications.handleHttpError(error, "", true)
        )
      );
  }

  startBookingVisit(visit: SearchResponse, oldVisitID: number | null = null) {
    this.dataStore.visitToBook = visit;
    this.dataStore.visitToRemove = oldVisitID;
    this.router.navigate(["/booking-status"], { skipLocationChange: true });
  }

  removeVisitFromStore(visitId: number) {
    const ind = this.dataStore.patientVisits.findIndex(
      (x) => x.visitId === visitId
    );
    this.dataStore.patientVisits.splice(ind, 1);
    this._patientVisits.next(Object.assign({}, this.dataStore).patientVisits);
  }

  finishBookingVisit(): Observable<BookSlotResponse> {
    if (this.dataStore.visitToBook == null) {
      let res = new BookSlotResponse();
      res.success = false;
      res.message = "Nie udało się umówić wizyty";
      let ret = new BehaviorSubject(res);
      return ret.asObservable();
    }
    let slot: SearchResponse = this.dataStore.visitToBook!;
    return this.http
      .post<BookSlotResponse>(`api/Visit/Insert`, {
        specialityID: this.dataStore.filters.specialityId,
        serviceId: this.dataStore.filters.serviceId,
        hourEndId: slot.hourEndId,
        hourStartId: slot.hourStartId,
        date: slot.dateShow,
        scheduleId: slot.scheduleId,
        isPaying: slot.isPaying,
        oldVisitId: this.dataStore.visitToRemove,
        visitTypeId: slot.typeSelected,
      })
      .pipe(
        tap(
          (data: BookSlotResponse) => {
            this.dataStore.lastBookedVisitId = data.visitId;
            this.saveLastBookedVisitId(data.visitId);
            this.dataStore.visitToBook = null;
            if (!slot.isPaying) this.userService.deleteTemporaryAccount();
          },
          (error) => {
            if (error.status === 401) this.userService.logout("booking-status");
            else this.dataStore.visitToBook = null;
          }
        )
      );
  }

  getPaymentStatus(): Observable<GetPaymentStatusResponse> {
    if (!this.hasVisitForPayment()) {
      this.userService.deleteTemporaryAccount();
      let res = new GetPaymentStatusResponse();
      res.success = false;
      res.message = "Wystąpił nieoczekiwany błąd";
      let ret = new BehaviorSubject(res);
      return ret.asObservable();
    }
    let visitId = this.getLastBookedVisitId();
    return this.http
      .get<GetPaymentStatusResponse>(
        `api/Visit/GetPaymentStatusForVisit?visitId=${visitId}`
      )
      .pipe(
        tap(
          (data: GetPaymentStatusResponse) => {
            if (data.checkAgain === false) {
              this.userService.deleteTemporaryAccount();
              this.clearVisitForPayment();
            }
          },
          (error) => {
            this.userService.deleteTemporaryAccount();
            if (error.status === 401) this.userService.logout("after-payment");
            else this.dataStore.visitToBook = null;
          }
        )
      );
  }

  checkLimit(): Observable<boolean> {
    let f = this.dataStore.filters;
    return this.http.get<boolean>(
      `api/Visit/CheckLimit?specialityId=${f.specialityId}`
    );
  }

  saveBookingVisitInfo(): Observable<string> {
    if (this.dataStore.visitToBook == null) {
      let ret = new BehaviorSubject(null);
      return ret.asObservable();
    }

    let slot: SearchResponse = this.dataStore.visitToBook!;

    return this.http.post<string>(`api/Visit/InsertVisitBook`, {
      scheduleId: slot.scheduleId,
      date: slot.dateShow,
      hourStartId: slot.hourStartId,
      hourEndId: slot.hourEndId,
      serviceId: this.dataStore.filters.serviceId,
      isPaying: slot.isPaying,
      visitTypeId: slot.typeSelected,
      specialityId: this.dataStore.filters.specialityId,
    });
  }

  getBookedVisitByToken(token: string | null) {
    if (token && token.length > 0) {
      return this.http
        .get<VisitBookInfoResponse>(`api/Visit/SelectVisitBook?token=${token}`)
        .pipe(
          tap((ret) => {
            this.dataStore.visitToBook = new SearchResponse(
              ret.scheduleId,
              ret.hourStartId,
              ret.hourEndId,
              new Date(ret.dateShow),
              ret.dateShow,
              "",
              "",
              "",
              0,
              false,
              []
            );
            this.dataStore.visitToBook.token = token;
            this.dataStore.visitToBook.typeSelected = ret.patientVisitTypeId;
            this.dataStore.visitToBook.isPaying = ret.withPayment;
            this.dataStore.filters.specialityId = ret.specialityId;
            this.dataStore.filters.serviceId = ret.serviceId;
          })
        );
    }

    return new ReplaySubject().asObservable();
  }

  public getDate(date: Date | null) {
    var mm = date!.getMonth() + 1; // getMonth() is zero-based
    var dd = date!.getDate();

    return [
      date!.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  }

  public changeCalendarState(isLoading: boolean) {
    this._loadCalendar.next(isLoading);
  }

  public changeTermResultState(isLoading: boolean) {
    this._loadTerms.next(isLoading);
  }

  private saveLastBookedVisitId(visitId: number) {
    localStorage.setItem("lastBookedVisit", visitId.toString());
  }

  public getLastBookedVisitId(): number {
    let val = localStorage.getItem("lastBookedVisit");
    return val == null ? -1 : Number(val);
  }

  public hasVisitForPayment(): boolean {
    return this.getLastBookedVisitId() > 0;
  }

  public clearVisitForPayment() {
    this.saveLastBookedVisitId(-1);
  }

  get hours() {
    return this._hours.asObservable();
  }

  get datesWithFreeSlots() {
    return this._datesWithFreeSlots.asObservable();
  }

  get visitSlots() {
    return this._visitSlots.asObservable();
  }

  get patientVisits() {
    return this._patientVisits.asObservable();
  }

  get visitSlotsError() {
    return this._visitSlotsError.asObservable();
  }

  get visitToBook(): SearchResponse {
    return <SearchResponse>{ ...this.dataStore.visitToBook };
  }

  get loadTerms() {
    return this._loadTerms.asObservable();
  }

  get loadCalendar() {
    return this._loadCalendar.asObservable();
  }

  get reloadCalendar() {
    return this._reloadCalendar.asObservable();
  }
}
