
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject ,  BehaviorSubject ,  Observable, Subscription } from 'rxjs';

import { PersonalDetail } from '../models/personal-detail.model';
import { RegisterResponse } from '../models/register-response.model';
import { ConfirmResponse } from '../models/confirm-response.model';
import { RemindPasswordResponse } from '../models/remind-password-response.model';
import { ChangePasswordResponse } from '../models/change-password-response.model';
import { GetPatientResponse } from '../models/get-patient-response.model';

import { NotificationService } from './notification.service';

import { ChangeAcceptedToSResponse } from '../models/change-accepted-tos-response';
import { ClientService } from './client.service';
import { ConfigModel } from '../models/config.model';
import { Router } from '@angular/router';

@Injectable()
export class UserService {
    private loggedIn = false;
    private _loggedInBS = new ReplaySubject<boolean>(1);
    private _patientData = new ReplaySubject<GetPatientResponse>(1);
    private patientOnlineId: number = -1;
    private redirect: string = "";
    public doRedirect = true;
    private _cookieConfirmed = false;
    private dataStore: {
        patientData: GetPatientResponse
    };

    constructor(private http: HttpClient, private notificationService: NotificationService, private clientService: ClientService, private router: Router) {
        this.clear();
        if (this.clientService.config.hideRegistration) {
            this.logout('', false);
        }
        
        this.clientService.configChange.subscribe((config: ConfigModel) => {
            if (config.hideRegistration) {
                this.logout('', false);
            }
        });

        this.loggedIn = !!localStorage.getItem('access_token');
        this._cookieConfirmed = !!localStorage.getItem('cookieConfirmed');
        if (this.loggedIn && new Date(localStorage.getItem('expires') || "") < new Date()) {
            this.loggedIn = false;
            localStorage.removeItem('access_token');
        }

        this.notificationService.logouts.subscribe(() => {
            this.logout();
        });
    }

    clear() {
        this.redirect = '';
        this.dataStore = {
            patientData: new GetPatientResponse()
        }
    }

    login(email: string, password: string) {
        var loginStatus = new LoginStatus();
        return this.http
            .post<any>(
                'api/Account/Login',
                {
                    email: email,
                    password: password
                }
            ).pipe(
            map((r: any) => {
                if (r.token != null && typeof r.token != "undefined") {
                    localStorage.setItem('access_token', r.token);
                    localStorage.setItem('expires', r.expires);
                    localStorage.setItem('patientOnlineId', r.patientOnlineId);
                    loginStatus.success = true;
                    this.loggedIn = true;
                    this._loggedInBS.next(true);
                } else {
                    loginStatus.success = false;
                    loginStatus.errorCode = r.error;
                    loginStatus.message = r.message;
                }

                return loginStatus;
            }));
    }

    refreshToken(token: string | null) {
        var loginStatus = new LoginStatus();
        return this.http
            .post<any>('api/Account/RefreshToken?token=' + token, null)
            .pipe(map((r: any) => {
                if (r.token != null && typeof r.token != "undefined") {
                    localStorage.setItem('access_token', r.token);
                    localStorage.setItem('expires', r.expires);
                    loginStatus.success = true;
                    this.loggedIn = true;
                    this._loggedInBS.next(true);
                } else {
                    loginStatus.success = false;
                    loginStatus.errorCode = r.error;
                    loginStatus.message = r.message;
                    if (!!localStorage.getItem('access_token')) {
                        this.logout('', false);
                    }
                }
            }));
    }

    register(model: PersonalDetail) {
        return this.http
            .post<RegisterResponse>(
                'api/Account/Register',
                model
            ).pipe(map((r: RegisterResponse) => {
                this.patientOnlineId = r.patientOnlineId;
                return r;
            }));
    }

    registerTempAccount(model: PersonalDetail) {
        return this.http
            .post<any>(
                'api/Account/RegisterTempAccount',
                model
            ).pipe(map(r => {
                let result: { success: boolean } = { success: false };
                result.success = r.token != null && typeof r.token != "undefined";
                if (result.success) {
                    sessionStorage.setItem('access_token', r.token);
                    sessionStorage.setItem('expires', r.expires);
                    sessionStorage.setItem('isTempUser', 'true');
                }
                return result;
            }));
    }

    deleteTemporaryAccount() {
        if (!!sessionStorage.getItem('isTempUser')) {
            sessionStorage.removeItem('isTempUser');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('expires');
            this.loggedIn = false;
        }
    }

    remindPassword(email: string) {
        return this.http
            .post<RemindPasswordResponse>(
                'api/Account/RemindPassword',
                {
                    email
                }
            );
    }

    confirm(code: number) {
        return this.http
            .post<ConfirmResponse>(
                'api/Account/Confirm',
                {
                    confirmationCode: code
                }
            );
    }

    loginWithConfirmationCode(code: number) {
        var loginStatus = new LoginStatus();
        return this.http
            .post<any>(
                'api/Account/LoginWithConfirmationCode',
                {
                    confirmationCode: code
                }
            ).pipe(
            map((r: any) => {
                if (r.token != null && typeof r.token != "undefined") {
                    localStorage.setItem('access_token', r.token);
                    localStorage.setItem('expires', r.expires);
                    loginStatus.success = true;
                    this.loggedIn = true;
                    this._loggedInBS.next(true);
                } else {
                    loginStatus.success = false;
                    loginStatus.errorCode = r.error;
                    loginStatus.message = r.message;
                }

                return loginStatus;
            }));
    }

    firstLogon() {
        return this.http
            .get<boolean>('api/Account/FirstLogonCheck');
    }

    getPatientData() {
        let $s: Subscription = this.http
            .get<GetPatientResponse>('/api/Account/Get')
            .subscribe((result: GetPatientResponse) => {
                $s.unsubscribe();
                this.dataStore.patientData = result;
                this._patientData.next(Object.assign({}, this.dataStore.patientData));
            }, () => $s.unsubscribe());
    }

    changePassword(oldPassword: string, newPassword: string, newPasswordConfirmation: string) {
        return this.http
            .post<ChangePasswordResponse>(
                'api/Account/ChangePassword',
                {
                    oldPassword,
                    newPassword,
                    newPasswordConfirmation
                }
            );
    }

    changeAcceptedToS(acceptedToS: Array<number>) {
        this.dataStore.patientData.acceptedToS = acceptedToS;
        return this.http
            .post<ChangeAcceptedToSResponse>(
                'api/Account/ChangeAcceptedToS',
                {
                    acceptedToS
                }
            );
    }

    resendConfirmation() {
        if (this.patientOnlineId == -1) {
            let ret = new ConfirmResponse();
            ret.success = false;
            ret.message = 'Twoja sesja wygasła. Skorzytaj z linku zawartego w mailu z potwierdzniem rejestracji.';
            let rs = new BehaviorSubject<ConfirmResponse>(ret);
            return rs.asObservable();
        }

        return this.http
            .post<ConfirmResponse>(
                'api/Account/ResendConfirmation',
                {
                    patientOnlineId: this.patientOnlineId
                }
            );
    }

    logout(redirect: string = '', doRedirect: boolean = true) {
        this.clear();
        this.deleteTemporaryAccount();
        this.redirect = redirect;
        this.doRedirect = doRedirect;

        this.http.get<any>('api/Account/Logout').subscribe();
        localStorage.removeItem('access_token');
        this.loggedIn = false;
        this._loggedInBS.next(false);
    }



    hasRedirect() {
        return this.redirect != null && this.redirect !== '';
    }

    getRedirect() {
        let rediredt = this.redirect;
        this.redirect = "";
        return rediredt;
    }

    public redirectHomePage(isError: boolean) {
        let config = this.clientService.config;

        if (!isError && (config.redirectWithAccount || config.redirectWithoutAccount)) {
            if (this.isLoggedIn) {
                if (config.redirectWithAccount)
                    location.assign(config.redirectAddress);
                else
                    this.router.navigate(['/']);
            }
            else if (config.redirectWithoutAccount)
                location.assign(config.redirectAddress); 
        }
        else {
            this.router.navigate(['/']);
        }
    }

    get isLoggedIn() {
        return this.loggedIn;
    }

    loggedInStatus = this._loggedInBS.asObservable();

    get patientData() {
        return this._patientData.asObservable();
    }

    get cookieConfirmed(): boolean {
        return this._cookieConfirmed;
    }

    set cookieConfirmed(isConfirmed: boolean) {
        localStorage.setItem('cookieConfirmed', isConfirmed.toString());
        this._cookieConfirmed = isConfirmed;
    }

    get currentPatientOnlineId(): number{
        return this.isLoggedIn ? Number(localStorage.getItem('patientOnlineId')) : -1;
    }
}

class LoginStatus {
    success: boolean;
    errorCode: number;
    message: string;
}
