<div [@fadeInOut] class="col-xs-12">
    <div class="col-xs-12">
        <h5 class="text-main">Wybierz interesujące Cię kryteria, by wyświetlić terminy</h5>
        <br/>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="visitForm" novalidate>
        <div class="col-xs-12 col-md-8">

            <div class="col-xs-12 col-no-padding">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group" [class.has-error]="!okSpeciality">
                        <label for="specialityId" class="text-alt">Specjalizacja</label>
                        <select class="form-control" formControlName="specialityId">
                            <option value="0" disabled="disabled">Wybierz</option>
                            <option *ngFor="let s of specialities" [value]="s.id">{{s.name}}</option>
                        </select>
                        <p [hidden]="okSpeciality"
                           class="text-danger">
                            Wybór specjalizacji jest wymagany
                        </p>
                    </div>
                </div>

                <div class="col-xs-12 col-md-6">

                    <div class="form-group">
                        <label for="languageId" class="text-alt">Język</label>
                        <select class="form-control" formControlName="languageId">
                            <option *ngFor="let o of languages" [value]="o.id">{{o.name}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="col-xs-12 col-no-padding">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                        <label for="departmentId" class="text-alt">Oddział</label>
                        <select class="form-control" formControlName="departmentId">
                            <option value="-1">Dowolny</option>
                            <option *ngFor="let d of departments" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                        <label for="doctorId" class="text-alt">Lekarz</label>
                        <select class="form-control" formControlName="doctorId">
                            <option value="-1">Wszyscy</option>
                            <option *ngFor="let d of doctors" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div>
                </div>

            </div>
            
            <div class="col-xs-12 col-md-12">
                <label for="serviceId" class="text-alt">Rodzaj usługi</label>
                <div *ngIf="showServices">
                    <div class="radio" *ngFor="let s of services">
                        <label>
                            <input type="radio" name="serviceId" formControlName="serviceId" [value]='s.id'>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                            {{s.name}}
                        </label>
                    </div>
                </div>
                <p [hidden]="okService"
                   class="text-danger">
                    Proszę wybrać rodzaj usługi
                </p>
                <p [hidden]="!noServices"
                   class="text-danger">
                    Wybrana specjalizacja nie posiada żadnych usług z możliwością umówienia online
                </p>
            </div>
        </div>
        <div class="col-xs-12 col-md-4">
            <div class="col-xs-12">
                <div class="form-group">
                    <div *ngIf="visitType.length > 1">
                        <label class="text-alt">Rodzaj wizyty</label>
                        <div class="checkbox" *ngFor="let vt of visitType">
                            <label>
                                <input #cb type="checkbox" (change)="toggleVisitType(vt.id, cb.checked)" [checked]="isVisitTypeSelected(vt.id)">
                                <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                {{vt.name}}
                            </label>
                        </div>
                        <p [hidden]="okVisitType"
                        class="text-danger">
                         Proszę wybrać przynajmniej jeden typ wizyty
                     </p>
                    </div>
                    <label class="text-alt">Dni tygodnia</label>
                    <div class="mb-2" formGroupName="daysOfWeekGroup">
                        
                        <div class="day-of-the-week"><p-toggleButton formControlName="dayMon" onLabel="Pon" offLabel="Pon" title="Poniedziałek"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="dayTue" onLabel="Wto" offLabel="Wto" title="Wtorek"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="dayWed" onLabel="Śro" offLabel="Śro" title="Środa"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="dayThu" onLabel="Czw" offLabel="Czw" title="Czwartek"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="dayFri" onLabel="Pią" offLabel="Pią" title="Piątek"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="daySat" onLabel="Sob" offLabel="Sob" title="Sobota"></p-toggleButton></div>
                        <div class="day-of-the-week"><p-toggleButton formControlName="daySun" onLabel="Nie" offLabel="Nie" title="Niedziela"></p-toggleButton></div>
                        <p [hidden]="okDaysOfWeek"
                           class="text-danger">
                            Proszę wybrać przynajmniej jeden dzień tygodnia
                        </p>
                    </div>
                    <label for="hour" class="text-alt">Godziny</label>
                    <div class="radio" *ngFor="let h of hours">
                        <label>
                            <input type="radio" name="hour" formControlName="hour" [value]='h.id'>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                            {{h.name}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12" *ngIf="showWarning">
            <div class="form-group">
                <label class="text-alt">Uwagi</label>
                <p>{{warning}}</p>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" formControlName="warning">
                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                        Oświadczam, że zapoznałem/am się z powyższymi uwagami.
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-md-4 col-md-offset-8">
            <button type="submit" class="btn btn-primary input-full-width pull-right">Wyszukaj <i class="fa fa-search"></i></button>
        </div>
    </form>
    <div class="col-xs-12">
        <br/>
        <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
        </div>
    </div>
</div>
