﻿import { Component, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';

import { VisitService } from '../shared/services/visit.service';
import { UserService } from '@app/shared/services/user.service';

import { GetPaymentStatusResponse } from '../../shared/models/get-payment-status-response.model';

import { ErrorProcessor } from './../../shared/error-processor';


@Component({
    templateUrl: './after-payment.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1', }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class AfterPaymentComponent implements OnDestroy {
    isError = false;
    error = '';
    message = '';
    waitingForPayment: boolean = true;
    finishedPayment = false;
    reCheck: any;
    interval = 5000;

    constructor(private visitService: VisitService, private userService: UserService) {
        if (this.visitService.hasVisitForPayment())
            this.checkStatus();
        else {
            this.waitingForPayment = false;
            this.finishedPayment = true;
            this.isError = true;
            this.error = "Wystąpił nieoczekiwany błąd";
        }
    }

    checkStatus() {
        this.waitingForPayment = true;
        this.visitService.getPaymentStatus().subscribe((result: GetPaymentStatusResponse) => {
            if (result.checkAgain) {
                if (this.interval < 15000)
                    this.interval += 1000;
                this.reCheck = setTimeout(() => { this.checkStatus(); }, this.interval);
            } else {
                this.waitingForPayment = false;
                if(result.success)
                {
                    this.finishedPayment = true;
                    if (result.paymentFinished) {
                        this.message = result.message;
                    } else {
                        this.error = result.message;
                        this.isError = true;
                    }

                } else {
                    this.isError = true;
                    this.error = 'Wystąpił nieoczekiwany błąd';
                }
            }
            
            },
            (error: any) => {
                this.finishedPayment = true;
                this.waitingForPayment = false;
                this.isError = true;
                this.error = ErrorProcessor.process(error);
            });
    }

    go() {
        this.userService.redirectHomePage(this.isError);
    }

    ngOnDestroy(): void {
        window.clearTimeout(this.reCheck);
    }
}