﻿import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ClientService } from './services/client.service';
import { map } from 'rxjs/operators';
import { ConfigModel } from './models/config.model';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationVisibleGuard implements CanActivate {

    constructor(private clientService: ClientService, private router: Router) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.clientService.config.hideRegistration == null) {
            return this.clientService.configChange.pipe(map((config: ConfigModel) => this.check(config)));
        }
        else {
            return this.check(this.clientService.config);
        }
    }

    check(config: ConfigModel): boolean {
        if (config.hideRegistration) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}