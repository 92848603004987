<div [@fadeInOut] class="col-xs-12 col-no-padding">
  <div [class.bhide]="!showCancelVisit && !showConfirmPresence" class="blocker">
    &nbsp;
  </div>
  <visit-cancel
    *ngIf="showCancelVisit"
    [@fadeInOut]
    class="col-xs-12 absolute"
    [visit]="selectedVisit"
    (canceled)="hideCancel()"
  ></visit-cancel>
  <visit-confirm-presence
    *ngIf="showConfirmPresence"
    [@fadeInOut]
    class="col-xs-12 absolute"
    [visit]="selectedVisit"
    (canceled)="hideConfirmPresence()"
  ></visit-confirm-presence>
  <div class="list-container" [class.list-container-fixed]="showCancelVisit">
    <h4 class="text-main text-center">Moje zaplanowane wizyty</h4>
    <h5 class="text-center" *ngIf="noVisits">Brak umówionych wizyt</h5>
    <div class="row" *ngFor="let v of visits">
      <div class="visit-box clearfix">
        <div class="col-md-3 col-xs-5">
          <p title="Data wizyty">
            <i class="fas fa-calendar-alt fa-lg"></i> {{ v.date }}
          </p>
          <p title="Godzina wizyty">
            <i class="fas fa-clock fa-lg"></i> {{ v.hour }}
          </p>
        </div>
        <div class="col-md-4 col-xs-7">
          <p title="Lekarz">
            <i class="fas fa-user-md fa-lg"></i> {{ v.doctor }}
          </p>
          <p title="Usługa">
            <i class="fas fa-medkit fa-lg"></i> {{ v.service }}
          </p>
        </div>
        <div class="col-md-3 col-xs-7">
          <p title="Oddział">
            <i class="fas fa-map-marker fa-lg"></i> {{ v.department }}
          </p>
          <p *ngIf="telemedicineActive" title="Rodzaj wizyty">
            <i
              class="fas fa-lg"
              [class.fa-hospital]="v.visitTypeId == 1"
              [class.fa-video]="v.visitTypeId == 2"
              [class.fa-phone]="v.visitTypeId == 3"
            ></i>
            {{ v.visitTypeName }}
          </p>
        </div>
        <div class="col-md-2 col-xs-5 center-btn">
          <button
            *ngIf="v.canCancel"
            type="button"
            class="btn btn-sm btn-danger input-full-width"
            (click)="cancelVisit(v)"
          >
            Odwołaj <i class="fa fa-trash"></i>
          </button>
          <button
            *ngIf="!v.canCancel"
            type="button"
            class="btn btn-sm btn-danger disabled input-full-width"
            pTooltip="{{ v.cantCancelMessage }}"
            tooltipPosition="left"
          >
            Odwołaj <i class="fa fa-trash"></i>
          </button>

          <button
            *ngIf="v.canConfirmPresence; else cantConfirm"
            type="button"
            class="btn btn-sm btn-success input-full-width"
            (click)="confirmPresence(v)"
            title="Potwierdź gotowość do odbycia e-wizyty"
          >
            Potwierdź gotowość <i class="fa fa-check"></i>
          </button>
          <ng-template #cantConfirm>
            <div *ngIf="v.visitTypeId != 1">
              <p *ngIf="!v.teleToken; else goToRoom">
                <small
                  >Obecność możesz potwierdzić {{ v.confirmTime }} minut przed
                  wizytą.</small
                >
              </p>
              <ng-template #goToRoom>
                <button
                  *ngIf="v.visitTypeId == 2; else waitCall"
                  id="go-to-room"
                  type="button"
                  class="btn btn-sm btn-success input-full-width"
                  (click)="goToVisitRoom(v)"
                >
                  Przejdź do wizyty <i class="fas fa-notes-medical"></i>
                </button>
                <ng-template #waitCall>
                  <p>
                    <small
                      >Lekarz wkrótce skontaktuje się z tobą
                      telefonicznie.</small
                    >
                  </p>
                </ng-template>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
