import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-speech-bubble",
  templateUrl: "./speech-bubble.component.html",
  styleUrls: ["./speech-bubble.component.scss"],
})
export class SpeechBubbleComponent implements OnInit {
  @Input()
  public message: string;

  @Input()
  public imgSrc: string | null;

  constructor() {}

  ngOnInit() {}
}
