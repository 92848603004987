<div
  *ngIf="!!slot"
  [@fadeInOut]
  class="col-xs-12 col-md-6 col-md-offset-3 float"
>
  <div class="text-center" *ngIf="slot.typeSelected == 0; else typeSelected">
    <div class="col-xs-12">
      <h4 class="text-main">Wybierz rodzaj wizyty</h4>
    </div>
    <div *ngFor="let vt of slot.visitTypes" class="col-xs-4 visit-type">
      <button
        type="button"
        class="btn btn-primary input-full-width"
        (click)="selectType(vt.id, vt.name)"
      >
        <i
          class="fas fa-4x vt-icon"
          [class.fa-hospital]="vt.id == 1"
          [class.fa-video]="vt.id == 2"
          [class.fa-phone]="vt.id == 3"
        ></i>
        <br />
        {{ vt.name }}
      </button>
    </div>

    <div class="col-xs-12">
      <button
        type="button"
        class="btn btn-info input-full-width"
        (click)="cancel()"
      >
        COFNIJ <i class="fa fa-undo"></i>
      </button>
    </div>
  </div>
  <ng-template #typeSelected>
    <div class="col-xs-12 text-center">
      <h4 class="text-main">
        Wybrana wizyta <i class="fa fa-calendar-check-o"></i>
      </h4>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right">Lekarz</h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <small>{{ slot.doctor }}</small>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right">Data</h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <small>{{ slot.dateShow }}</small>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right">Godzina</h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <small>{{ slot.hour }}</small>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right">Oddział</h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <small>{{ slot.department }}</small>
        </h4>
      </div>
    </div>
    <div *ngIf="telemedicineActive" class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right"><strong>Rodzaj</strong></h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <strong>{{ slot.typeNameSelected }}</strong
          >&nbsp;<i
            class="fas fa-pencil clickable"
            title="Zmień rodzaj wizyty"
            (click)="changeType()"
          ></i>
        </h4>
      </div>
    </div>
    <div *ngIf="showPrices" class="row">
      <div class="col-xs-6">
        <h4 style="text-align: right">Cena</h4>
      </div>
      <div class="col-xs-6">
        <h4>
          <small>
            {{ slot.price | currency: "PLN":"symbol-narrow":"1.2-2" }}
          </small>
        </h4>
      </div>
    </div>
    <div class="col-xs-12 text-center">
      <h4 class="text-main">Czy na pewno chcesz zarezerwować wizytę?</h4>
    </div>
    <div class="col-xs-6">
      <button
        type="button"
        class="btn btn-info input-full-width"
        (click)="cancel()"
      >
        COFNIJ <i class="fa fa-undo"></i>
      </button>
    </div>
    <div
      *ngIf="(!blockWithoutPayment && !forcePayment) || slot.price === 0"
      class="col-xs-6"
    >
      <button
        type="button"
        class="btn btn-success input-full-width"
        (click)="book()"
      >
        REZERWUJ <i class="fa fa-check"></i>
      </button>
    </div>

    <div
      *ngIf="showPayment && slot.price !== 0"
      [class.col-xs-6]="blockWithoutPayment || forcePayment"
      [class.col-xs-12]="!blockWithoutPayment && !forcePayment"
      [style.padding-top]="blockWithoutPayment || forcePayment ? null : '10px'"
    >
      <button
        type="button"
        class="btn btn-warning input-full-width"
        (click)="book(true)"
      >
        REZERWUJ I ZAPŁAĆ <i class="fa fa-credit-card"></i>
      </button>
    </div>

    <div *ngIf="showPayment && slot.price !== 0" class="col-xs-12">
      <h5 class="text-center">
        <small
          >Płatności obsługuje
          <img style="height: 14px" src="/img/payU logo.png" alt="PayU"
        /></small>
      </h5>
    </div>
  </ng-template>
</div>
