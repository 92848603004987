<div [@fadeInOut] class="col-xs-12">
    <div class="row">
        <div class="col-xs-12">
            <h4 class="text-main">Moje dane</h4>
            <br />
        </div>
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Imię</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{patient.name}}</small>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Nazwisko</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{patient.surname}}</small>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="!registrationWithBirthDate">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Pesel</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{patient.pesel}}</small>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="registrationWithBirthDate">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Data urodzenia</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{ patient.birthDate | date: 'yyyy-MM-dd' }}</small>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Telefon</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{patient.phoneNumber}}</small>
                </h4>
            </div>

        </div>
        <div class="row">
            <div class="col-xs-4">
                <h4 style="text-align: right;">Email</h4>
            </div>
            <div class="col-xs-8">
                <h4>
                    <small>{{patient.email}}</small>
                </h4>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="col-xs-12">
            <h4 class="text-main">Zmiana hasła</h4>
            <br />
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordForm" novalidate>

            <div class="form-group" [class.has-error]="!okOldPassword">
                <input type="password" class="form-control" formControlName="oldPassword" placeholder="stare hasło">
                <p [hidden]="okOldPassword"
                   class="text-danger">
                    Stare hasło jest wymagane
                </p>
            </div>
            <div formGroupName="passwordsGroup">
                <div class="form-group" [class.has-error]="!okPassword">
                    <input type="password" class="form-control" formControlName="password" placeholder="nowe hasło">
                    <p [hidden]="okPassword"
                       class="text-danger">
                        Hasło jest wymagane
                    </p>
                </div>
                <div class="form-group" [class.has-error]="!okPasswordsMatch">
                    <input type="password" class="form-control" formControlName="confirmPassword" placeholder="powtórz nowe hasło">
                    <p [hidden]="okPasswordsMatch"
                       class="text-danger">
                        Podane hasła nie są identyczne
                    </p>
                </div>
            </div>

            <div class="col-md-12">
                <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
                </div>
                <div *ngIf="hasMessage" class="alert alert-success" [innerHTML]="message">
                </div>
            </div>
            <button type="submit" class="btn btn-primary input-full-width">Zmień hasło <i class="fa fa-key"></i></button>
        </form>
    </div>
    <div *ngIf="allowMultiConsents" class="col-md-8">
        <div class="row">
            <h4 class="text-main tos-header">
                Moje zgody
                <button *ngIf="!updatingToS" (click)="setUpdating(true);" class="btn btn-primary btn-edit"><i class="fa fa-pencil"></i></button>
            </h4>
        </div>
        <form (ngSubmit)="onSubmitAcceptedToS()" class="row" [formGroup]="termsOfServiceForm">
            <div formArrayName="tos" class="row">
                <div *ngFor="let t of tos.controls; let i = index" class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [formControlName]="i">
                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                            {{terms[i].content}}
                            <i *ngIf="terms[i].description.length > 0" class="info fa fa-info-circle" pTooltip="{{terms[i].description}}" tooltipPosition="right"></i>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="isErrorToS" class="alert alert-danger" [innerHTML]="error">
                </div>
                <div *ngIf="hasMessageToS" class="alert alert-success" [innerHTML]="message">
                </div>
            </div>
            <div *ngIf="updatingToS" class="row">
                <button type="submit" class="btn btn-primary">Aktualizuj zgody&emsp;<i class="fa fa-save"></i></button>
                <button type="button" class="btn btn-danger" (click)="setUpdating(false);">Anuluj&emsp;<i class="fa fa-times"></i></button>
            </div>
        </form>
    </div>
</div>