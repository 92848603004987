import { Component, ViewChild, ElementRef} from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Title }     from '@angular/platform-browser';
import { FiltersComponent } from './filters/filters.component';

@Component({
    templateUrl: './visit.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0'}),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class VisitComponent {

    float = true;
    @ViewChild('filters')
    filters: FiltersComponent;

    constructor(private titleService: Title) {
        titleService.setTitle("Medsoft - Wyszukaj termin");
    }

    goToSlots(element: ElementRef) { 
        if (typeof element !== 'undefined') {
            setTimeout(() => {
                element.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 500); 
        }
    }

    monthChanged(event: {month: number, year: number}) {
        let now = new Date();

        event.month--; //months start from 0
        if (event.year >= now.getFullYear() && (event.month >= now.getMonth() || event.year !== now.getFullYear())) {
            let date = new Date(event.year, event.month, 1);
            this.filters.getDatesWithFreeSlots(date);
        }
    }
}
