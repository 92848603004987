<div class="col-xs-12 col-no-padding" *ngIf="!error">
  <p class="text-center" *ngIf="!urlId">Nieprawidłowy link</p>
  <div *ngIf="urlId && !visit" class="text-center">
    <h4><i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Trwa pobieranie informacji o wizycie</h4>
  </div>
  <ng-container *ngIf="urlId && visit">
    <div class="row" *ngIf="visit.canConfirmPresence">
      <visit-confirm-presence [visit]="visit" [hideCancel]="true" [urlId]="urlId"></visit-confirm-presence>
    </div>
    <div *ngIf="!visit.canConfirmPresence && !visit.teleToken" class="alert alert-warning text-center">
      Obecność możesz potwierdzić {{visit.confirmTime}} minut przed wizytą.
    </div>

    <div class="col-xs-12 col-md-6 col-md-offset-3 alert alert-success text-center" *ngIf="!visit.canConfirmPresence && visit.teleToken && visit.visitTypeId == 3">
      <h4>Dziękujemy za potwierdzenie wizyty.</h4><h5>Lekarz skontaktuje się z Tobą w ciągu kilku minut.</h5>
    </div>
  </ng-container>
</div>
<div *ngIf="error" class="alert alert-danger text-center" [innerHTML]="error">
</div>