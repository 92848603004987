<div [@fadeInOut] class="col-xs-12 col-md-4 col-md-offset-4">
    <div class="text-center">
        <img src="/img/pac-logo.png"/>
    </div>
    <br/>
    <ng-container *ngIf="!success">
        <ng-container *ngIf="!confirming">
            <div class="col-xs-12">
                <p *ngIf="!isSms">Na podany adres email został wysłany kod potwierdzający, wpisz go poniżej</p>
                <p *ngIf="isSms">Na podany numer telefonu został wysłany kod potwierdzający, wpisz go poniżej</p>

                <form (ngSubmit)="onSubmit()" [formGroup]="confirmForm" novalidate>
                    <div class="form-group" [class.has-error]="!okCode">
                        <input type="text" class="form-control" formControlName="code" placeholder="kod">
                        <p [hidden]="okCode"
                           class="text-danger">
                            Proszę podać kod
                        </p>
                    </div>
                    <button type="submit" class="btn btn-primary input-full-width" [disabled]="!confirmForm.valid">Potwierdź <i class="fa fa-check"></i></button>
                </form>
                <br/>
                <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
                </div>

                <p *ngIf="!isSms">
                    <small>Email z kodem nie doszedł? <a href="#" (click)="resend($event)">Wyślij ponownie</a></small>
                </p>

                <div *ngIf="isMsg" class="alert alert-success" [innerHTML]="message">
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="confirming">
            <div *ngIf="confirmed">
                <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
                </div>
                <div *ngIf="isMsg" class="alert alert-success" [innerHTML]="message">
                </div>
            </div>
            <div *ngIf="!confirmed">
                <h4 class="text-main"><i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Trwa potwierdzanie konta</h4>
            </div>
        </ng-container>
    </ng-container>
    <div *ngIf="success">
        <h3>Twoje konto zostało potwierdzone</h3>
        <p>
            <a [routerLink]="['/']">Kliknij tutaj, aby wrócić do strony głównej</a>
        </p>
    </div>
</div>