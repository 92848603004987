import { Injectable, } from '@angular/core';


import { ReplaySubject } from 'rxjs';

import { Notification, NotificationType } from '../models/notification.model';

import { ErrorProcessor } from '../error-processor';

@Injectable()
export class NotificationService {
    private _notifications = new ReplaySubject<Notification>(1);
    private _logouts = new ReplaySubject<string>(1);

    handleHttpError(error: any, redirect: string = "", suppressNotification: boolean = false) {
        if (error.status === 401) {
            this._logouts.next(redirect);
        }

        if (!suppressNotification) {
            this._notifications.next(new Notification(NotificationType.Error,
                ErrorProcessor.process(error)));
        }
    }

    makeNotification(message: string, type: NotificationType) {
        this._notifications.next(new Notification(type, message));
    }

    get notifications() {
        return this._notifications.asObservable();
    }

    get logouts() {
        return this._logouts.asObservable();
    }
}
