import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetResponse } from '../shared/models/get-response.model';
import { Subscription } from 'rxjs';
import { VisitService } from '../shared/services/visit.service';

@Component({
  selector: 'app-e-visit',
  templateUrl: './e-visit.component.html',
  styleUrls: ['./e-visit.component.scss']
})
export class EVisitComponent implements OnInit, OnDestroy {

  urlId: string;
  visit: GetResponse;

  error: string;

  private getVisitInfo$: Subscription

  constructor(private route: ActivatedRoute,
    private visitService: VisitService,
    private router: Router) { }

  ngOnInit() {
    this.urlId = this.route.snapshot.params.urlId;

    if (this.urlId) {
      this.getVisitInfo$ = this.visitService.getByUrlId(this.urlId)
        .subscribe(v => {
          if (v) {
            this.visit = v;
            if(!this.visit.canConfirmPresence && this.visit.teleToken && this.visit.visitTypeId == 2){
              this.router.navigate(['/evisit-room'], {state: {data: {visit: this.visit, urlId: this.urlId}}});
            }
          } else {
            this.error = 'Wizyta nie istnieje';
          }
        },
          () => { this.error = 'Wystąpił błąd podczas pobierania wizyty'; });
    } else {
      this.urlId = undefined;
    }
  }

  ngOnDestroy() {
    if (this.getVisitInfo$)
      this.getVisitInfo$.unsubscribe();
  }

}
