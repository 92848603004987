﻿import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

import { UserService } from "./../shared/services/user.service";
import { ClientService } from "./../shared/services/client.service";
import { ConfigModel } from "./../shared/models/config.model";
import { ErrorProcessor } from "./../shared/error-processor";
import { PersonalDetail } from "../shared/models/personal-detail.model";
import { VisitService } from "../visit/shared/services/visit.service";
import { HttpParams } from "@angular/common/http";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        group([animate(".7s ease-out", style({ opacity: "1" }))]),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate(".8s ease-in", style({ opacity: "0" })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  isError = false;
  error = "";
  submitingForm = false;
  hasRules: boolean;
  rulesUrl: string = "/api/Client/Rules";
  hasPrivacyPolicy: boolean;
  PrivacyPolicyUrl: string = "/api/Client/PrivacyPolicy";

  loginForm: FormGroup;

  redirect: string = "";
  redirectQuery: string = "";
  withoutRegistration: boolean;
  allowReservationWithoutAccount: boolean;
  loginPanelClass: string;
  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private visitService: VisitService
  ) {}

  ngOnInit() {
    let redirect = this.route.snapshot.params.redirect;

    if (typeof redirect !== "undefined" && redirect != null) {
      this.redirect = redirect;

      const queryString = this.route.snapshot.queryParamMap.keys
        .map((k) => `${k}=${this.route.snapshot.queryParams[k]}`)
        .join("&");

      if (queryString && queryString.length > 0) {
        this.redirectQuery = `?${queryString}`;
      }
    } else if (
      this.visitService.visitToBook &&
      this.visitService.visitToBook.scheduleId &&
      this.redirect.length < 1
    ) {
      this.redirectQuery =
        this.visitService.visitToBook.token &&
        this.visitService.visitToBook.token.length > 0
          ? `?token=${this.visitService.visitToBook.token}`
          : "";

      this.redirect = "booking-status";
    } else {
      this.redirect = "";
    }

    this.clientService.configChange.subscribe((model: ConfigModel) =>
      this.loadConfig(model, true)
    );
    this.loadConfig(this.clientService.config);
  }

  loadConfig(model: ConfigModel, reloaded: boolean = true) {
    this.hasRules = model.hasRules;
    this.hasPrivacyPolicy = model.hasPrivacyPolicy;
    this.allowReservationWithoutAccount =
      model.allowReservationWithoutAccount && this.redirect.length > 0;
    this.withoutRegistration = model.hideRegistration || false;
    this.setTitle();
    this.createForm();

    if (this.allowReservationWithoutAccount)
      this.loginPanelClass = "col-xs-6 col-md-offset-1 col-md-4";
    else this.loginPanelClass = "col-xs-12 col-md-4 col-md-offset-4";
  }

  setTitle() {
    if (this.withoutRegistration)
      this.titleService.setTitle("Medsoft - Dane pacjenta");
    else this.titleService.setTitle("Medsoft - Logowanie");
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      rules: ["", this.hasRules ? Validators.requiredTrue : []],
    });
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    this.userService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (result: any) => {
          if (result.success) {
            if (this.redirect !== null) {
              let params: Params = {};
              this.redirectQuery
                .replace("?", "")
                .split("&")
                .forEach((q) => {
                  let splited = q.split("=");
                  if (splited.length >= 2) {
                    params[splited[0]] = splited[1];
                  }
                });
              this.router.navigate(["/" + this.redirect], {
                queryParams: params,
              });
            }
          } else {
            this.isError = true;
            this.error = "Wystąpił nieoczekiwany błąd";
          }
        },
        (error: any) => {
          this.isError = true;
          this.error = ErrorProcessor.process(error);
        }
      );
  }

  withoutRegistrationClick() {
    if (this.redirect !== null && this.redirect.length > 0) {
      this.withoutRegistration = true;
      this.setTitle();
    } else this.router.navigate(["/"]);
  }

  personalDetailSubmit(model: PersonalDetail) {
    this.submitingForm = true;
    this.userService.registerTempAccount(model).subscribe(
      (r) => {
        this.submitingForm = false;
        if (r.success) {
          if (this.redirect !== null)
            this.router.navigate(["/" + this.redirect]);
        } else {
          this.isError = true;
          this.error = "Wystąpił nieoczekiwany błąd";
        }
      },
      (error: any) => {
        this.submitingForm = false;
        this.isError = true;
        this.error = ErrorProcessor.process(error);
      }
    );
  }
  get okEmail(): boolean {
    return (
      !this.loginForm.hasError("required", ["email"]) ||
      this.loginForm.get("email")!.pristine
    );
  }

  get okPassword(): boolean {
    return (
      !this.loginForm.hasError("required", ["password"]) ||
      this.loginForm.get("password")!.pristine
    );
  }

  get okRules(): boolean {
    return (
      !this.loginForm.hasError("required", ["rules"]) ||
      this.loginForm.get("rules")!.pristine
    );
  }

  get mustLogin(): boolean {
    return (
      !!this.redirect &&
      !!this.visitService.visitToBook &&
      this.visitService.visitToBook.typeSelected != 1
    );
  }
}
