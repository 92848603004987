<ng-container *ngIf="loadingVisitData">
  <div class="col-xs-12 text-center">
    <h4>
      <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> {{ loadingMessage }}
    </h4>
  </div>
</ng-container>
<ng-container *ngIf="!loadingVisitData">
  <div [@fadeInOut] class="col-xs-12 col-md-6 col-md-offset-3">
    <div *ngIf="!finished && !isError">
      <div class="col-xs-12 text-center">
        <h4 class="text-main">
          Wizyta do odwołania <i class="fa fa-calendar-check-o"></i>
        </h4>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <h4 style="text-align: right">Data</h4>
        </div>
        <div class="col-xs-6">
          <h4>
            <small>{{ visit.date }}</small>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <h4 style="text-align: right">Godzina</h4>
        </div>
        <div class="col-xs-6">
          <h4>
            <small>{{ visit.hour }}</small>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <h4 style="text-align: right">Lekarz</h4>
        </div>
        <div class="col-xs-6">
          <h4>
            <small>{{ visit.doctor }}</small>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <h4 style="text-align: right">Usługa</h4>
        </div>
        <div class="col-xs-6">
          <h4>
            <small>{{ visit.service }}</small>
          </h4>
        </div>
      </div>
      <div *ngIf="telemedicineActive" class="row">
        <div class="col-xs-6">
          <h4 style="text-align: right">Rodzaj</h4>
        </div>
        <div class="col-xs-6">
          <h4>
            <small>{{ visit.visitTypeName }}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-12 text-center">
        <h4 class="text-main">Czy na pewno chcesz odwołać wizytę?</h4>
        <h5 *ngIf="visit.hasPayment" class="text-main">
          Płatność zostanie zwrócona na konto użyte do opłacenia wizyty
        </h5>
      </div>
      <div class="col-xs-6">
        <button
          type="button"
          class="btn btn-danger input-full-width"
          (click)="cancel()"
        >
          NIE <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="col-xs-6">
        <button
          type="button"
          class="btn btn-success input-full-width"
          (click)="cancelVisit()"
        >
          TAK <i class="fa fa-check"></i>
        </button>
      </div>
    </div>
    <div *ngIf="finished">
      <div class="col-xs-12 col-no-padding">
        <div
          *ngIf="isError"
          class="alert alert-danger"
          [innerHTML]="error"
        ></div>
        <div
          *ngIf="isMsg"
          class="alert alert-success text-center"
          [innerHTML]="message"
        ></div>
      </div>
      <div class="col-xs-6 col-xs-offset-3">
        <button
          type="button"
          class="btn btn-primary input-full-width"
          (click)="cancel()"
        >
          Zamknij
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!finished && isError">
  <div class="col-md-12 col-lg-8 col-lg-offset-2">
    <div
      *ngIf="isError"
      class="alert alert-danger text-center"
      [innerHTML]="error"
    ></div>
  </div>
</ng-container>
