import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@app/shared/services/payment.service';
import { trigger, transition, style, group, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { GetPaymentStatusResponse } from '@app/shared/models/get-payment-status-response.model';
import { ErrorProcessor } from '@app/shared/error-processor';
import { UserService } from '@app/shared/services/user.service';
import { ClientService } from '@app/shared/services/client.service';

@Component({
  templateUrl: './payment-status.component.html',
  animations: [
    trigger('fadeInOut',
      [
        transition(':enter',
          [
            style({ opacity: '0' }),
            group([
              animate('.7s ease-out', style({ opacity: '1' })),
            ])
          ]),
        transition(':leave',
          [
            style({ opacity: '1', }),
            animate('.8s ease-in', style({ opacity: '0' })),
          ]),
      ]),
  ]
})
export class PaymentStatusComponent implements OnInit {
  isError = false;
  error = '';
  message = '';
  waitingForPayment: boolean = true;
  finishedPayment = false;
  reCheck: any;
  interval = 5000;
  fullAccess: boolean;

  constructor(private paymentService: PaymentService, 
              private userService: UserService,
              private clientService: ClientService,
              private route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.paramMap.subscribe(p => this.getPaymentStatus(p.get('token')));
    this.fullAccess = this.clientService.config.fullAccess;
  }

  getPaymentStatus(token: string) {
    this.waitingForPayment = true;
    this.paymentService.getPaymentStatus(token).subscribe((result: GetPaymentStatusResponse) => {
      if (result.checkAgain) {
        if (this.interval < 15000)
          this.interval += 1000;
        this.reCheck = setTimeout(() => { this.getPaymentStatus(token); }, this.interval);
      } else {
        this.waitingForPayment = false;
        if (result.success) {
          this.finishedPayment = true;
          if (result.paymentFinished) {
            this.message = result.message;
          } else {
            this.error = result.message;
            this.isError = true;
          }

        } else {
          this.isError = true;
          this.error = 'Wystąpił nieoczekiwany błąd';
        }
      }

    },
    (error: any) => {
      this.finishedPayment = true;
      this.waitingForPayment = false;
      this.isError = true;
      this.error = ErrorProcessor.process(error);
    });
  }

  go() {
    this.userService.redirectHomePage(this.isError);
  }

  ngOnDestroy(): void {
      window.clearTimeout(this.reCheck);
  }
}
