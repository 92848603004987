<div *ngIf="changeForFew && !confirmationVisible && slot != null" [@fadeInOut] class="col-xs-12 col-md-10 col-md-offset-1 float">
    <div class="list-container list-container-fixed">
        <table class="table table-striped table-responsive">
            <thead>
                <tr class="info">
                    <td style="width: 15%">
                        Data
                    </td>
                    <td style="width: 15%">
                        Godzina
                    </td>
                    <td style="width: 25%">
                        Pracownik
                    </td>
                    <td style="width: 15%">
                        Usługa
                    </td>
                    <td style="width: 15%" class="hidden-xs">
                        Oddział
                    </td>
                    <td style="width: 15%">
                        &nbsp;
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let v of visits">
                    <td style="width: 15%">
                        {{v.date}}
                    </td>
                    <td style="width: 15%">
                        {{v.hour}}
                    </td>
                    <td style="width: 25%">
                        {{v.doctor}}
                    </td>
                    <td style="width: 15%">
                        {{v.service}}
                    </td>
                    <td style="width: 15%" class="hidden-xs">
                        {{v.department}}
                    </td>
                    <td style="width: 15%; padding: 6px">
                        <button *ngIf="!v.hasPayment" type="button" class="btn btn-xs btn-success" (click)="chooseVisit(v);">Wybierz <i class="fa fa-calendar"></i></button>
                        <button *ngIf="v.hasPayment" type="button" class="btn btn-xs btn-success disabled" pTooltip="Nie można zmienić terminu wizyty z płatnością" tooltipPosition="left">Wybierz <i class="fa fa-calendar"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-4 col-md-offset-4">
        <button type="button" class="btn btn-danger input-full-width" (click)="cancel(true)">ANULUJ <i class="fa fa-times"></i></button>
    </div>
</div>
<div *ngIf="confirmationVisible && slot != null" class="col-xs-12 col-md-6 col-md-offset-3 float">
    <div class="col-xs-12 text-center">
        <h4 class="text-main">Nowy termin wizyty <i class="fa fa-calendar-check-o"></i></h4>
    </div>
    <div class="col-xs-6">
        <h4 style="text-align: right;">Lekarz</h4>
    </div>
    <div class="col-xs-6">
        <h4><small>{{slot.doctor}}</small></h4>
    </div>
    <div class="col-xs-6">
        <h4 style="text-align: right;">Data</h4>
    </div>
    <div class="col-xs-6">
        <h4><small>{{slot.dateShow}}</small></h4>
    </div>
    <div class="col-xs-6">
        <h4 style="text-align: right;">Godzina</h4>
    </div>
    <div class="col-xs-6">
        <h4><small>{{slot.hour}}</small></h4>
    </div>
    <div class="col-xs-6">
        <h4 style="text-align: right;">Oddział</h4>
    </div>
    <div class="col-xs-6">
        <h4><small>{{slot.department}}</small></h4>
    </div>
    <div *ngIf="showPrices" class="col-xs-6">
        <h4 style="text-align: right;">Cena</h4>
    </div>
    <div *ngIf="showPrices" class="col-xs-6">
        <h4><small>{{slot.price | currency:'PLN':'symbol-narrow':'1.2-2'}}</small></h4>
    </div>
    <div class="col-xs-12 text-center">
        <h4 class="text-main">Czy na pewno chcesz zmienić termin?</h4>
    </div>
    <div class="col-xs-6">
        <button type="button" class="btn btn-info input-full-width" (click)="cancel(!changeForFew)">COFNIJ <i class="fa fa-undo"></i></button>
    </div>
    <div class="col-xs-6">
        <button type="button" class="btn btn-success input-full-width" (click)="book()">TAK <i class="fa fa-check"></i></button>
    </div>
</div>
<div *ngIf="slot == null" class="col-xs-12 col-md-6 col-md-offset-3 float">
    <div class="col-xs-12 text-center">
        <p>
            Osiągnięto limit wizyt w wybranej specjalności.<br />
            Umówienie wizyty online jest niemożliwe.
        </p>
    </div>
   
    <div class="col-md-6 col-md-offset-3">
        <button type="button" class="btn btn-danger input-full-width" (click)="cancel(!changeForFew)">ROZUMIEM <i class="fa fa-times"></i></button>
    </div>
</div>