import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, transition, style, group, animate } from '@angular/animations';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SaveInvoiceDataModel } from '@app/shared/models/save-invoice-data.model';
import { ErrorProcessor } from '@app/shared/error-processor';
import { SaveInvoiceDataResponse } from '@app/shared/models/save-invoice-data-response.model';
import { PaymentService } from '@app/shared/services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { GetPaymentDetailsResponse } from '@app/shared/models/get-payment-details-response';
import { MakePaymentModel } from '@app/shared/models/make-payment.model';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        transition(':enter',
          [
            style({ opacity: '0' }),
            group([
              animate('.7s ease-out', style({ opacity: '1' })),
            ])
          ]),
        transition(':leave',
          [
            style({ opacity: '1', }),
            animate('.8s ease-in', style({ opacity: '0' })),
          ]),
      ]),
  ]
})
export class PaymentComponent implements OnInit, OnDestroy {
  @Input()
  paymentUrl: string;
  @Input()
  hasInvoiceData: boolean;
  @Input()
  styleClass: string;

  isError = false;
  error = '';

  token: string;
  goingToPayment = false;

  invoiceIsError = false;
  invoiceError = '';
  invoiceForm: FormGroup;

  validate = false;
  redirect: any;

  isTokenPayment: boolean;
  fetchingDetails: boolean;

  paymentDetails: GetPaymentDetailsResponse;
  price: number;

  constructor(private fb: FormBuilder, private paymentService: PaymentService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.fetchingDetails = false;
    this.isTokenPayment = false;
    this.route.paramMap.subscribe(p => this.getDetails(p.get('token')));
    this.createForm();
  }

  getDetails(token: string) {
    this.token = token;
    this.isTokenPayment = this.token != null && this.token.length > 0;
    if (this.isTokenPayment) {
      this.styleClass = "col-xs-12 col-md-8 col-md-offset-2";
      this.fetchingDetails = true;
      this.paymentService.getDetails(this.token).subscribe(
        data => {
          this.isTokenPayment = true;
          this.paymentDetails = data
          this.fetchingDetails = false;
        },
        (error: any) => {
          this.fetchingDetails = false;
          this.isError = true;
          this.error = ErrorProcessor.process(error);
        }
      );
    }
  }

  goToPayment() {
    if (!this.invoiceForm.valid) {
      this.validate = true;
      this.invoiceIsError = true;
      this.invoiceError = 'Proszę poprawić wprowadzone dane';
      return;
    }

    let model = new SaveInvoiceDataModel();
    model.personalInvoice = this.invoiceForm.value.personalInvoice;
    model.companyName = this.invoiceForm.value.companyName;
    model.nip = this.invoiceForm.value.nip;
    model.street = this.invoiceForm.value.street;
    model.building = this.invoiceForm.value.building;
    model.apartment = this.invoiceForm.value.apartment;
    model.postalCode = this.invoiceForm.value.postalCode;
    model.city = this.invoiceForm.value.city;

    if (this.isTokenPayment) {
      let makePaymentModel = new MakePaymentModel(
        this.token,
        this.invoiceForm.value.firstName,
        this.invoiceForm.value.lastName,
        this.invoiceForm.value.email,
        model);

      this.paymentService.makePayment(makePaymentModel).subscribe(
        (data: any) => {
            if (data.status.success) {
              this.paymentUrl = data.redirectUri;
              this.setRedirect();
            }
        },
        (error: any) => {
          this.isError = true;
          this.error = ErrorProcessor.process(error);
        });
    }
    else {
      this.paymentService.saveInvoiceData(model).subscribe((result: SaveInvoiceDataResponse) => {
        if (result.success) {
          this.goingToPayment = true;
          this.setRedirect();
        } else {
          this.invoiceIsError = true;
          this.invoiceError = 'Wystąpił nieoczekiwany błąd';
        }
      },
        (error: any) => {
          this.invoiceIsError = true;
          this.invoiceError = ErrorProcessor.process(error);
        });
    }
  }

  private setRedirect() {
    window.clearTimeout(this.redirect);
    this.redirect = setTimeout(() => { location.replace(this.paymentUrl) }, 5000);
  }

  createForm() {
    this.invoiceForm = this.fb.group({
      firstName: ['', this.isTokenPayment ? Validators.required : []],
      lastName: ['', this.isTokenPayment ? Validators.required : []],
      email: ['', this.isTokenPayment ? [Validators.required, Validators.email] : [Validators.email]],
      personalInvoice: [true],
      street: ['', Validators.required],
      building: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      companyName: [''],
      nip: [''],
      apartment: ['']
    });

    if (this.hasInvoiceData) {
      this.invoiceForm.controls['street'].setValidators([]);
      this.invoiceForm.controls['building'].setValidators([]);
      this.invoiceForm.controls['postalCode'].setValidators([]);
      this.invoiceForm.controls['city'].setValidators([]);
      this.invoiceForm.controls['street'].updateValueAndValidity();
      this.invoiceForm.controls['building'].updateValueAndValidity();
      this.invoiceForm.controls['postalCode'].updateValueAndValidity();
      this.invoiceForm.controls['city'].updateValueAndValidity();
    }

    this.invoiceForm.controls["personalInvoice"].valueChanges.subscribe((val: boolean) => {
      if (val) {
        this.invoiceForm.controls['companyName'].setValidators([]);
        this.invoiceForm.controls['nip'].setValidators([]);
        if (this.hasInvoiceData) {
          this.invoiceForm.controls['street'].setValidators([]);
          this.invoiceForm.controls['building'].setValidators([]);
          this.invoiceForm.controls['postalCode'].setValidators([]);
          this.invoiceForm.controls['city'].setValidators([]);
        }
        else {
          this.invoiceForm.controls['street'].setValidators([Validators.required]);
          this.invoiceForm.controls['building'].setValidators([Validators.required]);
          this.invoiceForm.controls['postalCode'].setValidators([Validators.required]);
          this.invoiceForm.controls['city'].setValidators([Validators.required]);
        }
      } else {
        this.invoiceForm.controls['companyName'].setValidators([Validators.required]);
        this.invoiceForm.controls['nip'].setValidators([Validators.required]);
        this.invoiceForm.controls['street'].setValidators([Validators.required]);
        this.invoiceForm.controls['building'].setValidators([Validators.required]);
        this.invoiceForm.controls['postalCode'].setValidators([Validators.required]);
        this.invoiceForm.controls['city'].setValidators([Validators.required]);
      }
      this.invoiceForm.controls['companyName'].updateValueAndValidity();
      this.invoiceForm.controls['nip'].updateValueAndValidity();
      this.invoiceForm.controls['street'].updateValueAndValidity();
      this.invoiceForm.controls['building'].updateValueAndValidity();
      this.invoiceForm.controls['postalCode'].updateValueAndValidity();
      this.invoiceForm.controls['city'].updateValueAndValidity();
    });
  }
  get okFirstName(): boolean {
    return !this.invoiceForm.hasError('required', ['firstName']) || (this.invoiceForm.get('firstName')!.pristine && !this.validate);
  }

  get okLastName(): boolean {
    return !this.invoiceForm.hasError('required', ['lastName']) || (this.invoiceForm.get('lastName')!.pristine && !this.validate);
  }

  get okEmail(): boolean {
    return !this.invoiceForm.hasError('email', ['email']) || (this.invoiceForm.get('email')!.pristine && !this.validate);
  }

  get okEmailReq(): boolean {
      return !this.invoiceForm.hasError('required', ['email']) || (this.invoiceForm.get('email')!.pristine && !this.validate);
  }

  get okStreet(): boolean {
    return !this.invoiceForm.hasError('required', ['street']) || (this.invoiceForm.get('street')!.pristine && !this.validate);
  }

  get okBuilding(): boolean {
    return !this.invoiceForm.hasError('required', ['building']) || (this.invoiceForm.get('building')!.pristine && !this.validate);
  }

  get okPostalCode(): boolean {
    return !this.invoiceForm.hasError('required', ['postalCode']) || (this.invoiceForm.get('postalCode')!.pristine && !this.validate);
  }

  get okCity(): boolean {
    return !this.invoiceForm.hasError('required', ['city']) || (this.invoiceForm.get('city')!.pristine && !this.validate);
  }

  get okCompanyName(): boolean {
    return !this.invoiceForm.hasError('required', ['companyName']) || (this.invoiceForm.get('companyName')!.pristine && !this.validate);
  }

  get okNip(): boolean {
    return !this.invoiceForm.hasError('required', ['nip']) || (this.invoiceForm.get('nip')!.pristine && !this.validate);
  }

  get okPersonalInvoice(): boolean {
    return !this.invoiceForm.hasError('required', ['personalInvoice']) || (this.invoiceForm.get('personalInvoice')!.pristine && !this.validate);
  }

  get personalInvoice(): boolean {
    return this.invoiceForm.value.personalInvoice;
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.redirect);
  }
}
