import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask'
import { CaptchaModule } from 'primeng/captcha';
import {ToggleButtonModule} from 'primeng/togglebutton';

import { routing } from './app.routes';

import { AppComponent } from './app.component';

import { VisitComponent } from './visit/visit.component';
import { FiltersComponent } from './visit/filters/filters.component';
import { FreeTermsCalendarComponent } from './visit/free-terms-calendar/free-terms-calendar.component';
import { VisitSlotListComponent } from './visit/visit-slot-list/visit-slot-list.component';
import { BookSlotComponent } from './visit/book-slot/book-slot.component';
import { ReBookSlotComponent } from './visit/re-book-slot/re-book-slot.component';
import { VisitCancelComponent } from './visit/visit-cancel/visit-cancel.component';
import { VisitConfirmPresenceComponent } from './visit/visit-confirm-presence/visit-confirm-presence.component';
import { BookSlotStatusComponent } from './visit/book-slot-status/book-slot-status.component';
import { AfterPaymentComponent } from './visit/after-payment/after-payment.component';
import { LoginComponent } from './login/login.component';
import { ConfirmComponent } from './confirm/confirm.component';

//register
import { RegisterComponent } from './register/register.component'; 
import { PersonalDetailsFormComponent } from './personal-detail/personal-detail.component';

import { PatientComponent } from './patient/patient.component';
import { MyVisitsComponent } from './visit/my-visits/my-visits.component';
import { RemindPasswordComponent } from './remind-password/remind-password.component';
import { MenuComponent } from './menu/menu.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { UserService } from './shared/services/user.service';
import { ClientService } from './shared/services/client.service';
import { CaptchaService } from './shared/services/captcha.service';
import { NotificationService } from './shared/services/notification.service';

//visit
import { SpecialityService } from './visit/shared/services/speciality.service';
import { LanguageService } from './visit/shared/services/language.service';
import { DepartmentService } from './visit/shared/services/department.service';
import { DoctorService } from './visit/shared/services/doctor.service';
import { ServiceService } from './visit/shared/services/service.service';
import { VisitService } from './visit/shared/services/visit.service';
import { VisitTypeService } from './visit/shared/services/visit-type.service';
import { EVisitRoomComponent } from './visit/e-visit-room/e-visit-room.component';
import { EVisitDocRoomComponent } from './visit/e-visit-doc-room/e-visit-doc-room.component';
import { EVisitComponent } from './visit/e-visit/e-visit.component';

import { AuthInterceptor } from './shared/auth.interceptor';
import { LoggedInGuard } from './shared/logged-in.guard';
import { FullAccessGuard } from './shared/full-access.guard';
import { RegistrationVisibleGuard } from './shared/registration-visible.guard';
import { ngfModule } from "angular-file";

import localePl from '@angular/common/locales/pl';
import { PaymentComponent } from './payment/payment.component';
import { PaymentService } from './shared/services/payment.service';
import { PaymentStatusComponent as PaymentStatusComponent } from './payment/status/payment-status.component';
import { SpeechBubbleComponent } from './speech-bubble/speech-bubble.component';

registerLocaleData(localePl, 'pl');

@NgModule({
    declarations: [
        AppComponent,
        FiltersComponent,
        VisitComponent,
        FreeTermsCalendarComponent,
        VisitSlotListComponent,
        BookSlotStatusComponent,
        BookSlotComponent,
        ReBookSlotComponent,
        MenuComponent,
        LoginComponent,
        RegisterComponent,
        PersonalDetailsFormComponent,
        PatientComponent,
        MyVisitsComponent,
        VisitCancelComponent,
        ConfirmComponent,
        RemindPasswordComponent,
        AfterPaymentComponent,
        VisitConfirmPresenceComponent,
        EVisitRoomComponent,
        EVisitDocRoomComponent,
        NotFoundComponent,
        PaymentComponent,
        PaymentStatusComponent,
        EVisitComponent,
        SpeechBubbleComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CalendarModule,
        TooltipModule,
        InputMaskModule,
        CaptchaModule,
        ToggleButtonModule,
        ngfModule,
        routing,
    ],
    providers: [
        UserService,
        ClientService,
        NotificationService,
        PaymentService,
        LoggedInGuard,
        RegistrationVisibleGuard,
        FullAccessGuard,

        //visit
        SpecialityService,
        LanguageService,
        DepartmentService,
        DoctorService,
        ServiceService,
        VisitService,
        CaptchaService,
        VisitTypeService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: APP_INITIALIZER, useFactory: (config: ClientService) => () => config.getConfig(), deps: [ClientService], multi: true },
        { provide: LOCALE_ID, useValue: 'pl' },
    ]
})
export class AppModuleShared {
}