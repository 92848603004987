import { Injectable } from '@angular/core';
import { SaveInvoiceDataModel } from '@app/shared/models/save-invoice-data.model';
import { BaseResponse } from '../models/base-response.model';
import { HttpClient } from '@angular/common/http';
import { GetPaymentDetailsResponse } from '../models/get-payment-details-response';
import { MakePaymentModel } from '../models/make-payment.model';
import { GetPaymentStatusResponse } from '../models/get-payment-status-response.model';

@Injectable()
export class PaymentService {
  
  constructor(private http: HttpClient) { }

  getDetails(token: string) {
    return this.http.get<GetPaymentDetailsResponse>(`api/Payment/Details?token=${token}`);
  }

  makePayment(model: MakePaymentModel) {
    return this.http.post('api/Payment/MakePayment', model);
  }

  saveInvoiceData(model: SaveInvoiceDataModel) {
    return this.http.post<BaseResponse>(`api/Payment/SaveInvoiceData`, model);
  }

  getPaymentStatus(token: string) {
    return this.http.get<GetPaymentStatusResponse>(`api/Payment/GetStatus?token=${token}`);
  }
}
