<div *ngIf="showCalendar" [@fadeInOut] class="col-xs-12">
  <hr />
  <div class="col-xs-12">
    <h5 class="text-main">
      Wybierz termin wizyty - kliknij na kalendarzu wybrany dzień, by wyświetlić
      wolne godziny
    </h5>
    <br />
  </div>
  <div class="col-xs-12">
    <div class="col-xs-12 col-md-4">
      <div
        id="calendar-loader"
        class="load-board loader"
        [class.visible-loader]="(loadCalendar | async) || isLoadingForced"
      >
        <div class="loading">
          <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
          Trwa ładowanie...
        </div>
      </div>
      <p-calendar
        [(ngModel)]="selectedDay"
        [locale]="calendar_pl"
        [inline]="true"
        [minDate]="minDate"
        [maxDate]="maxDate"
        styleClass="calendar-control"
        [disabledDates]="disabledDays"
        [showOtherMonths]="false"
        (onSelect)="daySelected($event)"
        (onMonthChange)="monthChanged($event)"
        [disabled]="loadCalendar | async"
      >
        <ng-template pTemplate="date" let-date>
          <span
            [ngStyle]="{
              backgroundColor: dayFree(date)
                ? colFree
                : dayFull(date)
                ? cellTaken
                : 'inherit'
            }"
            style="
              padding: 0.25em;
              width: 1.75em;
              height: 1.75em;
              display: block;
              cursor: pointer;
              color: white;
            "
          >
            {{ date.day }}
          </span>
        </ng-template>
      </p-calendar>
    </div>
    <div class="col-xs-12 col-md-3">
      <p>Wybrany termin:</p>
      <h4 *ngIf="selectedDay != null" class="text-main">
        {{ selectedDay | date: "dd-MM-yyyy" }} ({{ selectedDayOfWeek }})
      </h4>
      <h4 *ngIf="selectedDay == null" class="text-main">brak</h4>
      <button
        class="btn btn-primary input-full-width"
        (click)="goToNextDay()"
        [disabled]="!haveMoreFreeDays()"
      >
        Następny
      </button>
    </div>
    <div class="col-xs-12 col-md-5">
      <div class="legend">
        <div><span class="zaj"></span>brak wolnych miejsc</div>
        <div><span class="free"></span>wolny termin</div>
        <div><span class="wyb"></span>wybrany termin</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <br />
    <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error"></div>
  </div>
</div>
