<p *ngIf="forCreatingAccount">
    Jeżeli po raz pierwszy korzystasz z rezerwacji online, podaj swoje dane i kliknij "rejestruj konto".
    <span *ngIf="!allowMultiConsents"><br />Wszystkie pola są obowiązkowe</span>
</p>
<p *ngIf="hasPrivacyPolicy"><a [href]="privacyPolicyUrl" target="_blank">Polityka prywatności</a></p>
<p *ngIf="allowMultiConsents"><small>Pola obowiązkowe</small></p>
<form [@fadeInOut] (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
    <div class="row">
        <div class="col-xs-12 col-no-padding">
            <div class="col-md-6">
                <div class="form-group" [class.has-error]="!okName">
                    <input type="text" class="form-control text-uppercase" formControlName="name" placeholder="imię">
                    <p [hidden]="okName"
                       class="text-danger">
                        Imię jest wymagane
                    </p>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group" [class.has-error]="!okSurname">
                    <input type="text" class="form-control text-uppercase" formControlName="surname" placeholder="nazwisko">
                    <p [hidden]="okSurname"
                       class="text-danger">
                        Nazwisko jest wymagane
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-no-padding">
            <div *ngIf="!registrationWithBirthDate" class="col-md-6">
                <div class="form-group" [class.has-error]="!okPesel">
                    <input type="text" class="form-control" formControlName="pesel" placeholder="PESEL">
                    <p [hidden]="okPesel"
                       class="text-danger">
                        Numer PESEL jest wymagany
                    </p>
                </div>
                <div *ngIf="allowRegistrationForForeigners" class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" formControlName="dontHavePesel">
                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                            Nie posiadam numeru PESEL
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="registrationWithBirthDate" class="col-md-6">
                <div class="form-group" [class.has-error]="!okBirthDate">
                    <p-calendar styleClass="form-control" [locale]="calendar_pl" dateFormat="yy-mm-dd" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="dateRange" placeholder="DATA URODZENIA" formControlName="birthDate"></p-calendar>
                    <p [hidden]="okBirthDate"
                       class="text-danger">
                        Data urodzenia jest wymagana
                    </p>
                </div>
            </div>

            <div class="col-md-6">
                <div style="width:20%; float:left;">
                    <input class="form-control" type="text" placeholder="+48" value="+48" disabled>
                </div>
                <div class="form-group" [class.has-error]="!okPhone">
                    <p-inputMask styleClass="form-control" [autoClear]="false" [unmask]="true" formControlName="phone" mask="999-999-999"></p-inputMask>
                </div>
                <p [hidden]="okPhone"
                   class="text-danger">
                    Numer telefonu jest wymagany
                </p>
            </div>
        </div>
    </div>

    <div *ngIf="forCreatingAccount" class="col-md-12">
        <p>
            Dane niezbędne do późniejszego logowania
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12 col-no-padding">
            <div class="col-md-6">
                <div class="form-group" [class.has-error]="!okEmail">
                    <input type="text" class="form-control" formControlName="email" placeholder="email">
                    <p [hidden]="okEmailReq"
                       class="text-danger">
                        Email jest wymagany
                    </p>
                    <p [hidden]="okEmail"
                       class="text-danger">
                        Nieprawidłowy adres email
                    </p>
                </div>
            </div>
            <div *ngIf="forCreatingAccount" class="col-md-6">
                <div formGroupName="passwordsGroup">
                    <div class="form-group" [class.has-error]="!okPassword">
                        <input type="password" class="form-control" formControlName="password" placeholder="hasło">
                        <p [hidden]="okPassword"
                           class="text-danger">
                            Hasło jest wymagane
                        </p>
                    </div>
                    <div class="form-group" [class.has-error]="!okPasswordsMatch">
                        <input type="password" class="form-control" formControlName="confirmPassword" placeholder="powtórz hasło">
                        <p [hidden]="okPasswordsMatch"
                           class="text-danger">
                            Podane hasła nie są identyczne
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="checkbox">
                <label>
                    <input type="checkbox" formControlName="rules">
                    <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                    <span *ngIf="!hasRules">Wyrażam zgodę na przetwarzanie przez placówkę medyczną wprowadzonych danych, za pomocą środków komunikacji elektronicznej, w szczególności poczty elektronicznej i sms.</span>
                    <span *ngIf="hasRules">Akceptuję <a [href]="rulesUrl" target="_blank">Regulamin</a>.</span>
                </label>
            </div>
            <p [hidden]="okRules"
               class="text-danger">
                {{noAgreementMessage}}
            </p>
        </div>
    </div>
    <div *ngIf="allowMultiConsents && tos.length > 0" class="col-md-12" formArrayName="tos">
        <p><small>Pola opcjonalne</small></p>
        <div *ngFor="let t of tos.controls; let i = index" class="form-group">
            <div class="checkbox">
                <label>
                    <input type="checkbox" [formControlName]="i">
                    <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                    {{terms[i].content}}
                </label>
                <i *ngIf="terms[i].description.length > 0" class="info fa fa-info-circle" pTooltip="{{terms[i].description}}" tooltipPosition="right"></i>
            </div>
            <p [hidden]="okTos(i)" class="text-danger">
                {{noAgreementMessage}}
            </p>
        </div>
    </div>
    <div *ngIf="forCreatingAccount" [hidden]="!showRegisterAditionalInfo" class="col-md-12">
        <p>{{registerAditionalInfo}}</p>
    </div>
    <div class="col-md-12">
        <div class="form-group captcha">
            <p-captcha *ngIf="useCaptcha" siteKey="6LfywXoUAAAAAEr6Cs2BLeWxE9qnyMYYRQBfF_Hw" language="pl" (onResponse)="captchaOnResponse($event)" (onExpire)="captchaOnExpire()"></p-captcha>
        </div>
    </div>
    <div class="col-md-12">
        <div *ngIf="_isError" class="alert alert-danger" [innerHTML]="_error"></div>
    </div>
    <div class="col-md-6 col-md-offset-6">
        <button type="submit" class="btn btn-primary input-full-width" [disabled]="submitingForm">
            {{ btnSubmitLabel }}
            <i *ngIf="!submitingForm" class="{{ btnSubmitIcon }}"></i>
            <i *ngIf="submitingForm" class="fa fa-spinner fa-pulse fa-fw"></i>
        </button>
    </div>
</form>