import { BaseResponse } from '../../../shared/models/base-response.model';

export class ConfirmPresence{
    constructor(public visitId: number, public urlId: string) {
    }
}

export class ConfirmPresenceResponse extends BaseResponse{
    teleRoomId: number;
    teleToken: string;
}