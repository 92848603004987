<menu></menu>
<div [@fadeInOut] class='container body-content'>
    <div class='row'>
        <div class='col-xs-12 clearfix'>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div [@fadeInOut] class="container footer text-center hidden-print">
    <div class="row">
        <div class="col-xs-12">
            <h5><small>Powered by <img src="/img/logo.png" alt="Medsoft"></small></h5>
        </div>
    </div>
</div>
