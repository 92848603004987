<div [@fadeInOut] class="col-xs-12 col-md-8 col-md-offset-2">
    <div *ngIf="waitingForPayment" class="col-xs-12 text-center">
        <h4><i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Trwa oczekiwanie na potwierdzenie płatności</h4>
    </div>
    <div *ngIf="finishedPayment" class="col-xs-12 text-center">
        <div *ngIf="!isError" class="alert alert-success" [innerHTML]="message">
        </div>
        <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
        </div>
        <div class="col-xs-6 col-xs-offset-3">
            <button type="button" class="btn btn-primary input-full-width" (click)="go()">Dalej <i class="fa fa-angle-right"></i></button>
        </div>
    </div>
</div>