import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

import { VisitService } from '../shared/services/visit.service';
import { GetResponse } from '../shared/models/get-response.model';

import { ErrorProcessor } from '../../shared/error-processor';
import { Subscription } from 'rxjs';

@Component({
    selector: 'visit-confirm-presence',
    templateUrl: './visit-confirm-presence.component.html',
    styleUrls: ['./visit-confirm-presence.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0', height: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                            animate('.7s ease-out', style({ height: '*' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1', height: '*' }),
                        animate('.6s ease-in', style({ opacity: '0' })),
                        animate('.5s ease-in', style({ height: '0' })),
                    ]),
            ]),
    ]
})
export class VisitConfirmPresenceComponent implements OnInit, OnDestroy {
    isError = false;
    error = '';
    isMsg = false;
    message = '';
    finished = false;
    confirmedVoiceAndVideo = false;
    isProcessing = false;

    allowSwitchToPhone = false;
    volLvl = 0;

    @ViewChild('videoPrev') videoPrevEl: ElementRef;

    @Input() visit: GetResponse;
    @Output() canceled: EventEmitter<GetResponse> = new EventEmitter<GetResponse>();
    @Input() hideCancel: boolean = false;
    @Input() urlId: string;

    private stream: MediaStream;
    private streamClone: MediaStream;
    private javascriptNode: ScriptProcessorNode;

    private $changeType: Subscription;
    private $confirmPresence: Subscription;

    constructor(private visitService: VisitService, private router: Router, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.finished = false;
        this.volLvl = 0;
        this.allowSwitchToPhone = false;
        this.isError = false;
        this.error = '';
        this.confirmedVoiceAndVideo = false;
        this.isProcessing = false;
        
        if(this.visit.visitTypeId == 2){
            this.checkMicAndCamera();
        } else{
            
        }
    }

    ngOnDestroy(){
        this.closeStreams();

        if(this.$confirmPresence)
            this.$confirmPresence.unsubscribe();
        
        if(this.$changeType)
            this.$changeType.unsubscribe();
    }

    closeStreams(){
        if(this.javascriptNode)
            this.javascriptNode.disconnect();
        if(this.stream){
            this.stream.getTracks().forEach(function(track:any) {
                track.stop();
            });
        }
        if(this.streamClone){
            this.streamClone.getTracks().forEach(function(track:any) {
                track.stop();
            });
        }
    }

    checkMicAndCamera(){
        let nav = <any>navigator;
        nav.getUserMedia = nav.getUserMedia || nav.webkitGetUserMedia || nav.mozGetUserMedia;
        if (nav.getUserMedia) {
            nav.getUserMedia({
                audio: true,
                video: true
                },
                (stream: MediaStream) => {
                    this.stream = stream;

                    this.streamClone = stream.clone()
                    //usuwam audio, żeby nie było słychać dźwięku u pacjenta
                    this.streamClone.getAudioTracks().forEach((t) => this.streamClone.removeTrack(t));
                    this.videoPrevEl.nativeElement.srcObject = this.streamClone;
                    this.cdRef.detectChanges();

                    let audioContext = new AudioContext();
                    let analyser = audioContext.createAnalyser();
                    let microphone = audioContext.createMediaStreamSource(stream);
                    let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
                    this.javascriptNode = javascriptNode;

                    analyser.smoothingTimeConstant = 0.8;
                    analyser.fftSize = 1024;

                    microphone.connect(analyser);
                    analyser.connect(javascriptNode);
                    javascriptNode.connect(audioContext.destination);
                    
                    
                    javascriptNode.onaudioprocess = () => {
                        var array = new Uint8Array(analyser.frequencyBinCount);
                        analyser.getByteFrequencyData(array);
                        var values = 0;

                        var length = array.length;
                        for (var i = 0; i < length; i++) {
                            values += (array[i]);
                        }

                    var average = values / length;
                    this.volLvl = Math.round(average) * 2;
                    if(this.volLvl > 100){
                        this.volLvl = 100;
                    }
                    this.cdRef.detectChanges();

                    } // end fn stream
                },
                () => {
                    this.allowSwitchToPhone = true;
                    this.isError = true;
                    this.error = 'Nie udało nam się uzyskać dostępu do kamery i/lub mikrofonu. Są one wymagane do odbycia wizyty';
                    this.cdRef.detectChanges();
                    this.closeStreams();
                }
            );
        } else {
            this.allowSwitchToPhone = true;
            this.isError = true;
            this.error = 'Twoja przeglądarka nie posiada obsługi kamery lub mikrofonu, który jest wymagany do przeprowadzenia wizyty. Prosimy o skorzystanie z innej przeglądarki internetowej.';
            this.cdRef.detectChanges();
            this.closeStreams();
        }
    }

    confirmPresence(){
        console.log('confirmed', this.visit.visitTypeId);

        this.isProcessing = true;
        this.$changeType = this.visitService.confirmPresence(this.visit.visitId, this.urlId)
            .subscribe(res =>{
                this.finished = true;
                this.isProcessing = false;
                this.visit.teleToken = res.teleToken;
                this.visit.canConfirmPresence = false;
                if(this.visit.visitTypeId == 3){
                    this.isMsg = true;
                    this.message = '<h4>Dziękujemy za potwierdzenie wizyty.</h4><h5>Lekarz skontaktuje się z Tobą w ciągu kilku minut.</h5>';
                } else {
                    this.router.navigate(['/evisit-room'], {state: {data: {visit: this.visit}}});
                }
            }, err => {
                this.finished = true;
                this.isProcessing = false;
                this.isError = true;
                this.isMsg = false;
                this.error = ErrorProcessor.process(err);
            })
    }

    cancel() {
        this.canceled.emit(this.visit);
    }

    cameraOrVoiceDontWork(){
        this.isMsg = true;
        this.message = "Kamera lub mikrofon nie funkcjonuje prawidłowo. Są one wymagane do odbycia ewizyty w wybranej formie."
        this.allowSwitchToPhone = true;
        this.closeStreams();
    }

    cameraAndVoiceWorks(){
        this.confirmedVoiceAndVideo = true;
        this.closeStreams();
    }

    switchToPhone(){
        this.isProcessing = true;
        this.$changeType = this.visitService.changeVisitType(this.visit.visitId, 3, this.urlId)
            .subscribe(res =>{
                this.isProcessing = false;
                this.visit.visitTypeId = 3;
                this.visit.visitTypeName = res.visitTypeName;
            }, err => {
                this.isProcessing = false;
                this.isError = true;
                this.isMsg = false;
                this.error = ErrorProcessor.process(err);
            })
    }
}