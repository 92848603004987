<div [@fadeInOut] class="col-xs-12 col-md-4 col-md-offset-4">
    <div class="text-center">
        <img src="/img/pac-logo.png"/>
    </div>
    <br/>
    <div class="col-xs-12">
        <form *ngIf="!isMsg" (ngSubmit)="onSubmit()" [formGroup]="remindForm" novalidate>
            <div class="form-group" [class.has-error]="!okEmailReq">
                <input type="text" class="form-control" formControlName="email" placeholder="email">
                <p [hidden]="okEmailReq"
                    class="text-danger">
                    Email jest wymagany
                </p>
                <p [hidden]="okEmail"
                    class="text-danger">
                    Proszę podać prawidłowy email
                </p>
            </div>
            <button type="submit" class="btn btn-primary input-full-width" [disabled]="!remindForm.valid || sendingEmail">
                Przypomnij hasło 
                <i *ngIf="!sendingEmail" class="fa fa-key"></i>
                <i *ngIf="sendingEmail" class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
        </form>
        <br />
        <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
        </div>
        <div *ngIf="isMsg" class="alert alert-success" [innerHTML]="message">
        </div>
    </div>
</div>