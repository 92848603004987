<div [@fadeInOut] *ngIf="!!visit && !hasOtherConnection">
    <div class="row">
        <div class="col-xs-12 text-center text-main">
            <h4>{{visit.visitTypeName}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-xs-6 text-center">
            <p title="Data wizyty">
                <i class="fas fa-calendar-alt fa-lg"></i> {{visit.date}}
            </p>
        </div>
        <div class="col-md-3 col-xs-6">
            <p title="Godzina wizyty">
                <i class="fas fa-clock fa-lg"></i> {{visit.hour}}
            </p>
        </div>
        <div class="col-md-3 col-xs-6">
            <p title="Lekarz">
                <i class="fas fa-user-md  fa-lg"></i> {{visit.doctor}}
            </p>
        </div>
        <div class="col-md-3 col-xs-6">
            <p title="Usługa">
                <i class="fas fa-medkit  fa-lg"></i> {{visit.service}}
            </p>
        </div>
    </div>
    <div class="row" *ngIf="!noVideo">
        <div class="col-md-10 col-md-offset-1 col-xs-12 video-container">
            <app-speech-bubble *ngIf="!isDoctorConnected"
                               imgSrc="/app/assets/images/person.png"
                               [message]="waitingForDoctorMessage">
            </app-speech-bubble>
            
            <video id="doc-video" #docVideo autoplay playsinline></video>
            <video id="patient-video" #patientVideo autoplay playsinline></video>
        </div>
    </div>
    <div class="row" *ngIf="!noVideo">
        <div class="col-md-8 col-md-offset-1 col-xs-12">
            <div
                ngfDrop
                selectable="1"
                multiple="1"
                [(files)]="file"
                [maxSize]="maxSize"
                class="drop-zone"
                accept=".txt,.pdf,.xsl,.xslt,.doc,.docx,.png,.jpeg"
                [fileDropDisabled]="isProcessing"
                [(validDrag)]="validDrag"
                [class.invalid-drag]="validDrag===false"
                [class.valid-drag]="validDrag"
                [class.file-selected]="!!file"
                [class.files-in-one-line]="filesInOneLine"
                [(lastInvalids)]="lastInvalids">
                <span *ngFor="let t of text; let i = index">{{t}}&nbsp;<i class="fal fa-times-square text-danger clickable" *ngIf="!!file" (click)="removeFile($event, i)" title="Usuń"></i></span>
            </div>
        </div>
        <div class="col-md-2 col-xs-12">
            <button type="button" class="btn btn-sm btn-success input-full-width" [disabled]="!file" (click)="addFiles()">Prześlij <i class="fas fa-upload"></i>
                &nbsp;<i class="fas fa-spinner fa-pulse" [class.hidden]="!isProcessing"></i></button>
        </div>
        <br/>
    </div>
    <div class="row">
        <div class="col-md-10 col-md-offset-1 col-xs-12">
            <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
            </div>
            <div *ngIf="isMsg" class="alert alert-success text-center" [innerHTML]="message">
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="hasOtherConnection">
    <div class="col-md-10 col-md-offset-1 col-xs-12">
        <div class="alert alert-danger">
            Posiadasz inne aktywne połączenie. Zamknij pozostałe okna i odśwież stronę, aby ponowić próbę połączenia.
        </div>
    </div>
</div>