import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";

import { VisitService } from "../shared/services/visit.service";
import { SearchResponse } from "../shared/models/search-response.model";
import { ClientService } from "@app/shared/services/client.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "visit-book-slot",
  templateUrl: "./book-slot.component.html",
  styleUrls: ["./book-slot.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0", height: "0" }),
        group([
          animate(".7s ease-out", style({ opacity: "1" })),
          animate(".7s ease-out", style({ height: "*" })),
        ]),
      ]),
      transition(":leave", [
        style({ opacity: "1", height: "*" }),
        animate(".6s ease-in", style({ opacity: "0" })),
        animate(".5s ease-in", style({ height: "0" })),
      ]),
    ]),
  ],
})
export class BookSlotComponent {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  public telemedicineActive: boolean = false;

  isError = false;
  error = "";
  @Input() slot: SearchResponse;
  @Input() showPrices: boolean;
  @Input() showPayment: boolean;
  @Input() blockWithoutPayment: boolean;
  @Output() canceled: EventEmitter<SearchResponse> =
    new EventEmitter<SearchResponse>();

  constructor(
    private visitService: VisitService,
    private clientService: ClientService
  ) {
    this.clientService.configChange
      .pipe(takeUntil(this.$destroy))
      .subscribe((model) => {
        this.telemedicineActive = model.telemedicineActive;
      });
  }

  selectType(id: number, name: string) {
    this.slot.typeSelected = id;
    this.slot.typeNameSelected = name;
  }

  book(isPaying = false) {
    this.slot.isPaying = isPaying;
    this.visitService.startBookingVisit(this.slot);
  }

  cancel() {
    this.canceled.emit(this.slot);
  }

  changeType() {
    this.slot.typeSelected = 0;
  }

  get forcePayment(): boolean {
    return this.slot.typeSelected !== 1;
  }
}
