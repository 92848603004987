﻿import { Component} from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router } from '@angular/router';
import { Title }     from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { UserService } from './../shared/services/user.service';
import { ClientService } from './../shared/services/client.service';
import { ErrorProcessor } from './../shared/error-processor';

@Component({
    templateUrl: './remind-password.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class RemindPasswordComponent {
    isError = false;
    error = '';
    isMsg = false;
    message = '';
    redirectToLogin: any;

    remindForm: FormGroup;
    sendingEmail: boolean = false;

    constructor(private titleService: Title, private fb: FormBuilder, private router: Router, private userService: UserService, private clientService: ClientService) {
        titleService.setTitle("Medsoft - Przypomnij hasło");
        this.createForm();
    }

    createForm() {
        this.remindForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });

    }

    onSubmit() {
        if (!this.remindForm.valid)
            return;
        this.sendingEmail = true;
        this.userService.remindPassword(this.remindForm.value.email).subscribe((result: any) => {
                this.isError = false;
                if (result.success && result.message == 'PRZYPOMNIENIE_WYSLANE') {
                    this.isMsg = true;
                    this.message = "Przypomnienie zostało wysłane na podany adres email";
                } else {
                    this.isMsg = false;
                    this.isError = true;
                    this.error = 'Wystąpił nieoczekiwany błąd';
                }
                this.sendingEmail = false;
            },
            (error: any) => {
                this.sendingEmail = false;
                this.isMsg = false;
                this.isError = true;
                this.error = ErrorProcessor.process(error);
                if (this.error === "")
                    this.error = "Nie znaleziono konta dla podanego adresu email";
            });
    }

    get okEmailReq(): boolean {
        return !this.remindForm.hasError('required', ['email']) || this.remindForm.get('email')!.pristine;
    }

    get okEmail(): boolean {
        return !this.remindForm.hasError('email', ['email']) || (this.remindForm.get('email')!.pristine);
    }
}
