﻿export class GetResponse {
    constructor(public visitId: number,
        public date: string,
        public hour: string,
        public doctor: string,
        public department: string,
        public service: string,
        public canCancel: boolean,
        public cantCancelMessage: string,
        public hasPayment: boolean,
        public visitTypeId: number,
        public visitTypeName: string,
        public teleToken: string | null,
        public canConfirmPresence: boolean,
        public confirmTime: number) {
    }
}