﻿import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormGroup, FormBuilder } from "@angular/forms";

import { VisitService } from "../shared/services/visit.service";
import { GetResponse } from "../shared/models/get-response.model";
import { Subject } from "rxjs";
import { ClientService } from "@app/shared/services/client.service";
import { takeUntil } from "rxjs/operators";

@Component({
  templateUrl: "./my-visits.component.html",
  styleUrls: ["./my-visits.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        group([animate(".7s ease-out", style({ opacity: "1" }))]),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate(".8s ease-in", style({ opacity: "0" })),
      ]),
    ]),
  ],
})
export class MyVisitsComponent implements OnInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  public telemedicineActive: boolean = false;

  showCancelVisit = false;
  showConfirmPresence = false;
  isError = false;
  error = "";
  hasMessage: boolean = false;
  message: string;

  visits: GetResponse[] = [];
  selectedVisit: GetResponse;

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private visitService: VisitService,
    private clientService: ClientService
  ) {
    titleService.setTitle("Medsoft - Moje wizyty");

    this.visitService.patientVisits.subscribe((data: GetResponse[]) => {
      this.visits = data;
    });

    this.clientService.configChange
      .pipe(takeUntil(this.$destroy))
      .subscribe((model) => {
        this.telemedicineActive = model.telemedicineActive;
      });
  }

  ngOnInit(): void {
    this.visitService.get();
  }

  cancelVisit(visit: GetResponse) {
    this.selectedVisit = visit;
    this.showCancelVisit = true;
  }

  hideCancel() {
    this.showCancelVisit = false;
  }

  confirmPresence(visit: GetResponse) {
    this.selectedVisit = visit;
    this.showConfirmPresence = true;
  }

  hideConfirmPresence() {
    this.showConfirmPresence = false;
  }

  get noVisits(): boolean {
    return this.visits.length === 0;
  }

  goToVisitRoom(visit: GetResponse) {
    this.router.navigate(["/evisit-room"], {
      state: { data: { visit: visit } },
    });
  }
}
