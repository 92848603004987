<div [@fadeInOut]  class="col-xs-12 col-md-6 col-md-offset-3 bg">
    <div *ngIf="!finished">
        <div class="col-xs-12 text-center">
            <h4 class="text-main">Potwierdź gotowość <i class="fa fa-calendar-check"></i></h4>
        </div>
        <div class="bg clearfix" *ngIf="visit.visitTypeId === 3">
            <div class="col-xs-12" >
                <h4 class="text-center">{{visit.visitTypeName}}</h4>
                <h5>Czy na pewno chcesz potwierdźić obecność?</h5>
                <h5>Po potwierdzeniu obecności w ciągu kilku minut lekarz skontaktuje się z Tobą telefonicznie.</h5>
            </div>
            <div class="col-xs-6">
                <button type="button" class="btn btn-danger input-full-width" (click)="cancel()">NIE <i class="fa fa-times"></i></button>
            </div>
            <div class="col-xs-6">
                <button type="button" class="btn btn-success input-full-width" (click)="confirmPresence()">TAK <i class="fa fa-check"></i>
                    &nbsp;<i class="fas fa-spinner fa-pulse" [class.hidden]="!isProcessing"></i></button>
            </div>
        </div>
        <div class="bg clearfix" *ngIf="visit.visitTypeId == 2">
            <div *ngIf="allowSwitchToPhone" class="col-xs-12 col-no-padding">
                <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
                </div>
                <div *ngIf="isMsg" class="alert alert-warning text-center" [innerHTML]="message">
                </div>
                <p>Jeżeli nie jesteś w stanie zrealizować wizyty w wybranej formie, możesz zmienić jej rodzaj na rozmowę telefoniczną z lekarzem klikając w poniższy przycisk</p>
                <div class="col-xs-8 col-xs-offset-2">
                    <button type="button" class="btn btn-success input-full-width" (click)="switchToPhone()">Rozmowa telefoniczna <i class="fa fa-phone"></i>
                        &nbsp;<i class="fas fa-spinner fa-pulse" [class.hidden]="!isProcessing"></i></button>
                </div>
            </div>
            <div *ngIf="!allowSwitchToPhone && !confirmedVoiceAndVideo">
                <div class="col-xs-12">
                    <h4 class="text-center">{{visit.visitTypeName}}</h4>
                    <p>Test działania kamery i mikrofonu</p>
                    <video #videoPrev autoplay playsinline></video>
                    <div class="progress">
                        <div class="progress-bar progress-bar-success" role="progressbar" [attr.aria-valuenow]="volLvl" aria-valuemin="0" aria-valuemax="100" [style.width.%]="volLvl">
                        </div>
                    </div>
                </div>
                <div class="col-xs-12" >
                    <h4>Czy podgląd z kamery jest widoczny, a podczas mówienia do mikrofonu pasek porusza się?</h4>
                </div>
                <div class="col-xs-6">
                    <button type="button" class="btn btn-danger input-full-width" (click)="cameraOrVoiceDontWork()">NIE <i class="fa fa-times"></i></button>
                </div>
                <div class="col-xs-6">
                    <button type="button" class="btn btn-success input-full-width" (click)="cameraAndVoiceWorks()">TAK <i class="fa fa-check"></i></button>
                </div>
            </div>
            <div *ngIf="!allowSwitchToPhone && confirmedVoiceAndVideo">
                <div class="col-xs-12" >
                    <h4 class="text-center">{{visit.visitTypeName}}</h4>
                    <h5>Czy na pewno chcesz potwierdźić obecność?</h5>
                    <h5>Po potwierdzeniu obecności zostaniesz przeniesiony na stronę realizacji wizyty gdzie dołączy do Ciebie lekarz.</h5>
                </div>
                <div class="col-xs-6">
                    <button type="button" class="btn btn-danger input-full-width" (click)="cancel()">NIE <i class="fa fa-times"></i></button>
                </div>
                <div class="col-xs-6">
                    <button type="button" class="btn btn-success input-full-width" (click)="confirmPresence()">TAK <i class="fa fa-check"></i>
                        &nbsp;<i class="fas fa-spinner fa-pulse" [class.hidden]="!isProcessing"></i></button>
                </div>
            </div>
        </div>
        <div class="col-xs-6 col-xs-offset-3" *ngIf="!hideCancel">
            <button type="button" class="btn btn-primary input-full-width" (click)="cancel()">Zamknij</button>
        </div>
    </div>
    <div *ngIf="finished">
        <div class="col-xs-12 col-no-padding">
            <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
            </div>
            <div *ngIf="isMsg" class="alert alert-success text-center" [innerHTML]="message">
            </div>
        </div>
        <div class="col-xs-6 col-xs-offset-3" *ngIf="!hideCancel">
            <button type="button" class="btn btn-primary input-full-width" (click)="cancel()">Zamknij</button>
        </div>
    </div>
</div>
