import { BaseResponse } from '../../../shared/models/base-response.model';

export class ChangeType{
    constructor(public visitId: number,
        public visitTypeId: number,
        public urlId: string) {
    }
}

export class ChangeTypeResponse extends BaseResponse{
    visitTypeName: string;
}