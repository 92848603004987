﻿import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { UserService } from './../shared/services/user.service';
import { ClientService } from './../shared/services/client.service';
import { ChangePasswordResponse } from './../shared/models/change-password-response.model';
import { GetPatientResponse } from './../shared/models/get-patient-response.model';
import { ConfigModel } from './../shared/models/config.model';
import { ErrorProcessor } from './../shared/error-processor';
import { MedsoftValidators } from './../shared/validators/validators';
import { Term } from '../shared/models/term-model';
import { forEach } from '@angular/router/src/utils/collection';
import { ChangeAcceptedToSResponse } from '../shared/models/change-accepted-tos-response';

@Component({
    templateUrl: './patient.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ],
    styles: ['.info { color: #317CB3; margin-top: -5px;}', '.btn-edit { padding: 0 0.5em; margin-left: 10px; }', '.tos-header { line-height: 1em; }']
})
export class PatientComponent implements OnInit {
    isError = false;
    isErrorToS = false;
    error = '';
    hasMessage: boolean = false;
    hasMessageToS: boolean = false;
    updatingToS: boolean = false;
    message: string;
    validate = false;
    patient: GetPatientResponse = new GetPatientResponse();
    terms: Array<Term>;

    allowMultiConsents: boolean;
    changePasswordForm: FormGroup;
    termsOfServiceForm: FormGroup;
    registrationWithBirthDate: boolean;

    constructor(private titleService: Title, private fb: FormBuilder, private router: Router, private userService: UserService, private clientService: ClientService) {
    }

    createForm() {
        this.changePasswordForm = this.fb.group({
            oldPassword: ['', Validators.required],
            passwordsGroup: this.fb.group({
                password: ['', [Validators.required]],
                confirmPassword: ['', Validators.required]
            },
                { validator: [MedsoftValidators.equalValidator] })
        });
    }

    createToSForm() {
        this.termsOfServiceForm = this.fb.group({
            tos: new FormArray([])
        });

        this.addTerms();
    }

    addTerms() {

        if (this.termsOfServiceForm == null || this.patient.acceptedToS == null) return;
        this.termsOfServiceForm.setControl('tos', new FormArray([]));
        for (let i = 0; i < this.terms.length; i++) {
            (<FormArray>this.termsOfServiceForm.get('tos')).push(new FormControl({ value: this.patient.acceptedToS!.indexOf(this.terms[i].termID) > -1, disabled: true }, []));
        }
    }

    get tos() {
        return this.termsOfServiceForm.get('tos') as FormArray;
    }

    ngOnInit(): void {
        this.titleService.setTitle("Medsoft - Moje dane");
        this.createForm();

        this.allowMultiConsents = this.clientService.config.termsOfServices != null;

        if (this.allowMultiConsents) {
            this.terms = this.clientService.config.termsOfServices;
            this.createToSForm();
        }

        this.userService.patientData.subscribe((data: GetPatientResponse) => {
            this.patient = data;

            if (this.allowMultiConsents)
                this.addTerms();
        });
        this.registrationWithBirthDate = this.clientService.config.registrationWithBirthDate;
        this.userService.getPatientData();
    }

    setUpdating(isUpdating: boolean) {
        this.updatingToS = isUpdating;
        this.isErrorToS = false;
        this.hasMessageToS = false;

        this.tos.controls.forEach((c, i) => {
            if (isUpdating)
                c.enable();
            else c.disable();
        });
    }

    onSubmit() {
        if (!this.changePasswordForm.valid) {
            this.validate = true;
            this.isError = true;
            this.error = 'Proszę poprawić wprowadzone dane';
            return;
        }

        let password = this.changePasswordForm.value.passwordsGroup.password;
        let passwordConfirmation = this.changePasswordForm.value.passwordsGroup.confirmPassword;
        let oldPassword = this.changePasswordForm.value.oldPassword;

        this.userService.changePassword(oldPassword, password, passwordConfirmation).subscribe((result: ChangePasswordResponse) => {
            if (result.success) {
                this.hasMessage = true;
                this.isError = false;
                this.message = "Hasło zostało zmienione";
            } else {
                this.isError = true;
                this.hasMessage = false;
                this.error = 'Wystąpił nieoczekiwany błąd';
            }
        },
            (error: any) => {
                this.isError = true;
                this.error = ErrorProcessor.process(error);
            });
    }

    onSubmitAcceptedToS() {
        this.setUpdating(false);

        let terms = <FormArray>this.termsOfServiceForm.get('tos');
        let acceptedToS: Array<number> = [];

        for (let i = 0; i < this.terms.length; i++) {
            if (terms.at(i).value === true) {
                acceptedToS.push(this.terms[i].termID);
            }
        }
        this.patient.acceptedToS = acceptedToS;
        this.userService.changeAcceptedToS(acceptedToS).subscribe((result: ChangeAcceptedToSResponse) => {
            if (result.success)
                this.message = "Zgody zostały zaktualizowane";
            else this.error = 'Wystąpił nieoczekiwany błąd';

            this.isErrorToS = !result.success;
            this.hasMessageToS = result.success;
        });
    }

    get okPassword(): boolean {
        return !this.changePasswordForm.hasError('required', ['passwordsGroup', 'password']) ||
            (this.changePasswordForm.get(['passwordsGroup', 'password'])!.pristine && !this.validate);
    }


    get okPasswordsMatch(): boolean {
        return !this.changePasswordForm.hasError('equal', ['passwordsGroup']) ||
            (this.changePasswordForm.get(['passwordsGroup', 'confirmPassword'])!.pristine && !this.validate);
    }

    get okOldPassword(): boolean {
        return !this.changePasswordForm.hasError('required', ['oldPassword']) || (this.changePasswordForm.get('oldPassword')!.pristine && !this.validate);
    }
}
