<div [@fadeInOut] class="col-xs-12" style="margin-bottom: 50px">
    <ng-container *ngIf="!withoutRegistration">
        <div class="text-center">
            <img src="/img/pac-logo.png" />
        </div>
        <br />
        <div class="col-xs-12" [class.extended]="allowReservationWithoutAccount">
            <div *ngIf="allowReservationWithoutAccount" class="vl"></div>
            <div class="row">
                <div [class]="loginPanelClass">
                    <label *ngIf="allowReservationWithoutAccount">Mam konto</label>
                    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" novalidate>
                        <div class="form-group" [class.has-error]="!okEmail">
                            <input type="text" class="form-control" formControlName="email" placeholder="email">
                            <p [hidden]="okEmail"
                               class="text-danger">
                                Email jest wymagany
                            </p>
                        </div>
                        <div class="form-group" [class.has-error]="!okPassword">
                            <input type="password" class="form-control" formControlName="password" placeholder="hasło">
                            <p [hidden]="okPassword"
                               class="text-danger">
                                Hasło jest wymagane
                            </p>
                        </div>
                        <div *ngIf="hasRules" class="form-group">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" formControlName="rules">
                                    <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                    <small>Akceptuję <a [href]="rulesUrl" target="_blank">Regulamin</a>.</small>
                                </label>
                                <p [hidden]="okRules"
                                   class="text-danger">
                                    Wyrażenie zgody jest wymagane
                                </p>
                            </div>
                        </div>
                        <p *ngIf="hasPrivacyPolicy"><a [href]="PrivacyPolicyUrl" target="_blank">Polityka prywatności</a></p>
                        <button type="submit" class="btn btn-primary input-full-width" [disabled]="!loginForm.valid">Zaloguj <i class="fa fa-sign-in"></i></button>
                    </form>
                    <br />
                    <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error">
                    </div>
                    <p><a [routerLink]="['/remind-password']">Nie pamiętam hasła</a></p>
                    <ng-container *ngIf="!allowReservationWithoutAccount">
                        <p>Nie posiadasz konta?</p>
                        <a class="btn btn-primary input-full-width" [routerLink]="['/register']">Zarejestruj się <i class="fa fa-user-plus"></i></a>
                    </ng-container>
                    <hr [class.small]="allowReservationWithoutAccount" />
                    <p><small>Nie pamiętasz danych logowania?<br>Skontaktuj się z rejestracją.</small></p>
                </div>
                <div *ngIf="allowReservationWithoutAccount" class="col-xs-6 col-md-offset-2 col-md-4">
                    <p *ngIf="mustLogin; else canWithoutLogin" style="padding-top: 120px;">Umówienie wizyty wybranego typu wymaga posiadania konta w systemie powiązanego z teczką pacjenta.</p>
                    <ng-template #canWithoutLogin>
                        <label>Nie mam konta</label>
                        <a class="btn btn-without-register input-full-width" (click)="withoutRegistrationClick()">Umów bez rejestracji <i class="fa fa-calendar-plus-o"></i></a>
                        <div class="or">lub</div>
                        <a class="btn btn-primary input-full-width" [routerLink]="['/register']">Zarejestruj się <i class="fa fa-user-plus"></i></a>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="withoutRegistration">
        <div class="col-xs-12 col-md-10 col-lg-8 col-md-offset-1 col-lg-offset-2">
            <h4 class="text-main">Dane do rezerwacji terminu</h4>
            <br />
            <personal-detail btnSubmitLabel="Umów wizytę"
                             btnSubmitIcon="fa fa-calendar-plus-o"
                             noAgreementMessage="Wyrażenie zgody jest wymagane w celu umówienia wizyty"
                             [useCaptcha]="true"
                             [(isError)]="isError"
                             [(error)]="error"
                             [(submitingForm)]="submitingForm"
                             (formSubmit)="personalDetailSubmit($event)">
            </personal-detail>
        </div>
    </ng-container>
   
</div>