﻿import { Component } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";

import { Title } from "@angular/platform-browser";

import { VisitService } from "../shared/services/visit.service";
import { UserService } from "@app/shared/services/user.service";
import { BookSlotResponse } from "../shared/models/book-slot-response.model";

import { ErrorProcessor } from "./../../shared/error-processor";
import { ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: "./book-slot-status.component.html",
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        group([animate(".7s ease-out", style({ opacity: "1" }))]),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate(".8s ease-in", style({ opacity: "0" })),
      ]),
    ]),
  ],
})
export class BookSlotStatusComponent {
  isError = false;
  error = "";
  message = "";
  isBooking: boolean = true;
  finishedBooking = false;
  visitId: number;
  hasPayment: boolean = false;
  paymentUrl: string;
  goingToPayment = false;
  hasInvoiceData = false;
  validate = false;

  private visitBookToken: string | null;

  constructor(
    private visitService: VisitService,
    private titleService: Title,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.titleService.setTitle("Medsoft - Wizyta");

    this.visitBookToken = this.activatedRoute.snapshot.queryParams.token;

    if (
      (visitService.visitToBook == null || !visitService.visitToBook.doctor) &&
      this.visitBookToken &&
      this.visitBookToken.length > 0
    ) {
      this.visitService.getBookedVisitByToken(this.visitBookToken).subscribe(
        () => {
          this.bookSlot();
        },
        (err) => {
          this.bookSlot();
        }
      );
    } else {
      this.bookSlot();
    }
  }

  bookSlot() {
    this.isBooking = true;
    this.goingToPayment = false;
    this.visitService.finishBookingVisit().subscribe(
      (result: BookSlotResponse) => {
        this.isBooking = false;
        this.finishedBooking = true;
        if (result.success) {
          this.hasInvoiceData = result.hasInvoiceData;
          this.message = result.message;
          this.visitId = result.visitId;

          this.hasPayment = result.hasPayment;
          this.paymentUrl = result.paymentUrl;
        } else {
          this.isError = true;
          this.error = "Wystąpił nieoczekiwany błąd";
        }
      },
      (error: any) => {
        this.isBooking = false;
        this.finishedBooking = true;
        this.isError = true;
        this.error = ErrorProcessor.process(error);
      }
    );
  }

  go() {
    this.userService.redirectHomePage(this.isError);
  }
}