<div *ngIf="showList" [@fadeInOut] class="col-xs-12 col-no-padding">

  <div [class.bhide]="!showBookSlot && !showRebookSlot" class="blocker">&nbsp;</div>

  <visit-book-slot *ngIf="showBookSlot" [@fadeInOut] class="col-xs-12" [class.float]="float" [slot]="selectedSlot" [showPrices]="showPrices" [showPayment]="showPayment" [blockWithoutPayment]="blockWithoutPayment" (canceled)="hideBooking();">
  </visit-book-slot>
  <visit-re-book-slot *ngIf="showRebookSlot" [@fadeInOut] class="col-xs-12" [class.float]="float" [visits]="patientVisits" [slot]="selectedSlot" (canceled)="hideRebooking();">
  </visit-re-book-slot>

  <h5 *ngIf="!hasResults && this.isError" class="text-danger" #empty>{{error}}</h5>

  <div *ngIf="loadTerms | async" class="load-board loader">
    <div class="loading">
      <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
      Trwa ładowanie...
    </div>
  </div>

  <div *ngIf="hasResults" class="list-container" #list [class.list-container-fixed]="showBookSlot || showRebookSlot">
    <table class="table table-striped slot-list" [class.withPrices]="showPrices" #table>
      <thead>
        <tr class="info">
          <td>
            Godzina
          </td>
          <td>
            Lekarz
          </td>
          <td>
            Oddział
          </td>
          <td *ngIf="showPrices">
            Cena
          </td>
          <td *ngIf="canChangeVisitDate">
            &nbsp;
          </td>
          <td *ngIf="telemedicineActive">
            Rodzaj
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of visits | async">
          <td>
            {{v.hour}}
          </td>
          <td>
            {{v.doctor}}
          </td>
          <td>
            {{v.department}}
          </td>
          <td *ngIf="showPrices">
            {{v.price | currency:'PLN':'symbol-narrow':'1.2-2'}}
          </td>
          <td class="td-btn" *ngIf="canChangeVisitDate">
            <button *ngIf="!v.changeVisitDate || !userService.isLoggedIn" type="button" class="btn btn-sm btn-success" (click)="showBooking(v);">Rezerwuj termin <i class="fal fa-calendar-plus"></i></button>
            <button *ngIf="v.changeVisitDate && userService.isLoggedIn" type="button" class="btn btn-sm btn-warning" (click)="showRebooking(v);">Zmień termin <i class="fal fa-calendar"></i></button>
          </td>
          <td *ngIf="telemedicineActive">
            <i *ngFor="let vt of v.visitTypes" class="fas fa-lg vt-icon" [class.fa-hospital]="vt.id == 1" [class.fa-video]="vt.id == 2" [class.fa-phone]="vt.id == 3" [title]="vt.name"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
