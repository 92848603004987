﻿import { Component, EventEmitter, Output, Input } from "@angular/core";
import { trigger, transition, style, animate, group} from '@angular/animations';
import { VisitService } from "../shared/services/visit.service";
import { SearchResponse } from "../shared/models/search-response.model";
import { GetResponse } from "../shared/models/get-response.model";

@Component({
    selector: 'visit-re-book-slot',
    templateUrl: './re-book-slot.component.html',
    styleUrls: ['./re-book-slot.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0', height: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                            animate('.4s ease-out', style({ height: '*' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1', height: '*' }),
                        animate('.6s ease-in', style({ opacity: '0', height: '0' })),
                        animate('.5s ease-in', style({ height: '0' })),
                    ])
            ])
    ]
})
export class ReBookSlotComponent {
    @Input() slot: SearchResponse;
    @Input() visits: GetResponse[];
    @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

    selectedSlot: GetResponse;
    changeForFew: boolean;
    confirmationVisible: boolean;
    constructor(private visitService: VisitService) {
    }

    book() {
        this.visitService.startBookingVisit(this.slot, this.selectedSlot.visitId);
    }

    chooseVisit(visit: GetResponse) {
        this.confirmationVisible = true;
        this.selectedSlot = visit;
    }

    ngOnInit(): void {
        this.changeForFew = this.visits.length > 1;
        if (!this.changeForFew) {
            this.selectedSlot = this.visits[0];
            this.confirmationVisible = true;
        }
    }

    cancel(exit: boolean = false) {
        if (exit)
            this.canceled.emit('');
        else this.confirmationVisible = false;
    }
}