﻿import { Component } from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})
export class NotFoundComponent {
    constructor(private titleService: Title) {
        this.titleService.setTitle('Medsoft - Strona nie znaleziona');
    }
}