import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";

import { VisitService } from "../shared/services/visit.service";
import { DatesWithFreeSlotsResponse } from "../shared/models/dates-with-free-slots-response.model";

import { ClientService } from "./../../shared/services/client.service";
import { ConfigModel } from "../../shared/models/config.model";
import { Calendar } from "primeng/calendar";
import { Observable } from "rxjs";

@Component({
  selector: "visit-free-terms-calendar",
  templateUrl: "./free-terms-calendar.component.html",
  styleUrls: ["./free-terms-calendar.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0", height: "0" }),
        group([
          animate(".7s ease-out", style({ opacity: "1" })),
          animate(".5s ease-out", style({ height: "*" })),
        ]),
      ]),
      transition(":leave", [
        style({ opacity: "1", height: "*" }),
        animate(".8s ease-in", style({ opacity: "0" })),
        animate(".7s ease-in", style({ height: "0" })),
      ]),
    ]),
  ],
})
export class FreeTermsCalendarComponent implements OnInit {
  private loadingTimeout;

  @Output()
  onMonthChange = new EventEmitter<{ month: number; year: number }>();

  calendar_pl: any;
  isError = false;
  error = "";
  showCalendar = false;
  selectedDay: Date | null = new Date();
  nextFreeDay: Date | null = new Date();
  minDate: Date;
  maxDate: Date;
  bannedDays: Array<Date> = new Array<Date>();
  disabledDays: Array<Date> = new Array<Date>();
  fullDays: Array<Date> = new Array<Date>();
  searchingForNextDay: boolean;

  cellTaken = "#fe62c2";
  colFree = "#47bb47";

  loadCalendar: Observable<boolean>;

  minRangeDate: Date;
  maxRangeDate: Date;
  isLoadingForced: boolean = false;

  constructor(
    private visitService: VisitService,
    private clientService: ClientService
  ) {
    this.clientService.configChange.subscribe((r: ConfigModel) => {
      this.setDateRange(r.searchForwardDays);
    });
    this.setDateRange(this.clientService.config.searchForwardDays);
  }

  setDateRange(daysNumber: number): void {
    let date = new Date();
    let minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + daysNumber
    );
    this.minRangeDate = minDate;
    this.maxRangeDate = maxDate;
    this.minDate = minDate;
    this.maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  ngOnInit(): void {
    this.loadCalendar = this.visitService.loadCalendar;
    this.loadCalendar.subscribe();
    this.bindData();
    this.calendar_pl = {
      firstDayOfWeek: 1,
      dayNames: [
        "Niedziela",
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Priątek",
        "Sobota",
      ],
      dayNamesShort: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"],
      dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
      monthNames: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
      ],
      monthNamesShort: [
        "Sty",
        "Lut",
        "Mar",
        "Kwi",
        "Maj",
        "Cze",
        "Lip",
        "Sie",
        "Wrz",
        "Paź",
        "Lis",
        "Gru",
      ],
      today: "Dzisiaj",
      clear: "Wyczyść",
    };
    this.showCalendar = false;
  }

  bindData() {
    this.visitService.datesWithFreeSlots.subscribe(
      (result: DatesWithFreeSlotsResponse[]) => {
        this.showCalendar = true;
        this.disabledDays = [];
        this.bannedDays = [];
        this.fullDays = [];

        Array.from(result).forEach((x) => {
          this.bannedDays.push(new Date(x.date));
          if (x.status !== 0) {
            if (x.status === 2) this.fullDays.push(new Date(x.date));
            else this.disabledDays.push(new Date(x.date));
          }
        });

        this.fillDisabledDays(result);

        if (this.searchingForNextDay) {
          this.searchingForNextDay = false;
          let nextDate = this.getNextFreeDate(this.minDate);

          if (
            !!nextDate &&
            nextDate.getMonth() == this.selectedDay.getMonth()
          ) {
            this.selectedDay = nextDate;
          }
          this.daySelected();
        }
      }
    );

    this.visitService.reloadCalendar.subscribe(() => {
      this.onReload();
    });
  }

  private fillDisabledDays(result: any[]) {
    const isResultExist = result && result.length > 0;
    let lastDate = new Date(this.minDate);

    if (isResultExist) {
      lastDate = new Date(result[result.length - 1].date);
    }

    const lastDateOfMonth = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth() + 1,
      0
    );

    let currentMissingDate = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth(),
      lastDate.getDate() + (isResultExist ? 1 : 0)
    );

    while (currentMissingDate.getTime() <= lastDateOfMonth.getTime()) {
      this.disabledDays.push(new Date(currentMissingDate));

      currentMissingDate.setDate(currentMissingDate.getDate() + 1);
    }
  }

  onReload() {
    this.selectedDay = null;

    let date = new Date();
    this.minDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.nextFreeDay = this.getNextFreeDate(this.minDate);

    if (this.nextFreeDay) {
      if (this.nextFreeDay.getMonth() == this.minDate.getMonth()) {
        this.goToNextDay();
      } else {
        this.selectedDay = this.minDate;
      }
    }

    if (this.selectedDay == null && this.bannedDays.length == 0)
      this.selectedDay = this.minDate;
    else if (this.selectedDay == null && this.bannedDays.length > 0)
      this.showCalendar = false;

    this.daySelected();
  }

  getNextFreeDate(date: Date): Date | null {
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    while (
      this.bannedDays.findIndex((x) => x.getTime() === date.getTime()) >= 0
    ) {
      date.setDate(date.getDate() + 1);
    }

    if (date >= this.maxRangeDate) {
      return null;
    }

    return date;
  }

  daySelected() {
    let date = this.selectedDay || new Date();
    this.visitService.search(date);
    let nextDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
    );
    this.nextFreeDay = this.getNextFreeDate(nextDay);
  }

  private sameMonths(date1: Date, date2: Date) {
    return date1 && date2 && date1.getMonth() == date2.getMonth();
  }

  dayFree(date: any): boolean {
    let m = new Date();
    m.setDate(this.minDate.getDate() - 1);
    let d = new Date(date.year, date.month, date.day);

    let monthsEqual = this.sameMonths(this.disabledDays[0], d);

    return (
      monthsEqual &&
      (this.selectedDay == null ||
        d.getTime() !== this.selectedDay!.getTime()) &&
      d >= m &&
      d <= this.maxDate &&
      this.disabledDays.findIndex((x) => x.getTime() === d.getTime()) === -1 &&
      this.fullDays.findIndex((x) => x.getTime() === d.getTime()) === -1
    );
  }

  dayFull(date: any): boolean {
    let d = new Date(date.year, date.month, date.day);

    let monthsEqual = this.sameMonths(this.fullDays[0], d);

    return (
      monthsEqual &&
      this.fullDays.findIndex((x) => x.getTime() === d.getTime()) >= 0
    );
  }

  goToNextDay() {
    let date = this.selectedDay || new Date();

    if (
      date < this.minDate ||
      date > this.maxDate ||
      this.nextFreeDay.getMonth() != date.getMonth()
    ) {
      if (this.nextFreeDay.getMonth() != date.getMonth()) {
        this.searchingForNextDay = true;
        this.nextFreeDay.setDate(1);
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);
      }

      this.monthChanged({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }

    if (this.nextFreeDay != null) {
      this.selectedDay = this.nextFreeDay;
    }

    this.daySelected();
  }

  haveMoreFreeDays(): boolean {
    return this.nextFreeDay != null;
  }

  monthChanged(event: { month: number; year: number }) {
    this.isLoadingForced = true;

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }

    this.loadingTimeout = setTimeout(
      () => (this.isLoadingForced = false),
      1000
    );

    let minDate = new Date(event.year, event.month - 1, 1);
    let maxDate = new Date(event.year, event.month, 0);

    if (
      maxDate.getFullYear() === this.maxRangeDate.getFullYear() &&
      maxDate.getMonth() <= this.maxRangeDate.getMonth() &&
      minDate.getFullYear() === this.minRangeDate.getFullYear() &&
      minDate.getMonth() >= this.minRangeDate.getMonth()
    ) {
      this.visitService.changeCalendarState(true);
    }

    if (minDate < this.minRangeDate) minDate = this.minRangeDate;

    if (maxDate > this.maxRangeDate || maxDate < this.minRangeDate)
      maxDate = this.maxRangeDate;

    this.minDate = minDate;
    this.maxDate = maxDate;

    this.onMonthChange.next(event);
  }

  get selectedDayOfWeek() {
    switch (this.selectedDay!.getDay()) {
      case 0:
        return "Niedziela";
      case 1:
        return "Poniedziałek";
      case 2:
        return "Wtorek";
      case 3:
        return "Środa";
      case 4:
        return "Czwartek";
      case 5:
        return "Piątek";
      case 6:
        return "Sobota";
      default:
        return "";
    }
  }
}
