<div [ngClass]="styleClass">
    <div *ngIf="isError" class="alert alert-danger" [innerHTML]="error"></div>
    <h3 *ngIf="fetchingDetails"><i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Trwa pobieranie szczegółów płatności</h3>
    <ng-container *ngIf="!isError && !fetchingDetails">
        <div *ngIf="goingToPayment" class="alert alert-warning text-center">
            <h3><i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Za chwilę nastąpi przekierowanie do płatności...</h3>
            <h4><small>W przypadku braku przekierowania kliknij <a [href]="paymentUrl">tutaj</a></small></h4>
        </div>
        <form *ngIf="!goingToPayment" [@fadeInOut] (ngSubmit)="goToPayment()" [formGroup]="invoiceForm" novalidate>
            <div *ngIf="isTokenPayment">
                <h4 class="text-main text-center">Szczegóły płatności</h4>
                <div class="alert alert-success" id="paymentDetails">
                    <table>
                        <tbody>
                            <tr *ngFor="let service of paymentDetails.services" class="service">
                                <td class="td-name">{{ service.name }}</td>
                                <td class="td-price">{{ service.price | currency:'PLN':'symbol-narrow':'1.2-2' }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="td-name"><h4>do zapłaty</h4></td>
                                <td class="td-price"><h4>{{ paymentDetails.price | currency:'PLN':'symbol-narrow':'1.2-2' }}</h4></td>
                            </tr>
                        </tfoot>
                    </table>
                </div> 
                <h4 class="text-main text-center">Dane płatnika</h4>
                <div class="row">
                    <div class="col-xs-12 col-md-6 form-group">
                        <input type="text" class="form-control text-uppercase col-xs-6"  formControlName="firstName" placeholder="imię">
                        <p [hidden]="okFirstName" class="text-danger">
                            Imię jest wymagana
                        </p>
                    </div>
                    <div class="col-xs-12 col-md-6 form-group">
                        <input type="text" class="form-control text-uppercase col-xs-6" formControlName="lastName" placeholder="nazwisko">
                        <p [hidden]="okLastName" class="text-danger">
                            Nazwisko jest wymagana
                        </p>
                    </div>
                </div>
                <input type="email" class="form-control" placeholder="email" formControlName="email">
                <p [hidden]="okEmailReq" class="text-danger">
                    Email jest wymagany
                </p>
                <p [hidden]="okEmail" class="text-danger">
                    Nieprawidłowy adres email
                </p>
            </div>
            <h4 class="text-main text-center">Dane do faktury VAT</h4>
            <div class="row">
                <div class="col-xs-6 col-no-padding">
                    <div class="radio pull-right" [class.cb-error]="!okPersonalInvoice">
                        <label>
                            <input type="radio" [value]='true' formControlName="personalInvoice">
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                            <ng-container *ngIf="hasInvoiceData" for="personalInvoice">na moje dane z teczki pacjenta
                            </ng-container>
                            <ng-container *ngIf="!hasInvoiceData" for="personalInvoice">na moje dane</ng-container>
                        </label>
                    </div>

                </div>
                <div class="col-xs-6 col-no-padding">
                    <div class="radio pull-left" [class.cb-error]="!okPersonalInvoice">
                        <label>
                            <input type="radio" [value]='false' formControlName="personalInvoice">
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                            na firmę
                        </label>

                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!personalInvoice">
                <div class="col-xs-12 col-no-padding">

                    <div class="col-md-6">
                        <div class="form-group" [class.has-error]="!okCompanyName">
                            <input type="text" class="form-control text-uppercase" formControlName="companyName"
                                placeholder="nazwa firmy">
                            <p [hidden]="okCompanyName" class="text-danger">
                                Nazwa firmy jest wymagana
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" [class.has-error]="!okNip">
                            <input type="text" class="form-control text-uppercase" formControlName="nip"
                                placeholder="NIP">
                            <p [hidden]="okNip" class="text-danger">
                                NIP jest wymagany
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!personalInvoice || (personalInvoice && !hasInvoiceData)">
                <div class="col-xs-12 col-no-padding">

                    <div class="col-md-6">
                        <div class="form-group" [class.has-error]="!okStreet">
                            <input type="text" class="form-control text-uppercase" formControlName="street"
                                placeholder="ulica">
                            <p [hidden]="okStreet" class="text-danger">
                                Ulica jest wymagana
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group" [class.has-error]="!okBuilding">
                            <input type="text" class="form-control text-uppercase" formControlName="building"
                                placeholder="budynek">
                            <p [hidden]="okBuilding" class="text-danger">
                                Budynek jest wymagany
                            </p>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="text" class="form-control text-uppercase" formControlName="apartment"
                                placeholder="mieszkanie">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!personalInvoice || (personalInvoice && !hasInvoiceData)">
                <div class="col-xs-12 col-no-padding">

                    <div class="col-md-6">
                        <div class="form-group" [class.has-error]="!okPostalCode">
                            <input type="text" class="form-control text-uppercase" formControlName="postalCode"
                                placeholder="kod pocztowy (__-___)">
                            <p [hidden]="okPostalCode" class="text-danger">
                                Kod pocztowy jest wymagany
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" [class.has-error]="!okCity">
                            <input type="text" class="form-control text-uppercase" formControlName="city"
                                placeholder="miasto">
                            <p [hidden]="okCity" class="text-danger">
                                Miasto jest wymagane
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div *ngIf="invoiceIsError" class="alert alert-danger" [innerHTML]="invoiceError">
                </div>
            </div>
            <div class="col-md-6 col-md-offset-3">
                <button type="submit" class="btn btn-primary input-full-width">Przejdź do płatności</button>
            </div>
        </form>
    </ng-container>



</div>