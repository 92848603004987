import { Term } from "./term-model";

export class ConfigModel {
    public hasRules = false;
    public hasPrivacyPolicy = false;
    public ifSmsOnRegister = false;
    public showPrices = false;
    public paymentsActive = false;
    public registerAditionalInfo = '';
    public blockWithoutPayment = false;
    public allowRegistrationForForeigners = false;
    public termsOfServices: Array<Term> = [];
    public weekDays: Array<number> = [];
    public searchForwardDays = 0;
    public allowReservationWithoutAccount = false;
    public registrationWithBirthDate = false;
    public hideRegistration: boolean | null = null;
    public redirectWithoutAccount: boolean = false;
    public redirectWithAccount: boolean = false;
    public redirectAddress: string = '';
    public fullAccess: boolean | null = null;
    public evisitWaitingForDoctorMessage: string = '';
    public telemedicineActive = false;
}
