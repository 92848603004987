import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { ErrorProcessor } from './shared/error-processor';
import { trigger, transition, style, animate, group } from '@angular/animations';
import { environment } from '../../../ClientApp/src/environments/environment';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0', transform: 'translateY(-110%)' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                            animate('.4s ease-out', style({ transform: 'translateY(0)' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ]
})

export class AppComponent implements OnInit, OnDestroy {
  isError = false;
  error = '';

  intervalToken: any;
  intervalUserActive: any;

  mouseListener: any;

  lastMouseMove: Date;

  tokenConfig = environment.tokenRefresh;
  userActiveConfig = environment.userActive;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.loggedInStatus.subscribe((data) => {
      if (data) {
        this.startIntervalToken();
      } else {
        this.stopIntervalToken();
      }
    })
  }

  ngOnDestroy() {
  }

  startIntervalToken() {
    this.refreshToken();
    if (!this.intervalToken) {
      this.intervalToken = window.setInterval(() => {
        this.refreshToken();
      }, this.tokenConfig.intervalRefreshTokenMiliseconds);
    }
  }

  stopIntervalToken() {
    if (this.intervalToken) {
      window.clearInterval(this.intervalToken);
      this.intervalToken = null;
    }

    this.stopIntervalUserActive();
  }

  refreshToken() {
    let currentToken = localStorage.getItem('access_token');
    let expires = localStorage.getItem('expires');

    let timeToRefresh = new Date();
    timeToRefresh.setMinutes(timeToRefresh.getMinutes() + this.tokenConfig.refreshTokenBeforeEndExpireMinutes);

    if (this.userService.isLoggedIn) {
      this.startIntervalUserActive();
    }

    if (!currentToken || !expires || timeToRefresh < new Date(expires)){
      return;
    }

    this.userService.refreshToken(currentToken).subscribe((result: any) => {
    },
      (error: any) => {
        this.isError = true;
        this.error = ErrorProcessor.process(error);
      });
  }

  startIntervalUserActive() {
    if (!this.intervalUserActive) {
      this.lastMouseMove = new Date();
      this.startListenMouseMove();

      this.intervalUserActive = window.setInterval(() => {
        this.checkUserActivity();
      }, this.userActiveConfig.intervalCheckActiveUserMilisecond);
    }
  }

  stopIntervalUserActive() {
    if (this.intervalUserActive) {
      window.clearInterval(this.intervalUserActive);
      this.intervalUserActive = null;
    }

    this.stopListenMouseMove();
  }

  startListenMouseMove() {
    this.mouseListener = window.document.addEventListener('mousemove', this.setUserActivity, true);
  }

  stopListenMouseMove() {
    this.mouseListener = window.document.removeEventListener('mousemove', this.setUserActivity, true);
  }

  checkUserActivity() {
    let time = new Date(this.lastMouseMove.getUTCFullYear(), this.lastMouseMove.getUTCMonth(), this.lastMouseMove.getUTCDate(), this.lastMouseMove.getHours(), this.lastMouseMove.getMinutes() + this.userActiveConfig.deactiveUserLogoutMinutes);
    let now = new Date();

    if (!!time && time < now) {
      this.stopIntervalToken();
      this.userService.logout();
    }
  }

  setUserActivity(event: any) {
    event.preventDefault();
    this.lastMouseMove = new Date();
  }

}
