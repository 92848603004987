import { Component} from '@angular/core';
import { trigger, transition, style, animate, group} from '@angular/animations';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { UserService } from './../shared/services/user.service';

import { PersonalDetail } from '../shared/models/personal-detail.model';
import { RegisterResponse } from './../shared/models/register-response.model';
import { ErrorProcessor } from './../shared/error-processor';
import { VisitService } from '@app/visit/shared/services/visit.service';


@Component({
    templateUrl: './register.component.html',
    animations: [
        trigger('fadeInOut',
            [
                transition(':enter',
                    [
                        style({ opacity: '0' }),
                        group([
                            animate('.7s ease-out', style({ opacity: '1' })),
                        ])
                    ]),
                transition(':leave',
                    [
                        style({ opacity: '1' }),
                        animate('.8s ease-in', style({ opacity: '0' })),
                    ]),
            ]),
    ],
    styles: ['.info { color: #317CB3; padding: 5px; }']
})
export class RegisterComponent {
    isError = false;
    error = '';
    submitingForm = false;
    token: string | null;

    constructor(private titleService: Title, private router: Router, private userService: UserService, private visitService: VisitService) {
        this.titleService.setTitle("Medsoft - Rejestracja"); 
        
        this.visitService.saveBookingVisitInfo().subscribe(
          (token) => {
            this.token = token;
          },
          (err) => {
            this.token = null;
          }
        );
    }

    onSubmit(model: PersonalDetail) {
        this.isError = false;
        this.error = '';
        this.submitingForm = true;

        model.visitBookToken = this.token;

        this.userService.register(model).subscribe((result: RegisterResponse) => {
            this.submitingForm = false;
            if (result.success) {
                this.router.navigate(['/confirm']);
            } else {
                this.isError = true;
                this.error = 'Wystąpił nieoczekiwany błąd';
            }
        },
        (error: any) => {
            this.submitingForm = false;
            this.isError = true;
            this.error = ErrorProcessor.process(error);
        });
    }
}
