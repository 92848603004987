﻿export class PersonalDetail {
    constructor() {
        this.email = '';
        this.password = '';
        this.passwordConfirmation = '';
        this.birthDate = null,
        this.name = '';
        this.surname = '';
        this.phone = '';
        this.pesel = '';
        this.dontHavePesel = false;
        this.acceptedTos = [];
        this.captchaResponse = null;
        this.visitBookToken = null;
    }

    email: string;
    password: string;
    passwordConfirmation: string;
    name: string;
    surname: string;
    phone: string;
    pesel: string;
    birthDate: Date | null;
    dontHavePesel: boolean;
    acceptedTos: Array<number>;
    captchaResponse: string | null;
    visitBookToken: string | null;
}