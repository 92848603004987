import { SaveInvoiceDataModel } from '@app/shared/models/save-invoice-data.model';

export class MakePaymentModel {
    constructor(public token: string,
                public firstName: string,
                public lastName: string,
                public email: string,
                public invoiceData: SaveInvoiceDataModel,
                ) {

    }
}