import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from "@angular/animations";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

import { Observable, Subscription, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { SpecialityService } from "../shared/services/speciality.service";
import { Speciality } from "../shared/models/speciality.model";
import { LanguageService } from "../shared/services/language.service";
import { Language } from "../shared/models/language.model";
import { DepartmentService } from "../shared/services/department.service";
import { Department } from "../shared/models/department.model";
import { DoctorService } from "../shared/services/doctor.service";
import { Doctor } from "../shared/models/doctor.model";
import { ServiceService } from "../shared/services/service.service";
import { Service } from "../shared/models/service.model";
import { VisitService } from "../shared/services/visit.service";
import { Hour } from "../shared/models/hour.model";
import { VisitTypeService } from "../shared/services/visit-type.service";
import { VisitType } from "../shared/models/visit-type.model";

import { ClientService } from "../../shared/services/client.service";
import { ConfigModel } from "../../shared/models/config.model";
import { UserService } from "../../shared/services/user.service";

@Component({
  selector: "visit-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        group([animate(".7s ease-out", style({ opacity: "1" }))]),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate(".8s ease-in", style({ opacity: "0" })),
      ]),
    ]),
  ],
})
export class FiltersComponent implements OnInit, OnDestroy {
  isError = false;
  error = "";
  validate = false;

  showServices: boolean = false;
  noServices: boolean = false;
  showWarning: boolean = false;
  warning: string = "";

  specialities: Speciality[];
  languages: Language[];
  departments: Department[];
  doctors: Doctor[];
  hours: Hour[];
  services: Service[];
  visitType: VisitType[] = [new VisitType(1, "Wizyta stacjonarna")];

  departmentId: number = -1;

  visitForm: FormGroup;

  private _visitTypeSelected = [1];

  private $destroy: Subject<boolean> = new Subject<boolean>();

  private telemedicineActive: boolean = false;

  constructor(
    private fb: FormBuilder,
    private specialityService: SpecialityService,
    private languageService: LanguageService,
    private departmentService: DepartmentService,
    private doctorService: DoctorService,
    private serviceService: ServiceService,
    private visitService: VisitService,
    private userService: UserService,
    private clientService: ClientService,
    private visitTypeService: VisitTypeService
  ) {
    this.createForm();
    this.setWeekDays(this.clientService.config.weekDays);
    this.clientService.configChange
      .pipe(takeUntil(this.$destroy))
      .subscribe((model) => {
        this.setWeekDays(model.weekDays);
        this.telemedicineActive = model.telemedicineActive;
      });
  }

  ngOnInit(): void {
    this.visitService.clear();
    this.bindData();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.$destroy.unsubscribe();
  }

  createForm() {
    this.visitForm = this.fb.group({
      specialityId: [0, Validators.min(1)],
      languageId: [1],
      departmentId: [0],
      doctorId: [0],
      serviceId: [0, Validators.min(0)],
      daysOfWeekGroup: this.fb.group(
        {
          dayMon: [true, []],
          dayTue: [true, []],
          dayWed: [true, []],
          dayThu: [true, []],
          dayFri: [true, []],
          daySat: [true, []],
          daySun: [true, []],
        },
        { validator: [this.mustBeCheckedValidator] }
      ),
      hour: [0, Validators.required],
      warning: [false],
    });
  }

  setWeekDays(weekDays: Array<number>) {
    let daysOfWeekGroup = this.visitForm.get("daysOfWeekGroup");
    daysOfWeekGroup!.setValue({
      dayMon: weekDays.indexOf(0) != -1,
      dayTue: weekDays.indexOf(1) != -1,
      dayWed: weekDays.indexOf(2) != -1,
      dayThu: weekDays.indexOf(3) != -1,
      dayFri: weekDays.indexOf(4) != -1,
      daySat: weekDays.indexOf(5) != -1,
      daySun: weekDays.indexOf(6) != -1,
    });
  }

  bindData() {
    this.visitForm.controls["specialityId"].valueChanges.subscribe((val) => {
      this.getDepartments(val);
    });

    this.visitForm.controls["departmentId"].valueChanges.subscribe((val) => {
      this.departmentId = val;
      this.getDoctors(
        this.visitForm.value.specialityId,
        val,
        this.visitForm.value.languageId
      );
    });

    this.visitForm.controls["languageId"].valueChanges.subscribe((val) => {
      this.getDoctors(
        this.visitForm.value.specialityId,
        this.departmentId,
        val
      );
    });

    this.visitForm.controls["doctorId"].valueChanges.subscribe((val) => {
      this.getServices(
        this.visitForm.value.specialityId,
        this.departmentId,
        val
      );
    });

    this.visitForm.controls["serviceId"].valueChanges.subscribe((val) => {
      this.showServiceWarning(val);
    });

    this.specialityService.specialities
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.specialities = data;
        if (data.length === 1) {
          this.visitForm.controls["specialityId"].setValue(data[0].id);
        } else {
          if (
            data.findIndex(
              (x) => x.id === this.visitForm.value.specialityId
            ) === -1
          ) {
            this.visitForm.controls["specialityId"].setValue(0);
          }
        }
      });
    this.languageService.languages
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.languages = data;
        if (this.visitForm.value.languageId === 0) {
          this.visitForm.controls["languageId"].setValue(
            data.length > 0 ? data[0].id : 0
          );
        }
      });
    this.departmentService.departments
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.departments = data;
        this.visitForm.controls["departmentId"].setValue(-1);
        this.departmentId = -1;
      });
    this.doctorService.doctors
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.doctors = data;
        this.visitForm.controls["doctorId"].setValue(-1);
      });
    this.serviceService.services
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.services = data;
      });
    this.visitService.hours.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      this.hours = data;
    });

    this.visitTypeService
      .get()
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        if (this.telemedicineActive) {
          this.visitType = data;
        }

        this._visitTypeSelected = data.map((x) => x.id);
      });

    let specSub = this.specialityService
      .get()
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => specSub.unsubscribe());
    let langSub = this.languageService
      .get()
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => langSub.unsubscribe());

    this.visitService.getHours();
  }

  getDepartments(specialityId: number) {
    this.visitForm.controls["departmentId"].setValue(0);
    this.departmentId = 0;

    if (specialityId > 0) {
      let sub = this.departmentService
        .get(specialityId)
        .pipe(takeUntil(this.$destroy))
        .subscribe((result: Department[]) => {
          this.getServices(
            this.visitForm.value.specialityId,
            this.departmentId,
            -1
          );
          sub.unsubscribe();
        });
    } else {
      this.departmentService.reset();
    }
  }

  getDoctors(specialityId: number, departmentId: number, languageId: number) {
    this.visitForm.controls["doctorId"].setValue(0);
    if (specialityId > 0 && departmentId != 0 && languageId > 0) {
      let sub = this.doctorService
        .get(specialityId, departmentId, languageId)
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => sub.unsubscribe());
    } else {
      this.doctorService.reset();
    }
  }

  getServices(specialityId: number, departmentId: number, doctorId: number) {
    if (specialityId > 0 && departmentId != 0 && doctorId != 0) {
      let sub = this.serviceService
        .get(specialityId, departmentId, doctorId)
        .pipe(takeUntil(this.$destroy))
        .subscribe((result: Service[]) => {
          sub.unsubscribe();
          this.showServices = result.length > 0;
          this.noServices = specialityId !== 0 && result.length === 0;
          let selectedServiceId = this.visitForm.controls["serviceId"].value;
          if (!result.find((x) => x.id === selectedServiceId)) {
            this.visitForm.controls["serviceId"].setValue(-1);
          }
        });
    } else {
      this.serviceService.reset();
    }
  }

  showServiceWarning(serviceId: number) {
    this.warning = this.serviceService.getServiceWarning(serviceId);
    this.showWarning = this.warning !== "";
  }

  onSubmit() {
    this.userService.deleteTemporaryAccount();
    let warningReq = this.showWarning && this.visitForm.value.warning === false;
    if (!this.visitForm.valid || warningReq) {
      this.validate = true;
      this.isError = true;
      this.error = warningReq
        ? "Proszę zapoznać się z uwagami"
        : "Proszę poprawić wprowadzone dane";
      return;
    }
    this.isError = false;
    this.getDatesWithFreeSlots(null);
  }

  getSelectedDays(): number[] {
    let daysOfWeek: number[] = [];
    if (this.visitForm.value.daysOfWeekGroup.dayMon) daysOfWeek.push(1);
    if (this.visitForm.value.daysOfWeekGroup.dayTue) daysOfWeek.push(2);
    if (this.visitForm.value.daysOfWeekGroup.dayWed) daysOfWeek.push(3);
    if (this.visitForm.value.daysOfWeekGroup.dayThu) daysOfWeek.push(4);
    if (this.visitForm.value.daysOfWeekGroup.dayFri) daysOfWeek.push(5);
    if (this.visitForm.value.daysOfWeekGroup.daySat) daysOfWeek.push(6);
    if (this.visitForm.value.daysOfWeekGroup.daySun) daysOfWeek.push(7);
    return daysOfWeek;
  }

  isVisitTypeSelected(vt: number): boolean {
    return this._visitTypeSelected.indexOf(vt) !== -1;
  }

  toggleVisitType(id: number, checked: boolean) {
    if (checked) {
      this._visitTypeSelected.push(id);
    } else {
      this._visitTypeSelected.splice(this._visitTypeSelected.indexOf(id), 1);
    }
  }

  getDatesWithFreeSlots(date: Date | null) {
    let specialityId: number = this.visitForm.value.specialityId;
    let doctorId: number = this.visitForm.value.doctorId;
    let timeOfDay: number = this.visitForm.value.hour;
    let weekDays: number[] = this.getSelectedDays();
    let languageId: number = this.visitForm.value.languageId;
    let serviceId: number = this.visitForm.value.serviceId;
    let departmentId: number = this.visitForm.value.departmentId;

    this.visitService.getDatesWithFreeSlots(
      specialityId,
      doctorId,
      timeOfDay,
      weekDays,
      languageId,
      serviceId,
      departmentId,
      this._visitTypeSelected,
      date,
      date == null
    );
  }

  get okVisitType(): boolean {
    return this._visitTypeSelected.length > 0;
  }

  get okSpeciality(): boolean {
    return (
      !this.visitForm.hasError("min", ["specialityId"]) ||
      (this.visitForm.get("specialityId")!.pristine && !this.validate)
    );
  }

  get okDaysOfWeek(): boolean {
    return !this.visitForm.hasError("checked", ["daysOfWeekGroup"]);
  }

  get okService(): boolean {
    return (
      !this.showServices ||
      !this.visitForm.hasError("min", ["serviceId"]) ||
      (this.visitForm.get("serviceId")!.pristine && !this.validate)
    );
  }

  get okWarnings(): boolean {
    return (
      this.visitForm.value.warning === true ||
      !this.showWarning ||
      (this.visitForm.get("warning")!.pristine && !this.validate)
    );
  }

  mustBeCheckedValidator({ value }: FormGroup): { [ket: string]: any } | null {
    const [...values] = Object.keys(value || {});
    return values.filter((x) => value[x] == true).length > 0
      ? null
      : { checked: true };
  }
}
