import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClientService } from './services/client.service';
import { map } from 'rxjs/operators';
import { ConfigModel } from './models/config.model';
import { Observable } from 'rxjs';

@Injectable()
export class FullAccessGuard implements CanActivate {

    constructor(private clientService: ClientService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.clientService.config.fullAccess === null) {
            return this.clientService.configChange.pipe(map((config: ConfigModel) => this.check(config)));
        }
        else {
            return this.check(this.clientService.config);
        }
    }

    private check(config: ConfigModel): boolean {
        if (config.fullAccess)
            return true;
        
        this.router.navigate(['/404']);
        return false;
    }
}