
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject } from 'rxjs';

import { GetConfigResult } from '../models/get-config-result.model';
import { ConfigModel } from '../models/config.model';

import { NotificationService } from './notification.service';



@Injectable()
export class ClientService {
    private configStore: ConfigModel = new ConfigModel();
    private _configChange = new ReplaySubject<ConfigModel>(1);
   
    constructor(private http: HttpClient, private notificationService: NotificationService) {
        //this.getConfig();
    }


    getConfig() {
        return this.http
            .get<GetConfigResult>(
            'api/Client/GetConfig').pipe(
            map((r: GetConfigResult) => {
                this.configStore.hasRules = r.hasRules;
                this.configStore.hasPrivacyPolicy = r.hasPrivacyPolicy;
                this.configStore.ifSmsOnRegister = r.smsOnRegister;
                this.configStore.registerAditionalInfo = r.registerAditionalInfo;
                this.configStore.showPrices = r.showPrices;
                this.configStore.paymentsActive = r.paymentsActive;
                this.configStore.blockWithoutPayment = r.blockWithoutPayment;
                this.configStore.allowRegistrationForForeigners = r.allowRegistrationForForeigners;
                this.configStore.termsOfServices = r.termsOfServices;
                this.configStore.weekDays = r.weekDays;
                this.configStore.searchForwardDays = r.searchForwardDays;
                this.configStore.registrationWithBirthDate = r.registrationWithBirthDate;
                this.configStore.hideRegistration = r.hideRegistration;
                this.configStore.allowReservationWithoutAccount = r.allowReservationWithoutAccount;
                this.configStore.redirectWithoutAccount = r.redirectWithoutAccount;
                this.configStore.redirectWithAccount = r.redirectWithAccount;
                this.configStore.redirectAddress = r.redirectAddress;
                this.configStore.fullAccess = r.fullAccess;
                this.configStore.evisitWaitingForDoctorMessage =
                  r.evisitWaitingForDoctorMessage;
                this.configStore.telemedicineActive = r.telemedicineActive;
                this._configChange.next(Object.assign({}, this.configStore));
            }))
            .toPromise();
    }

    get config() {
        return Object.assign({}, this.configStore);
    }

    get configChange() {
        return this._configChange.asObservable();
    }
}
