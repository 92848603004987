// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  rtcConfiguration: {
    iceServers: [
      {
        urls: ['turn:medsoft-tw1.westeurope.cloudapp.azure.com'],
        credential: 'testguestpwd',
        username: 'guest'
      }
    ]
  },
  tokenRefresh: {
    intervalRefreshTokenMiliseconds: 60000,  
    refreshTokenBeforeEndExpireMinutes: 2  
  },
  userActive: {
    deactiveUserLogoutMinutes: 60,
    intervalCheckActiveUserMilisecond: 30000 
  }
};
