
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { Speciality } from '../models/speciality.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../shared/models/notification.model';

@Injectable()
export class SpecialityService {
    private _specialities: ReplaySubject<Speciality[]>;
    private dataStore: {
        specialities: Speciality[]
    };

    constructor(private http: HttpClient, private notifications: NotificationService) {
        this.dataStore = { specialities: []};
        this._specialities = (new ReplaySubject(1) as ReplaySubject<Speciality[]>);
    }

    get(): Observable<Speciality[]> {
        return this.http
            .get<Speciality[]>(`api/Speciality/Get`).pipe(
            tap((data: Speciality[]) => {

                this.dataStore.specialities = [];
                data.forEach(t => this.dataStore.specialities.push(new Speciality(t.id, t.name)));
                this._specialities.next(Object.assign({}, this.dataStore).specialities);
            },
            error => this.notifications.handleHttpError(error)));
    }

    get specialities() {
        return this._specialities.asObservable();
    }
}