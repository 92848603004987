<div [@fadeInOut] class="col-xs-12 col-md-8 col-md-offset-2">
    <div class="col-md-12">
        <h4 class="text-main">Rejestracja konta</h4>
        <br />
        <personal-detail forCreatingAccount="true" 
                         btnSubmitLabel="Rejestruj konto"
                         btnSubmitIcon="fa fa-user-plus"
                         noAgreementMessage="Wyrażenie zgody jest wymagane w celu założenia konta"
                         (formSubmit)="onSubmit($event)"
                         [(isError)]="isError"
                         [(error)]="error"
                         [(submitingForm)]="submitingForm">
        </personal-detail>
    </div>
</div>